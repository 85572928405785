import axios from 'axios';
const category = {
    namespaced: true,
    state: () => ({
        /**
         * Hier fehlen noch die anderen States
         */
        show_all_terms: null,
        searchterms_de: [],
        searchterms_en: [],
        searchterms_it: [],
        searchterms_nl: [],
        searchterms_es: [],
        searchterms_fr: [],
    }),
    mutations: {
        show_all_terms(state, bool){
            state.show_all_terms = bool
        },
        resetTerms(state){
            state.searchterms_de = []
            state.searchterms_en = []
            state.searchterms_it = []
            state.searchterms_nl = []
            state.searchterms_es = []
            state.searchterms_fr = []
        },
        searchterms(state, data){
            state.searchterms_de = data.de
            state.searchterms_en = data.en
            state.searchterms_it = data.it
            state.searchterms_nl = data.nl
            state.searchterms_es = data.es
            state.searchterms_fr = data.fr
        },
        addSearchterm(state, data){
            state[data.type].push(data.term)
        },
        deleteSearchTerm(state, data){
            state[data.type] = state[data.type].filter(item => item !== data.term)
        }
    },
    actions: {
        getSearchTerms(context, cat_id){
            return axios.get(`/api/category_searchterms/getByCat/${cat_id}`).then(response => {
                context.commit('resetTerms')
                let data = response.data.result.sort()
                return setTerms(data, context)
            })
        },
        updateSearchTerms({state}, cat_id){ // vorerst bis store komplett implementiert ist
            const data = {
                terms: [
                    {
                        lang_slug: "de",
                        terms: state.searchterms_de
                    },
                    {
                        lang_slug: "en",
                        terms: state.searchterms_en
                    },
                    {
                        lang_slug: "it",
                        terms: state.searchterms_it
                    },
                    {
                        lang_slug: "nl",
                        terms: state.searchterms_nl
                    },
                    {
                        lang_slug: "es",
                        terms: state.searchterms_es
                    },
                    {
                        lang_slug: "fr",
                        terms: state.searchterms_fr
                    },
                    
                ]
            }
            return axios.post(`/api/category_searchterms/create/${cat_id}`, data).then(response => {
                return response
            })
        },
    }
}

function setTerms(data, context){
    if(data.length){
        return new Promise((resolve) => {
            data.forEach((item, index) => {
                const terms = {
                    type: `searchterms_${item.lang_slug}`,
                    term: item.term
                }
                context.commit('addSearchterm', terms)
                if(index == data.length - 1){
                    resolve()
                }
            });
        })
    }
    else{
        return Promise.resolve()
    }
    
}

export default category