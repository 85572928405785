<template >
<header>
  <div class="layout_container">
    <a @click="checkLastRoute" class="logo hovered"></a>
    <ul class="user_menu">
        <li :class="{ 'ticket none' : !ticket_id, 'ticket' : ticket_id}">{{ticket_id ? 'Ticket #' + ticket_id : 'Kein Ticket'}}</li>
        <li class="seperator"></li>
        <li @click="toggleDarkMode" class="skin hovered"><a ><i class="fas fa-lg fa-adjust" aria-hidden="true"></i></a></li>
        <li class="seperator"></li>
        <li class="user_name hovered">
          <a class="menue_select" @mouseover="showMenu">{{username}}
            <i class="fas fa-caret-down"></i>            
          </a>
          <ul class="menu_select_open closed" @mouseleave="closeMenu">
            <li class="title">Einstellungen</li>
            <a  v-if="loggedIn" class="disable_hover_effect full_wid" href="/user/options/config">
              <li>Kontoeinstellungen</li>
            </a>
            <a v-if="loggedIn && isAdmin" class="full_wid" :href="type== 'Backend' ? $router.resolve({name: 'suchmaske'}).href: $router.resolve({name: 'produktverwaltung'}).href" >
              <li  :class="{ 'admin' : type == 'Frontend'}">
                
                    <i class="fas" :class="{ 'fa-home' : type == 'Backend', 'fa-lock' : type == 'Frontend'}"></i> 
                    {{type == 'Backend' ? 'Frontend': 'Backend'}}
                
              </li>
           </a>
           <a v-if="loggedIn && isAdmin" class="full_wid" @click="syncCache">
              <li class="admin">
                <i class="fas fa-sync"></i> 
                Cache synchronisieren
              </li>
           </a>
            
            <li class="spacer"><hr></li>
            <li>
              <a @click="logout">Abmelden</a>
            </li>
          </ul>
        </li>
    </ul>
  </div>
  <LoadingAnimation v-if="loading" />
  <MessageModal :showModal="showModal" :message="modalMessage" :buttons="true" buttonText="Schließen" :onlyCloseButton="false" @closeModal="closeModal" />
</header>

    
</template>

<script>
import LoadingAnimation from "@/components/LoadingAnimation.vue";
import MessageModal from "@/components/MessageModal.vue";
import { authenticationService } from '@/_services/user';
import axios from 'axios';
//import { mapState } from 'vuex'
export default {
    props: ['zendesk_ticket', 'endType'],
    data(){
        return {
          lastRoute: sessionStorage.lastRoute ? window.location.origin + sessionStorage.lastRoute : '/',
          default_user: 'Default User',
          type: this.endType,
          loading: false,
          modalMessage: '',
          showModal: false,
        } 
    },
    components: {
        LoadingAnimation,
        MessageModal
    },
    computed: {
      loggedIn(){
        return this.$store.state.user.loggedIn
      },
      isAdmin(){
        return this.$store.state.user.isAdmin
      },
      username(){
        return this.$store.state.user.user.name
      },
      darkmode(){        
        return this.$store.state.user.userConfig.darkmode        
      },
      ticket_id(){
        return this.$store.state.input.ticket_id         
      },
    },
    watch:{
    },
    mounted(){      
    },
    methods: {
      closeModal(){
        this.showModal = false
        this.modalMessage = ''
      },
      async syncCache(){
        try {
            this.loading = true
            await this.sync()
            this.modalMessage = 'Cache wurde erfolgreich synchronisiert'
            this.showModal = true
            this.loading = false                
        } catch (error) {
            this.modalMessage = 'Cache konnte nicht syncronisiert werden. Grund: ' + error.message
            this.showModal = true
            this.loading = false  
        }
      },
      async sync(){
        const url = '/api/syncCache'
        try {
            await axios.get(url)
        } catch (error) {
            console.log(error)
            throw new Error(error)
        }
      },
      linkToMatchingEnd(e){
        e.preventDefault()
        if(this.type == 'Backend'){
          this.$router.push({name: 'suchmaske'})
        }
        else if(this.type == 'Frontend'){
          this.$router.push({name: 'produktverwaltung'})
        }
      },
      toggleDarkMode(){
        this.$store.commit('user/toggleDarkmode', !this.darkmode)        
      },
      checkLastRoute(){
        this.$store.dispatch('input/resetToDefault')
        this.$router.push({name: 'suchmaske', params: {lang: this.$store.state.input.language, product: this.$store.state.input.product.slug}})
        
      },
      logout(){        
        authenticationService.logout()
        .then(state => {
          if (state){
            this.$store.commit('user/logout')
            this.$router.push({ 
              name: 'login',              
            })
          }
        })
      },
      showMenu(e){
        if (e.target.nextSibling){
          e.target.nextSibling.classList.remove('closed')
        }
        
      },
      closeMenu(){
        document.querySelector('.menu_select_open').classList.add('closed')
      }
    }   
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.closed{
  display: none;
}
.full_wid{
  width: inherit;
}
</style>
