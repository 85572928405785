<template>
  <div>
    <div v-if="checkIlvyTreffer" class="zd_pusher msm_box clearfix">
      <ul>
        <li v-for="(treffer, n) in ilvy_treffer" :key="n">
          <a
            @click="linkToSingle(treffer)"
            class="class_icon hovered"
            :class="{ mail_tb: treffer.mailTB, doc_link: !treffer.mailTB && !treffer.rma, 'rma_ticket': treffer.rma }"
            >{{ treffer.title }}</a
          ><i
            @click="removeIlvyTreffer(n)"
            class="fas fa-times-circle"
            aria-hidden="true"
          ></i>
        </li>
      </ul>
      <a @click="sendToZendesk()" class="button icon important"
        >An Zendesk übergeben</a
      >
    </div>
    <MessageModal :message="modalMessage" :buttons="true" :showModal="showModal" @confirm="confirmRMA" @cancel="cancelRMA" buttonText="OK"  />
  </div>
</template>

<script>
import MessageModal from "@/components/MessageModal.vue";
export default {
  components: {
    MessageModal
  },
  data() {
    return {
      showModal: false,
      modalMessage: "Um ein RMA-Ticket zu erzeugen, musst Du auf dem RMA-Tab \"Speichern\" klicken!"
    };
  },
  computed: {
    rma_treffer(){
      return this.$store.state.zendesk.rma_treffer
    },
    ilvy_treffer() {
      return this.$store.state.zendesk.treffer;
    },
    checkIlvyTreffer() {
      return this.ilvy_treffer && this.ilvy_treffer.length > 0 ? true : false;
    },
    language() {
      return this.$store.state.input.language;
    },
    product: {
      get() {
        return this.$store.state.input.product.slug;
      },
      set(newVal) {
        this.$router
          .push({
            params: { ...this.$route.params, product: newVal },
            query: { ...this.$route.query },
          })
          .catch((err) => {
            console.error(err);
          });
      },
    },
  },
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  methods: {
    confirmRMA(){
      this.$store.commit('zendesk/rma_treffer', {})
      this.showModal = false
      this.$emit('startLoading')
        this.$store
          .dispatch("zendesk/sendToZendesk")
          .then((data) => {
            this.$emit('stopLoading')
            if (!data.error) {
              this.$router.push("/zendesk/handover/success");
            } else {
              alert(data.error);
            }
          })
          .catch((error) => console.log(error));
    },
    cancelRMA(){
      this.showModal = false
      this.$store.commit("input/info_tab", 'rma')
    },
    hasNoRMA(){
      return this.rma_treffer
      && Object.keys(this.rma_treffer).length === 0
      && Object.getPrototypeOf(this.rma_treffer) === Object.prototype
    },
    removeIlvyTreffer(x) {
      this.$store.dispatch("zendesk/removeTreffer", x);
    },
    linkToSingle(item) {
      if (item.doc_type == "ilvy") {
        this.$router.push({
          name: "publikationsansicht",
          params: {
            slug: this.product,
            id: item.doc_id + "_" + encodeURIComponent(item.doc_slug),
            lang: this.language,
          },
        });
      } else if (item.doc_type == "techex") {
        this.$router.push({
          name: "supnews_template",
          params: {
            type: "techex",
            product: item.product,
            itemID: item.doc_id,
            title: item.title,
          },
        });
      }
      else if(item.doc_type == "fritzwiki"){
          this.$router.push({
          name: "supnews_template",
          params: {
            type: "fritzwiki",
            product: item.product,
            itemID: item.doc_id,
            title: item.title,
          },
        });
      }
    },
    sendToZendesk() {
      if(!this.hasNoRMA() && !this.ilvy_treffer.some((item) => item.type == "rma")){
        this.showModal = true
      }
      else{
        this.$emit('startLoading')
        this.$store
          .dispatch("zendesk/sendToZendesk")
          .then((data) => {
            this.$emit('stopLoading')
            if (!data.error) {
              this.$router.push("/zendesk/handover/success");
            } else {
              alert(data.error);
            }
          })
          .catch((error) => console.log(error));
      }
      
      
        
       
    },
  },
};
</script>

<style scoped>
</style>