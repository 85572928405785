const treffer_store = {
    namespaced: true,
    state: () => ({
        treffer: [],
        requester: {
            id: null,
            email: null,
            error: null
        },
        rma_treffer: {},
        response_data: []
    }),
    mutations: {
        treffer(state, treffer){
            state.treffer = treffer
        },
        requester(state, requester){
            state.requester.id = requester?.id
            state.requester.email = requester?.email
            state.requester.error = requester?.error
        },
        rma_treffer(state, treffer){
            state.rma_treffer = treffer
        },
        push_rma_to_ilvy_treffer(state, data){
            state.treffer.push(data)   
        },
        response_data(state, response_data){
            state.response_data = response_data
        }
    },
    actions: {
        getRequester(context, ticket_id){
            return fetch('/api/zendesk/getRequester/' + ticket_id, {
                method: 'GET', 
            })
            .then(response => response.json())
            .then(data => {
                if(data.requester){
                    context.commit('requester', {
                        id: data.requester.id,
                        email: data.requester.email,
                        error: null
                    })
                }
                else{
                    context.commit('requester', {
                        id: null,
                        email: null,
                        error: data.error
                    })
                }
                
            })
            .catch(error => {
                console.log('error', error)
            })
        },
        removeTreffer({ commit, state }, x){
            let all = state.treffer
            all.splice(x, 1);
            commit('treffer', all)
        },
        sendToZendesk({commit, rootState, state}){
            const data = {
                ticket_id: rootState.input.ticket_id,
                ilvy_treffer: state.treffer,
                language: rootState.input.language
            }
            return fetch('/api/zendesk/sendToZendesk/', {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then(response => response.json())
            .then(data => {
                
                if(!data.error){
                    commit('treffer', [])
                    commit('response_data', data)
                    return {success: 'success'}
                }else{
                    return data
                }
                
            })
        }
    }
}

export default treffer_store