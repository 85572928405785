<template >
<div id="popup_catsort" class="overlay showProducts" >
        <div class="popup">
            <h2>Produkte</h2>
            <div class="popup_content">
               <div v-for="(cat,i) in items" :key="i">{{cat}}</div>
            </div>
            <div class="popup_action">
                <a @click="closePopup" class="button close">Schließen</a>
                
            </div>
        </div>
    </div> 
</template>

<script>

export default {
    props: ['products'],
    data(){
        return {
          items: this.products
        } 
    },
    computed:{
      
    },
    watch:{
      
    },
    mounted(){
      
    },
    methods: {
        closePopup(){
            this.$parent.showProductBox = false
        }
    }   
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.closed{
  display: none;
}
.showProducts{
    overflow: scroll;
}
</style>
