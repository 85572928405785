import axios from 'axios';
const workflowinternes = {
    namespaced: true,
    state: () => ({
        categories: [],
        single: {},
    }),
    mutations: {
        setCategories(state, categories) {
            state.categories = categories;
        },
        addCategory(state, category) {
            state.categories.push(category);
        },
        updateCategory(state, category) {
            const index = state.categories.findIndex(c => c.id === category.id);
            state.categories[index] = category;
        },
        deleteCategory(state, id) {
            const index = state.categories.findIndex(c => c.id === id);
            state.categories.splice(index, 1);
        },
        setSingle(state, id){
            
            state.single = state.categories.find(c => c.id === id);
            console.log('setSingle', state.single);
        },
        resetSingle(state){
            state.single = {};
        }
    },
    actions: {
        setSingle({commit}, id){
            return new Promise((resolve) => {
                commit('setSingle', id);
                resolve();
            })
            
        },
        resetSingle({commit}){
            commit('resetSingle');
        },
        getCategories({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get('/api/wui/get')
                    .then(response => {
                        const sorted = response.data.sort((a, b) => a.position - b.position);
                        commit('setCategories', sorted);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        createCategory({ commit }, category) {
            return new Promise((resolve, reject) => {
                axios.post('/api/wui/create', category)
                    .then(response => {
                        commit('addCategory', response.data);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        updateCategory({ commit }, category) {
            return new Promise((resolve, reject) => {
                axios.put('/api/wui/update/' + category.id, category)
                    .then(response => {
                        commit('updateCategory', response.data);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        deleteCategory({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios.delete('/api/wui/delete/' + id)
                    .then(response => {
                        commit('deleteCategory', id);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    }
}

export default workflowinternes