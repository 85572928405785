const input_store = {
    namespaced: true,
    state: () => ({
        default_language: 'de',
        default_product: '',
        language: '',
        product: {
            slug: null,
            psi_slug: null,
            supportnews_tag: null,
            product_group: null,
            name: null,
            id: null
        },
        ticket_id: null,
        channel: null,

        query: [],
        doc_class: 0,
        categories: [],
        currentPage_ilvy: 1,
        currentPage_techex: 1,
        currentPage_fritzwiki: 1,
        doc_tab: null,
        info_tab: null
    }),
    mutations: {
        language(state, language) {
            state.language = language
        },
        product(state, product) {
            state.product.slug = product.ilvy_slug
            state.product.psi_slug = product.psi_slug
            state.product.supportnews_tag = product.supportnews_tag
            state.product.product_group = product.product_group
            state.product.name = product.name
            state.product.id = product.id
        },
        resetProduct(state) {
            state.product.slug = null
            state.product.psi_slug = null
            state.product.supportnews_tag = null
            state.product.product_group = null
            state.product.name = null
            state.product.id = null
        },
        ticket_id(state, ticket_id) {
            state.ticket_id = ticket_id
        },
        channel(state, channel) {
            state.channel = channel
        },
        query(state, query) {
            state.query = query
        },
        doc_class(state, doc_class) {
            state.doc_class = doc_class
        },
        categories(state, categories) {
            state.categories = categories
        },
        currentPage_ilvy(state, currentPage_ilvy) {
            state.currentPage_ilvy = currentPage_ilvy
        },
        currentPage_techex(state, currentPage_techex) {
            state.currentPage_techex = currentPage_techex
        },
        currentPage_fritzwiki(state, currentPage_fritzwiki) {
            state.currentPage_fritzwiki = currentPage_fritzwiki
        },
        doc_tab(state, doc_tab) {
            state.doc_tab = doc_tab
        },
        info_tab(state, info_tab) {
            state.info_tab = info_tab
        },
        default_product(state, product) {
            state.default_product = product
        }

    },
    actions: {
        changeDefaultProduct(context, product) {
            let url = '/api/msm/setDefaultProduct'
            let data = {
                product: product
            }
            return fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(() => {
                    context.commit('default_product', product)
                })

        },
        initDefaultProduct({ commit }) {
            let url = '/api/msm/getDefaultProduct'
            return fetch(url)
                .then(response => response.json())
                .then((data) => {
                    commit('default_product', data[0].ilvy_slug)
                    return data[0].ilvy_slug
                })
        },
        reset({ commit, dispatch, state }) {
            if(state.query.length && state.categories.length){
                commit('categories', [])
                commit('query', [])
                const queryString = ''
                const categories = []
                return Promise.all([
                    dispatch('items/getFritzWikiItems', {
                        supportnews_tag: state.product.supportnews_tag,
                        query: queryString,
                        categories: categories
                    }, { root: true }),
                    dispatch('items/getIlvyItems', {
                        product: state.product.slug,
                        query: queryString,
                        language: state.language,
                        categories: categories
                    }, { root: true }),
                    dispatch('items/getTechexItems', {
                        supportnews_tag: state.product.supportnews_tag,
                        query: queryString,
                        categories: categories
                    }, { root: true }),
                    dispatch('options/getCategoryTerms', {}, { root: true }),
                    dispatch('resetCurrentPage')
                ])
            }
            else{
                if(state.query.length) dispatch('query', [])
                if(state.categories.length) dispatch('categories', [])
            }
            commit('doc_class', 0)
            commit('currentPage_ilvy', 1)
            commit('currentPage_techex', 1)
            commit('currentPage_fritzwiki', 1)
        },
        resetCurrentPage({ commit }) {
            commit('currentPage_ilvy', 1)
            commit('currentPage_techex', 1)
            commit('currentPage_fritzwiki', 1)
        },
        resetToDefault({ dispatch, commit }) {
            dispatch('reset')
            commit('doc_tab', 'ilvy')
            commit('info_tab', 'support')
            /*
            return Promise.all([
                dispatch('product', {product:state.default_product, pub:null})
            ])
            */

        },
        product({ dispatch, commit, state, rootState }, data) {
            const controller = new AbortController()
            const signal = controller.signal
            setTimeout(() => {
                controller.abort()
            }, 8000)
            let queryString = state.query.length ? state.query.join(' ') : ''
            return fetch('/api/msm/getBySlug/' + data.product, { signal: signal })
                .then(resp => resp.json())
                .then(json => {
                    if (json.length) {
                        commit('product', json[0])
                        if (rootState.input.categories.length) commit('categories', [])
                        if (data.pub) {
                            dispatch('publication_ilvy/getContent', {
                                lang: state.language,
                                product: data.product,
                                id: rootState.publication_ilvy.content.doc_id
                            }, { root: true })
                        }
                        return Promise.all([
                            dispatch('options/categories', { product: data.product, language: state.language }, { root: true }),
                            dispatch('items/getIlvyItems', {
                                product: data.product,
                                query: queryString,
                                language: state.language,
                                categories: state.categories
                            }, { root: true }),
                            dispatch('items/getTechexItems', {
                                supportnews_tag: state.product.supportnews_tag,
                                query: queryString,
                                categories: state.categories
                            }, { root: true }),

                            dispatch('items/getFritzWikiItems', {
                                supportnews_tag: state.product.supportnews_tag,
                                query: queryString,
                                categories: state.categories
                            }, { root: true }),

                            dispatch('items/getPSIData', {
                                psi_slug: state.product.psi_slug,
                            }, { root: true }),
                            dispatch('options/getCategoryTerms', {}, { root: true })
                        ])
                        /*.then(() => {
                            return Promise.all([
                                
                            ])
                        })
                        */
                    }
                    else {
                        commit('resetProduct')
                        return Promise.resolve()
                    }


                })
        },
        language({ dispatch, commit, state }, language) {
            commit('language', language)
            let queryString = state.query.length ? state.query.join(' ') : ''
            return Promise.all([
                dispatch('options/products', language, { root: true }),
                dispatch('options/categories', { product: state.product.slug, language: language }, { root: true }),
                dispatch('items/getIlvyItems', {
                    product: state.product.slug,
                    query: queryString,
                    language: language,
                    categories: state.categories
                }, { root: true }),
                dispatch('options/getLanguageTerms', {}, { root: true }),
                    dispatch('options/getCategoryTerms', {}, { root: true })
            ])
            /*.then(() => {
                return Promise.all([
                    dispatch('options/getLanguageTerms', {}, { root: true }),
                    dispatch('options/getCategoryTerms', {}, { root: true })
                ])
            })
            */
        },
        categories({ dispatch, commit, state }, categories) {
            commit('categories', categories)
            let queryString = state.query.length ? state.query.join(' ') : ''
            return Promise.all([
                dispatch('items/getFritzWikiItems', {
                    supportnews_tag: state.product.supportnews_tag,
                    query: queryString,
                    categories: state.categories
                }, { root: true }),
                dispatch('items/getIlvyItems', {
                    product: state.product.slug,
                    query: queryString,
                    language: state.language,
                    categories: state.categories
                }, { root: true }),
                dispatch('items/getTechexItems', {
                    supportnews_tag: state.product.supportnews_tag,
                    query: queryString,
                    categories: state.categories
                }, { root: true }),
                dispatch('options/getCategoryTerms', {}, { root: true }),
                dispatch('resetCurrentPage')
            ])
        },
        query({ dispatch, commit, state }, query) {
            commit('query', query)
            let queryString = query.length ? query.join(' ') : ''
            return Promise.all([
                dispatch('items/getIlvyItems', {
                    product: state.product.slug,
                    query: queryString,
                    language: state.language,
                    categories: state.categories
                }, { root: true }),
                dispatch('items/getTechexItems', {
                    supportnews_tag: state.product.supportnews_tag,
                    query: queryString,
                    categories: state.categories
                }, { root: true }),

                dispatch('items/getFritzWikiItems', {
                    supportnews_tag: state.product.supportnews_tag,
                    query: queryString,
                    categories: state.categories
                }, { root: true }),
                dispatch('resetCurrentPage')

            ])
        }
    }
}
export default input_store