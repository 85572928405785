import { render, staticRenderFns } from "./WuiVerwaltung.vue?vue&type=template&id=cfc37420&scoped=true&"
import script from "./WuiVerwaltung.vue?vue&type=script&lang=js&"
export * from "./WuiVerwaltung.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfc37420",
  null
  
)

export default component.exports