<template>
  <div>
    <Header endType="Backend" :key="rerender" />
    <AdminNavigation processType="Kategorien" />
    <div class="main_area layout_container">
      <div class="backend">
        <h1 v-if="$route.params.type == 'update'">{{form.msm_name}} bearbeiten</h1>
        <h1 v-else>neue Kategorie</h1>
      </div>

      <div class="msm_box form">
        <fieldset>
          <div>
            <legend>Name</legend>
            <input type="text" class="name_edit" v-model="form.msm_name" />
          </div>
        </fieldset>
        <fieldset class="field_row">
          <div v-if="type == 'anlegen'">
            <legend>Ilvy-ID</legend>
            <input
              type="number"
              class="name_edit"
              v-model="form.id"
              @blur="validate(form.id)"
            />
            <div class="validation_error" v-if="validation_error">
              ID bereits vergeben
            </div>
          </div>
          <div v-if="type == 'anlegen'">
            <legend>Name Ilvy!-intern</legend>
            <input type="text" class="name_edit" v-model="form.ilvy_name" />
          </div>
          <div>
            <legend>TechEx-ID</legend>
            <input type="text" class="name_edit" v-model="form.tech_ex_tag" />
          </div>
          <div>
            <legend>FRITZ!Wiki-ID</legend>
            <input
              type="text"
              class="name_edit"
              v-model="form.fritz_wiki_tag"
            />
          </div>
          <div>
            <legend>SupportNews-Name</legend>
            <input
              type="text"
              class="name_edit"
              v-model="form.supnews_category_name"
            />
          </div>
        </fieldset>
        <fieldset class="field_row">
          <div>
            <legend>Anzeigename DE</legend>
            <input type="text" class="name_edit" v-model="form.client_de" />
          </div>
          <div>
            <legend>Anzeigename IT</legend>
            <input type="text" class="name_edit" v-model="form.client_it" />
          </div>
          <div>
            <legend>Anzeigename NL</legend>
            <input type="text" class="name_edit" v-model="form.client_nl" />
          </div>
          <div>
            <legend>Anzeigename EN</legend>
            <input type="text" class="name_edit" v-model="form.client_en" />
          </div>
          <div>
            <legend>Anzeigename ES</legend>
            <input type="text" class="name_edit" v-model="form.client_es" />
          </div>
          <div>
            <legend>Anzeigename FR</legend>
            <input type="text" class="name_edit" v-model="form.client_fr" />
          </div>
        </fieldset>
        <fieldset>
          <div id="language_terms">
            <legend>Suchbegriffe DE</legend>
            <MultiSelect
              slug="de"
              @addTag="addTag"
              @removeTag="removeTag"
              :searchterms="searchterms_de"
              :options="[]"
              placeholder="Suchbegriff(e) hinzufügen"
              tag_placeholder="Diesen Tag hinzufügen"
            />

            <legend>Suchbegriffe EN</legend>
            <MultiSelect
              slug="en"
              @addTag="addTag"
              @removeTag="removeTag"
              :searchterms="searchterms_en"
              :options="[]"
              placeholder="Suchbegriff(e) hinzufügen"
              tag_placeholder="Diesen Tag hinzufügen"
            />

            <legend>Suchbegriffe IT</legend>
            <MultiSelect
              slug="it"
              @addTag="addTag"
              @removeTag="removeTag"
              :searchterms="searchterms_it"
              :options="[]"
              placeholder="Suchbegriff(e) hinzufügen"
              tag_placeholder="Diesen Tag hinzufügen"
            />

            <legend>Suchbegriffe NL</legend>
            <MultiSelect
              slug="nl"
              @addTag="addTag"
              @removeTag="removeTag"
              :searchterms="searchterms_nl"
              :options="[]"
              placeholder="Suchbegriff(e) hinzufügen"
              tag_placeholder="Diesen Tag hinzufügen"
            />

            <legend>Suchbegriffe ES</legend>
            <MultiSelect
              slug="es"
              @addTag="addTag"
              @removeTag="removeTag"
              :searchterms="searchterms_es"
              :options="[]"
              placeholder="Suchbegriff(e) hinzufügen"
              tag_placeholder="Diesen Tag hinzufügen"
            />

            <legend>Suchbegriffe FR</legend>
            <MultiSelect
              slug="fr"
              @addTag="addTag"
              @removeTag="removeTag"
              :searchterms="searchterms_fr"
              :options="[]"
              placeholder="Suchbegriff(e) hinzufügen"
              tag_placeholder="Diesen Tag hinzufügen"
            />
          </div>
        </fieldset>
        <fieldset>
          <legend>Icon</legend>
          <textarea v-model="form.icon" class="icon_note"></textarea>
        </fieldset>
        <fieldset>
          <legend>Produkte</legend>
          <div v-for="(pg, index) in optionsProducts" :key="index">
            <p class="product_cat">{{ pg.label }}</p>
            <ul class="checkboxes">
              <li v-for="product in pg.options" :key="product.id">
                <input
                  type="checkbox"
                  :id="product.id"
                  v-model="approvedProducts"
                  :value="product.id"
                />
                <label :for="product.id">{{ product.ilvy_slug }}</label>
              </li>
            </ul>
          </div>
        </fieldset>
        <fieldset class="field_row">
          <div>
            <legend>Freigabe</legend>
            <input type="radio" id="aa" v-model="form.approved" value="1" />
            <label for="aa">Ja</label>
            <input type="radio" id="bb" v-model="form.approved" value="0" />
            <label for="bb">Nein</label>
          </div>
          <div>
            <legend>Schreibschutz</legend>
            <input type="radio" id="cc" v-model="form.write_perm" value="1" />
            <label for="cc">Ja</label>
            <input type="radio" id="dd" v-model="form.write_perm" value="0" />
            <label for="dd">Nein</label>
          </div>
        </fieldset>
        <div class="form_action">
          <a @click="cancel" class="button close">Abbrechen</a>
          <a @click="onSubmit" class="button important">Speichern</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MultiSelect from "@/components/VueSelectComponent";
import Header from "@/components/Header.vue";
import AdminNavigation from "@/components/AdminNavigation.vue";
export default {
  components: {
    Header,
    AdminNavigation,
    MultiSelect,
  },
  data() {
    return {
      type: this.$route.params.type,
      id_dont_exists: null,
      validation_error: false,
      approvedProducts: [],
      optionsProducts: [],
      ids: [],
      rerender: 0,
      form: {
        products: [],
        productslug: [],
        client: "",
        approved: "",
        write_perm: "",
        msm_name: "",
        ilvy_name: "",
        client_de: "",
        client_en: "",
        client_fr: "",
        client_it: "",
        client_es: "",
        client_nl: "",
        id: "",
        tech_ex_tag: "",
        fritz_wiki_tag: "",
        icon: "",
      },
      terms_de: [],
      terms_en: [],
      terms_it: [],
      terms_nl: [],
      terms_es: [],
      terms_fr: [],
    };
  },
  mounted(){
    /**
     * Notlösung
     */
    this.terms_de = [...this.searchterms_de]
    this.terms_en = [...this.searchterms_en]
    this.terms_it = [...this.searchterms_it]
    this.terms_nl = [...this.searchterms_nl]
    this.terms_es = [...this.searchterms_es]
    this.terms_fr = [...this.searchterms_fr]
  },
  computed: {
    /**
     * in diesem Fal nicht als computed, sondern data und zuweisung in mounted-hook
     */
    searchterms_de() {
      return this.$store.state.admin.category.searchterms_de;
    },
    searchterms_en() {
      return this.$store.state.admin.category.searchterms_en;
    },
    searchterms_it() {
      return this.$store.state.admin.category.searchterms_it;
    },
    searchterms_nl() {
      return this.$store.state.admin.category.searchterms_nl;
    },
    searchterms_es() {
      return this.$store.state.admin.category.searchterms_es;
    },
    searchterms_fr() {
      return this.$store.state.admin.category.searchterms_fr;
    },
  },
  created() {
    this.fetchData();
    this.rerender++;
  },
  methods: {
    removeTag(tag, id) {
      const type = `terms_${id}`;
      this[type] = this[type].filter(item => item != tag)
      this[type].sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
      //this.$store.commit("admin/category/deleteSearchTerm", data);
    },
    addTag(tag, id) {
      const type = `terms_${id}`;
      this[type].push(tag)
      this[type].sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
      //this.$store.commit("admin/category/addSearchterm", data);
    },
    cancel() {
      this.$router.push({ name: "kategorienverwaltung" });
    },
    fetchData() {
      const controller = new AbortController();
      const signal = controller.signal;
      setTimeout(() => {
        controller.abort();
      }, 5000);
      fetch("/api/msm/getAll/", { signal: signal })
        .then((resp) => resp.json())
        .then((json) => {
          if (json) {
            this.optionsProducts = this.parseProducts(json);
          }
        });
      if (this.$route.params.type == "update") {
        fetch("/api/categories/getSingleCatProd/" + this.$route.params.id, {
          signal: signal,
        })
          .then((resp) => resp.json())
          .then((json) => {
            if (json) {
              if (this.type == "update") {
                json.forEach((item) => {
                  if (item.product_id) {
                    this.approvedProducts.push(item.product_id);
                  }
                });
              }
            }
          });
        fetch("/api/categories/getSingle/" + this.$route.params.id, {
          signal: signal,
        })
          .then((resp) => resp.json())
          .then((json) => {
            if (json) {
              if (json[0].client) {
                let client = JSON.parse(json[0].client);
                this.form.client_de = client.de;
                this.form.client_en = client.en;
                this.form.client_es = client.es;
                this.form.client_fr = client.fr;
                this.form.client_it = client.it;
                this.form.client_nl = client.nl;
              }
              if (json[0].name) {
                this.form.msm_name = json[0].name;
              }
              if (json[0].ilvy_name) {
                this.form.ilvy_name = json[0].ilvy_name;
              }
              if (json[0].approved) {
                this.form.approved = json[0].approved;
              }
              if (json[0].write_perm) {
                this.form.write_perm = json[0].write_perm;
              }
              if (json[0].fritzwiki_tag) {
                this.form.fritz_wiki_tag = json[0].fritzwiki_tag;
              }
              if (json[0].techex_tag) {
                this.form.tech_ex_tag = json[0].techex_tag;
              }
              if (json[0].id) {
                this.form.id = json[0].id;
              }
              if (json[0].icon) {
                this.form.icon = json[0].icon;
              }
              if (json[0].supnews_category_name) {
                this.form.supnews_category_name = json[0].supnews_category_name;
              }
            }
          });
      }

      if (this.type == "anlegen") {
        fetch("/api/categories/getIds")
          .then((resp) => resp.json())
          .then((json) => {
            if (json) {
              this.ids = json;
            }
          });
      }

      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    parseProducts(json) {
      let product_groups = [];
      json.forEach((item) => {
        product_groups.push({ label: item.product_group, options: [] });
        product_groups[product_groups.length - 1].options.push(item);
      });
      const combined = {};
      product_groups.forEach((o) => {
        const id = o["label"];
        const obj = o["options"][0];
        if (!combined[id]) {
          combined[id] = [obj];
        } else {
          combined[id].push(obj);
        }
      });
      const result = [];
      Object.keys(combined).forEach((key) => {
        result.push({ label: key, options: combined[key] });
      });
      return result.reverse();
    },
    onSubmit() {
      let client = {
        de: this.form.client_de,
        en: this.form.client_en,
        fr: this.form.client_fr,
        it: this.form.client_it,
        es: this.form.client_es,
        nl: this.form.client_nl,
      };
      if (this.type == "anlegen") {
        this.createNewCategory(client, this.approvedProducts)
          .then(() => {
            const data = {
              de: this.terms_de,
              en: this.terms_en,
              it: this.terms_it,
              nl: this.terms_nl,
              es: this.terms_es,
              fr: this.terms_fr
            }
            this.$store.commit('admin/category/searchterms', data)
            this.$store.dispatch('admin/category/updateSearchTerms', this.form.id).then(() => {
              this.$router.push({ name: "kategorienverwaltung" });
            })
            
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (this.type == "update") {
        this.updateCategory(client, this.approvedProducts)
          .then(() => {
            const data = {
              de: this.terms_de,
              en: this.terms_en,
              it: this.terms_it,
              nl: this.terms_nl,
              es: this.terms_es,
              fr: this.terms_fr
            }
            this.$store.commit('admin/category/searchterms', data)
            this.$store.dispatch('admin/category/updateSearchTerms', this.form.id).then(() => {
              this.$router.push({ name: "kategorienverwaltung" });
            })
            
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    createNewCategory(client, products) {
      let url = "/api/categories/createNewCategory";
      return this.$http.post(url, {
        name: this.form.msm_name,
        ilvy_name: this.form.ilvy_name,
        client: client,
        id: this.form.id,
        techex_tag: this.form.tech_ex_tag,
        fritz_wiki_tag: this.form.fritz_wiki_tag,
        approved: this.form.approved,
        write_perm: this.form.write_perm,
        products: products,
        icon: this.form.icon,
        supnews_category_name: this.form.supnews_category_name
      });
    },
    updateCategory(client, products) {
      let url = "/api/categories/updateCategory";
      return this.$http.put(url, {
        name: this.form.msm_name,
        client: client,
        id: this.form.id,
        techex_tag: this.form.tech_ex_tag,
        fritz_wiki_tag: this.form.fritz_wiki_tag,
        approved: this.form.approved,
        write_perm: this.form.write_perm,
        products: products,
        icon: this.form.icon,
        supnews_category_name: this.form.supnews_category_name
      });
    },
    validate(item) {
      if (this.ids.includes(parseInt(item))) {
        this.id_dont_exists = false;
        this.validation_error = true;
        this.$refs.id.focus();
      } else {
        this.id_dont_exists = true;
        this.validation_error = false;
      }
    },
  },
};
</script>
<style  scoped>
.validation_error {
  padding: 10px;
  background-color: firebrick;
  color: white;
  border-radius: 5px;
  margin-top: 5px;
}

.checkboxes {
  display: grid;
  grid-template-columns: repeat(4, 25%);
}
</style>