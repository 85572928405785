import axios from 'axios';
const user_store = {
    namespaced: true,
    state: () => ({
        isAdmin: null,
        loggedIn: null,
        user: {
            name: null,
            id: null
        },
        userConfig: {
            onlyMyTerms: null,
            language: null,
            darkmode: null,
            favoriteDoks: [],
            searchterms: []
        }
    }),
    mutations: {
        login(state) {
            state.loggedIn = true
        },
        loginAdmin(state, status) {
            state.isAdmin = status
        },
        setUser(state, data) {
            state.user.name = data.name
            state.user.id = data.id
        },
        logout(state) {
            state.loggedIn = null
            state.isAdmin = null
            state.userConfig.language = null
            state.userConfig.darkmode = null
            state.userConfig.favoriteDoks = []
            state.user.name = null
            state.user.id = null
        },
        setUserConfig(state, data) {
            state.userConfig.onlyMyTerms = data.only_my_searchterms
            state.userConfig.language = data.language_slug
            state.userConfig.darkmode = data.view_type == "dark" ? true : false
            state.userConfig.favoriteDoks = []//data.favoriteDoks
        },
        onlyMyTerms(state, bool){
            state.userConfig.onlyMyTerms = bool
        },
        toggleDarkmode(state, bool) {
            state.userConfig.darkmode = bool
        },
        searchterms(state, searchterms){
            state.userConfig.searchterms = searchterms
        },
        addSearchterm(state, searchterm){
            state.userConfig.searchterms.push(searchterm)
        },
        deleteSearchterm(state, searchterm){
            state.userConfig.searchterms = state.userConfig.searchterms.filter(item => item !== searchterm)
        }
    },
    actions: {
        getSearchTerms(context, user_id){
            return axios.get(`/api/user_searchterms/get/${user_id}`).then(data => {
                return context.commit('searchterms', data.data.terms.sort() )
            })
        },
        updateSearchTerms({state}, user_id){ // vorerst bis store komplett implementiert ist
            const data = {
                terms: state.userConfig.searchterms
            }
            return axios.post(`/api/user_searchterms/create/${user_id}`, data).then(response => {
                return response
            })
        },
        getUserConfig({dispatch, commit}) {
            const controller = new AbortController()
            const signal = controller.signal
            setTimeout(() => {
                controller.abort()
            }, 8000)
            return fetch('/api/userConfig/getUserConfig', { signal: signal })
                .then(resp => resp.json())
                .then(json => {
                    commit('loginAdmin', json.isAdmin)
                    commit('setUser', json.user)
                    if (json.config) {
                        commit('setUserConfig', json.config)
                    }
                    dispatch('getSearchTerms', json.user.id)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        changeUserDefaultConfig({ commit, dispatch, state }, data) {
            return fetch('/api/userConfig/postUserConfig', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(() => {
                    commit('setUserConfig', { view_type: data.view_type, language_slug: data.language, only_my_searchterms: data.only_my_searchterms })
                    return Promise.all([
                        dispatch('input/language', data.language, {root: true}),
                        dispatch('updateSearchTerms', state.user.id)
                    ])
                        .then(() => {
                            return { success: 'success' }
                        })

                })
                .catch((error) => {
                    return { error: error }
                });
        },
        logout(context) {
            let url = "/api/auth/logout"
            return fetch(url)
                .then(() => {
                    context.commit('logout')
                })
        }
    }
}
export default user_store