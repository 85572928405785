<template>
  <div>
    <Header endType="Backend" :key="rerender" />
    <AdminNavigation processType="Sprachen" />
    <div class="main_area layout_container">
      <div class="backend">
        <h1>{{ lang_name }} bearbeiten</h1>
      </div>

      <div class="msm_box form">
        <fieldset>
          <legend>Name</legend>
          <input type="text" class="name_edit" v-model="lang_name" />
        </fieldset>

        <fieldset class="field_row">
          <div>
            <legend>Slug</legend>
            <input type="text" class="name_edit" v-model="lang_slug" />
          </div>
        </fieldset>
        <fieldset class="field_row">
          <div id="base_url">
            <legend>Basis-URL auf <a href="https://avm.de" target="_blank">avm.de</a></legend>
            <input type="text" class="name_edit" v-model="lang_url" />
          </div>
        </fieldset>
        <fieldset class="field_row">
          <div id="language_terms">
            <legend>Suchbegriffe</legend>
            <MultiSelect
              @addTag="addTag"
              @removeTag="removeTag"
              :searchterms="searchterms"
              :options="[]"
              placeholder="Suchbegriff hinzufügen"
              tag_placeholder="Diesen Tag hinzufügen"
            />
          </div>
        </fieldset>
        <div class="form_action">
          <a @click="cancel" class="button close">Abbrechen</a>
          <a @click="onSubmit" class="button important">Speichern</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MultiSelect from "@/components/VueSelectComponent";
import Header from "@/components/Header.vue";
import AdminNavigation from "@/components/AdminNavigation.vue";
export default {
  components: {
    Header,
    AdminNavigation,
    MultiSelect,
  },
  data() {
    return {
      rerender: 0,
      terms: [],
    };
  },
  computed: {
    lang_name() {
      return this.$store.state.admin.language.name;
    },
    lang_slug() {
      return this.$store.state.admin.language.slug;
    },
    lang_url() {
      return this.$store.state.admin.language.url;
    },
    searchterms: {
      get() {
        return this.$store.state.admin.language.searchterms;
      },
      set() {},
    },
  },
  mounted() {
    this.terms = [...this.searchterms];
  },
  methods: {
    removeTag(tag) {
      this.terms = this.terms.filter((item) => item != tag);
      this.terms.sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
      //this.$store.commit('admin/language/deleteSearchterm',tag)
    },
    addTag(tag) {
      this.terms.push(tag);
      this.terms.sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
      //this.$store.commit('admin/language/addSearchterm',tag)
    },
    cancel() {
      this.$router.push({ name: "sprachenverwaltung" });
    },
    onSubmit(evt) {
      evt.preventDefault();

      this.saveSearchTerms();
      this.saveLangdata()
        .then((response) => {
          if (response) {
            this.$router.push({
              name: "sprachenverwaltung",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveLangdata() {
      let url = "/api/languages/update/" + this.$route.params.id;
      return this.$http.put(url, {
        lang_name: this.lang_name,
        lang_slug: this.lang_slug,
        lang_url: this.lang_url,
      });
    },
    saveSearchTerms() {
      this.$store.commit('admin/language/searchterms', this.terms)
      return this.$store.dispatch(
        "admin/language/updateSearchTerms",
        this.lang_slug
      );
    },
  },
  watch: {},
};
</script>
<style  scoped>
#base_url {
  width: 50%;
}
#base_url > input {
  width: 100%;
}
</style>