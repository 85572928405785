<template>
  <div id="app">
    <router-view></router-view>
    <Footer />
   <BackToTop />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import BackToTop from "@/components/BackToTop.vue";
export default {
  name: "App",
  components: {
    Footer,
    BackToTop,
  },
  data() {
    return {};
  },
  computed: {
    darkmode() {
      return this.$store.state.user.userConfig.darkmode;
    },
  },
  watch: {
    darkmode(newVal) {
      this.checkForDarkmode(newVal);
    },
  },
  mounted() {
    this.checkForDarkmode(this.darkmode);
  },
  methods: {
    checkForDarkmode(darkmode) {
      if (darkmode) {
        document.body.classList.add("dark");
      } else {
        document.body.classList.remove("dark");
      }
    },
  },
};
</script>

<style>
.hovered {
  cursor: pointer;
}
</style>
