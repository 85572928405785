<template>
<div>
  <Header endType="Backend" />
  <AdminNavigation processType="Logins"/>
  <div id="layoutSidenav_content" class="main_area layout_container">
    <div class="backend">
        <h1>Logins</h1>
    </div>
      <div class="login_cred_con">
      <main class="main_con">
        <AuthVerwaltung />
        <hr>
        <AdminVerwaltung />
        <hr>
        <SNUserVerwaltung />    
      </main>
      </div>
    </div>
</div>
    
</template>

<script>
import Header from '@/components/Header.vue'
import AdminNavigation from '@/components/AdminNavigation.vue'
import AdminVerwaltung from '@/components/Adminverwaltung.vue'
import AuthVerwaltung from '@/components/Authverwaltung.vue'
import SNUserVerwaltung from '@/components/SNUserVerwaltung.vue'
  export default {
    components: {
      Header, AdminNavigation, AdminVerwaltung, AuthVerwaltung, SNUserVerwaltung
    },
    data() {
      
      return {
        
      }
    }
  }
</script>
<style  scoped>

</style>