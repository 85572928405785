<template>
  <multiselect
    :id="slug"
    v-model="terms"
    :tag-placeholder="tag_placeholder"
    :placeholder="placeholder"
    tagPlaceholder=""
    selectLabel="wählen"
    deselectLabel="entfernen"
    selectedLabel="bereits gewählt"
    :showNoResults="false"
    :multiple="true"
    :taggable="true"
    :options="options"
    @tag="addTag"
    @input="inputChanged"
    @remove="removeTag"
    :block-keys="['Delete']"
    :group-values="group_values"
    :group-label="group_label"
    :showNoOptions="false"
    :max-height="450"
    label=""
    ref="multiselect"
    class="terms_select"
  >
  </multiselect>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  props: {
    options: {
      type: Array,
    },
    placeholder: {
      type: String,
    },
    tag_placeholder: {
      type: String,
    },
    searchterms: {
      type: Array,
    },
    slug: {
      type: String,
    },
    group_values: {
      type: String,
    },
    group_label: {
      type: String,
    },
    is_homepage: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      terms: [],
    };
  },
  computed: {},
  watch: {
    searchterms(newVal) {
      this.terms = newVal;
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.terms = [...this.searchterms];
    this.terms.sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
  },
  updated() {},
  methods: {
    addTag(newTag, id) {
      if (!this.terms.some((item) => item == newTag)) {
        this.terms.push(newTag);
        this.$emit("valueChanged", this.terms);
        this.$emit("addTag", newTag, id);
      }

      if (!this.is_homepage) {
        this.$refs.multiselect.$el.focus();
      }
      this.terms.sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
    },
    inputChanged(input) {
      this.$emit("valueChanged", input);
    },
    removeTag(tag, id) {
      this.$emit("removeTag", tag, id);
    },
  },
};
</script>

<style scoped>
</style>