<template >
  <div>
    <form @submit="saveRMA" id="rmaform">
      <legend>RMA-Verfahren</legend>
      <ul class="rma_type">
        <li>
          <input
            type="radio"
            id="submission"
            name="rma_procedure"
            value="submission"
            v-model="form.rma_procedure"
          />
          <label for="submission">Einsendung</label>
        </li>
        <li>
          <input
            type="radio"
            id="advexchange"
            name="rma_procedure"
            value="advexchange"
            :disabled="!hasAdvexchange"
            v-model="form.rma_procedure"
          />
          <label :class="{ disable: !hasAdvexchange }" for="advexchange"
            >Vorabaustausch</label
          >
        </li>
      </ul>

      <hr />

      <legend>Notiz für die Logistik</legend>
      <textarea
        name="support_note"
        form="rmaform"
        v-model="form.support_note"
        class="rma_note"
      ></textarea>

      <hr />

      <legend>Austauschgrund</legend>

      <div v-if="!hasNoRMATreffer()" class="rma_dok">
        <span>{{ rma_treffer.title }}</span
        ><i
          @click="removeRMATreffer()"
          class="fas fa-times-circle"
          aria-hidden="true"
        ></i>
      </div>

      <div v-else>
        <ul class="rma_reason">
          <li>
            <input
              type="radio"
              id="defect"
              name="rma_reason"
              :value="rma_reasons[0].value"
              v-model="form.rma_reason"
            />
            <label for="defect">{{ rma_reasons[0].name }}</label>
          </li>
          <li>
            <input
              type="radio"
              id="customer_advexchange"
              name="rma_reason"
              :value="rma_reasons[1].value"
              v-model="form.rma_reason"
            />
            <label for="customer_advexchange">{{ rma_reasons[1].name }}</label>
          </li>
          <li>
            <input
              type="radio"
              id="prio"
              name="rma_reason"
              :value="rma_reasons[2].value"
              v-model="form.rma_reason"
            />
            <label for="prio">{{ rma_reasons[2].name }}</label>
          </li>
        </ul>
      </div>

      <hr />

      <div v-if="hasNoRMATreffer()">
        <legend>Beschreibung des Austauschgrunds</legend>
        <textarea
          v-model="form.rma_reason_notice"
          name="rma_reason_notice"
          form="rmaform"
          required
          oninvalid="this.setCustomValidity('Bitte ausfüllen')"
          oninput="this.setCustomValidity('')"
          class="rma_note"
          placeholder="Aussagekräftige Fehlerbeschreibung hier eintragen!"
        ></textarea>
      </div>

      <div class="form_action">
        <button class="button important" type="submit">Speichern</button>
      </div>
    </form>

    <MessageModal
      :message="rma_message"
      :showModal="showModal"
      @closeModal="showModal = false"
      buttonText="OK"
    />
  </div>
</template>

<script>
import MessageModal from "@/components/MessageModal.vue";
import statistics from "@/_helpers/statistics.js";
export default {
  components: {
    MessageModal,
  },
  data() {
    return {
      form: {
        rma_procedure: "submission",
        support_note: "",
        rma_reason: "", // set in created() hook
        rma_reason_slug: "", // set in created() hook
        rma_reason_notice: this.rma_treffer ? null : ""
      },
      rma_reasons: [
        {
          value: "rma_grund_defektvermutung",
          name: "Defektvermutung",
        },
        {
          value: "rma_grund_kunde_besteht_auf_austausch",
          name: "Kunde besteht auf Austausch",
        },
        {
          value: "rma_durch_prio-support_veranlasst",
          name: "2ndLevel-RMA",
        },
      ],
      rma_message: "Du kannst nur einen RMA gleichzeitig auslösen!",
      showModal: false,
    };
  },
  computed: {
    hasAdvexchange() {
      return this.$store.state.items.psi_data?.data?.fields[
        "rma-verfahren"
      ]?.data.includes("vorabaustausch");
    },
    rma_treffer: {
      get() {
        return this.$store.state.zendesk.rma_treffer;
      },
      set(newVal) {
        this.$store.commit("zendesk/rma_treffer", newVal);
      },
    },
    ilvy_treffer() {
      return this.$store.state.zendesk.treffer;
    },
    product() {
      return this.$store.state.input.product.slug;
    },
    product_name() {
      return this.$store.state.input.product.name;
    },
    product_group() {
      return this.$store.state.input.product.product_group;
    },
    ticket_id() {
      return this.$store.state.input.ticket_id;
    },
    requester_id() {
      return this.$store.state.zendesk.requester.id;
    },
    requester_email() {
      return this.$store.state.zendesk.requester.email;
    },
    hasSN() {
      return this.$store.state.items.psi_data.data.fields["hasSN"].data ==
        "true"
        ? true
        : false;
    },
    hasPowerAdapter() {
      return this.$store.state.items.psi_data.data.fields["hasPowerAdapater"]
        ?.data == "true"
        ? true
        : false;
    },
    warranty_duration() {
      return this.$store.state.items.psi_data?.data?.fields["garantiezeit"]
        ?.data;
    },
  },
  watch: {},
  created() {
    this.form.rma_reason = !this.hasNoRMATreffer()
      ? this.rma_treffer?.title
      : "rma_grund_defektvermutung";
    this.form.rma_reason_slug = !this.hasNoRMATreffer()
      ? this.rma_treffer?.rma_reason
      : "rma_grund_defektvermutung";
  },
  mounted() {
    if (!this.requester_id) {
      this.$emit('startLoading')
      this.$store
        .dispatch("zendesk/getRequester", this.ticket_id)
        .then(() => {
          this.$emit('stopLoading')
          if (!this.requester_id) {
            this.$store.commit("input/info_tab", 'support');
            this.$emit('showModal')
          }
        })
        .catch((error) => {
          this.$emit('stopLoading')
          console.error(error);
          this.$emit('showModal')
        });
    } else {
      this.$store.commit("input/info_tab", 'rma');
    }
  },
  methods: {
    saveRMA(e) {
      e.preventDefault();
      if (!this.ilvy_treffer.some((item) => item.type == "rma")) {
        const title = !this.hasNoRMATreffer()
          ? this.form.rma_reason
          : this.getRMAReasonName(this.form.rma_reason);
        const rma_reason = !this.hasNoRMATreffer()
          ? this.form.rma_reason_slug
          : this.form.rma_reason;
        const data = {
          type: "rma",
          doc_type: this.rma_treffer.type,
          doc_id: this.rma_treffer.doc_id,
          doc_slug: this.rma_treffer.doc_slug,
          product: this.product,
          title: "RMA: " + title,
          originalTitle: title,
          rma: {
            requester: {
              id: this.requester_id,
              email: this.requester_email,
            },
            ticket_id: this.ticket_id,
            product: {
              name: this.product_name,
              slug: this.product,
              product_group: this.product_group,
              warranty_duration: this.warranty_duration,
              hasSN: this.hasSN,
              hasPowerAdapter: this.hasPowerAdapter,
            },
            rma_procedure: this.form.rma_procedure,
            support_note: this.form.support_note,
            rma_reason: rma_reason,
            rma_reason_notice: this.form.rma_reason_notice,
            originDB: this.rma_treffer.originDB
          },
        };
        this.sendToES();
        this.$store.commit("zendesk/push_rma_to_ilvy_treffer", data);
        this.$store.commit("input/info_tab", "support");
      } else {
        this.showModal = true;
      }
    },
    elasticSearch: statistics.elasticSearch,
    sendToES() {
      const url = "/api/stats/rma";
      const data = {
        category: this.$store.state.input.categories
          ? this.$store.state.input.categories.join(",")
          : 0,
        language: this.$store.state.input.language,
        product: this.$store.state.input.product.name,
        product_group: this.$store.state.input.product.product_group,
        searchquery: this.$store.state.input.query.length
          ? this.$store.state.input.query.join(" ")
          : "",
        ticket_id: this.$store.state.input.ticket_id ?? 0,
        topic: {
          name: !this.hasNoRMATreffer() ? this.rma_treffer?.title : null,
        },
        topic_container_name: !this.hasNoRMATreffer()
          ? this.rma_treffer?.title
          : null,
        support_type: this.$store.state.input.channel ?? 0,
        rma_type:
          this.form.rma_procedure == "submission"
            ? "einsendung"
            : "vorabaustausch",
        rma_slug: !this.hasNoRMATreffer() ? this.form.rma_reason_slug : "-",
        rma_reason: !this.hasNoRMATreffer()
          ? this.rma_treffer.type
          : this.getRMAReasonESName(this.form.rma_reason),
      };
      this.elasticSearch(url, data);
    },
    getRMAReasonName(value) {
      return this.rma_reasons.find((item) => item.value === value).name;
    },
    getRMAReasonESName(reason) {
      return reason == "rma_grund_defektvermutung"
        ? "defektvermutung"
        : reason == "rma_grund_kunde_besteht_auf_austausch"
        ? "kundenwunsch"
        : "prio-supports";
    },
    removeRMATreffer() {
      this.rma_treffer = {};
      this.form.rma_reason = !this.hasNoRMATreffer()
        ? this.rma_treffer?.title
        : "rma_grund_defektvermutung";
      this.form.rma_reason_slug = !this.hasNoRMATreffer()
        ? this.rma_treffer?.rma_reason
        : "rma_grund_defektvermutung";
    },
    hasNoRMATreffer() {
      return (
        this.rma_treffer &&
        Object.keys(this.rma_treffer).length === 0 &&
        Object.getPrototypeOf(this.rma_treffer) === Object.prototype
      );
    },
  },
};
</script>