<template>
    <div>
      <h2 class="backend">Admins</h2>
      <button @click="openaddAdminModal()" class="button right">Admin hinzufügen</button>
      <div class="table">
            <ul class="admins_table">
              <li class="table_header">
                <span class="left_align">User-ID</span>
                <span class="left_align">Name</span>
                <span class="left_align"></span>
                <span class="left_align"></span>
              </li>
              <li v-for="item in items_ids" :key="item.id">
                <span>{{item.admin_id}}</span>
                <span>{{item.name}}</span>
                <span id="update" class="status_icon edit" style="cursor:pointer" @click="updateAdmin(item)"></span>
                <span class="status_icon delete" style="cursor:pointer" @click="openDeleteAdminModal(item)"></span>
              </li>
            </ul>
        </div>        
        
        <div id="popup_catsort" class="overlay" v-if="addAdminModal">
            <div class="popup">
                <h2>Admin hinzufügen</h2>
                <div class="popup_form">
                  <label for="name">Name</label>
                  <input type="text" name="name" v-model="form_admins.name">
                  <label for="id">ID</label>
                  <input type="number" name="id" v-model="form_admins.admin_id">
                </div>
                <div class="popup_action">
                    <a @click="closeAddAdminModal()" class="button close">Schließen</a>
                    <a @click="changeAdmins('new')" class="button icon important">Speichern</a>
                </div>
            </div>
        </div>

        <div id="popup_catsort" class="overlay" v-if="updateAdminModal">
            <div class="popup">
                <h2>Admin bearbeiten</h2>
                <div class="popup_form">
                  <label for="name">Name</label>
                  <input type="text" name="name" v-model="adminName">
                  <label for="id">ID</label>
                  <input type="number" name="id" v-model="adminId">
                </div>
                <div class="popup_action">
                    <a @click="closeUpdateAdminModal()" class="button close">Schließen</a>
                    <a @click="saveUpdatedAdmin()" class="button icon important">Speichern</a>
                </div>
            </div>
        </div>

        <div id="popup_catsort" class="overlay" v-if="deleteAdminModal">
            <div class="popup">
              <h2>Admin löschen</h2>
                <p>Sollen <strong>{{adminName}}</strong> wirklich die Admin-Rechte entzogen werden?</p>
                <div class="popup_action">
                    <a @click="closeDeleteAdminModal()" class="button close">Nein</a>
                    <a @click="deleteAdmin()" class="button icon important">Ja</a>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>

  export default {
    data() {

      return {
        adminId: null,
        adminName: '',
        dbId: null,
        addAdminModal: false,
        updateAdminModal: false,
        deleteAdminModal: false,
        changeAuthDataModal: false,
        form_admins: {
          name: '',
          admin_id: null
        },
        
        items_ids: [],
        
        
      }
    },
    computed: {

    },
    mounted() {
      this.getAdminData()
    },
    
    methods: {
      getAdminData(){
        const controller = new AbortController()
        const signal = controller.signal
        setTimeout(() => { 
          controller.abort()
        }, 5000)
        fetch('/api/auth/getAdmins', {signal:signal})
        .then(resp => resp.json())
        .then(json => {
          this.items_ids = json                         
        })
        .catch(error => {
            console.error(error)
        })
      },
      closeUpdateAdminModal(){
        this.updateAdminModal = false
      },
      updateAdmin(item){
        this.adminId = item.admin_id
        this.adminName = item.name
        this.dbId = item.id
        this.updateAdminModal = true
      },
      saveUpdatedAdmin(){
        this.changeAdmins('update')
      },
      closeDeleteAdminModal(){
        this.deleteAdminModal = false
      },
      changeAdmins(type){
        const controller = new AbortController()
        const signal = controller.signal
        setTimeout(() => { 
          controller.abort()
        }, 5000)
        let url = ''
        let options;
        let data;
        if(type == 'new'){
          data = {
          name: this.form_admins.name, 
          admin_id: this.form_admins.admin_id
        }
          url = '/api/auth/postAdmin';
          options = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
            signal:signal
          };
        }
        else if(type == 'update'){
          data = {
          name: this.adminName, 
          admin_id: this.adminId,
          id: this.dbId
        }
          url = '/api/auth/updateAdmin'
          options = {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
            signal:signal
          };
        }
        
        fetch(url, options)
        .then(response => {
          return response.json()
        })
        .then(response => {
          if(response){
            if(type == 'new'){
              this.items_ids.push({name: this.form_admins.name, admin_id: this.form_admins.admin_id, id:response.id})
              this.closeAddAdminModal()
            }
            else{
              window.location.reload()
            }
            
            this.form_admins.name = ''
            this.form_admins.admin_id = null
          }
        })
        .catch(error => {
          console.log(error)
          alert('Das war nicht möglich. Grund: ' + error)
          window.location.reload()
        })
        
      },
      openDeleteAdminModal(item) {
        this.adminName = item.name
        this.adminId = item.admin_id
        this.dbId = item.id
        this.deleteAdminModal = true

      },
      openaddAdminModal(){
        this.addAdminModal = true
      },
      closeAddAdminModal(){
        this.addAdminModal = false
      },
      deleteAdmin(){
        const controller = new AbortController()
        const signal = controller.signal
        setTimeout(() => { 
          controller.abort()
        }, 5000)
        let url = "/api/auth/deleteAdmin/"+this.dbId        
        let options = {
          method: 'DELETE',
          signal:signal         
        };               
        fetch(url, options)
        .then(response => {
          if(response){
            window.location.reload()
          }
        })
        .catch(error => {
          console.log(error)
          alert('Das war nicht möglich. Grund: ' + error)
          window.location.reload()
        })
      }
    }
  }
</script>
<style  scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>