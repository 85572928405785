<template >
<div v-if="all_items.length" class="table">   <!-- TABLE ILVY-->

    <div v-if="all_items.length" class="table_header">    
        <ul v-if="type == 'ilvy'" class="viewfilter" >
            <li>
                <input type="radio" id="all" name="Dokklasse" value=0 v-model="selected_doc_class" :checked="selected_doc_class == 0">
                <label for="all">Alle</label>
            </li>
            <li v-for="doc_class in doc_classes" :key="doc_class.id">                
                <input type="radio" :id="doc_class.id" :value="doc_class.id" v-model="selected_doc_class" name="Dokklasse" :checked="selected_doc_class == doc_class.id">
                <label :for="doc_class.id">{{doc_class.name}}</label>
            </li>            
        </ul> 
        <br v-else>
        <ul class="pagination">
            <li @click="paginate(count,1)" class="page_first hovered" :class="{ 'disabled' : currentPage == 1}"><a ><i class="fas fa-angle-double-left"></i></a></li>
            <li @click="paginate(count,currentPage-1)" class="page_before hovered" :class="{ 'disabled' : currentPage == 1}"><a >Vorherige</a></li>                        
            <li @click="paginate(count,page)" v-for="page in max_pages" :key="page" class="page hovered" :class="{ 'active' : currentPage == page}"><a>{{page}}</a></li>
            <li @click="paginate(count,currentPage+1)" class="page_next hovered" :class="{ 'disabled' : currentPage == last_page}"><a>Nächste</a></li>
            <li class="page_last hovered" @click="paginate(count, last_page)" :class="{ 'disabled' : currentPage == last_page}"><a ><i class="fas fa-angle-double-right"></i></a></li>
        </ul>
    </div>
    
        <ul :class="{ 'techex_table' : type == 'techex', 'doc_table' : type == 'ilvy', 'fritzwiki_table' : type == 'fritzwiki'}">
            <li v-for="item in table_items" :key="item.id" >
                <span v-if="type == 'ilvy'" class="doc_id">{{type == 'ilvy' ? item.doc_id : (type == 'techex' ? item.itemId : item.id)}}</span>  
                <span v-if="type == 'ilvy'" :class="'doc_class_'+item.doc_class_id"></span>
                <span class="doc_title">
                    <a @click="linkToPublication($event, publication_link(item))" :href="publication_link(item)" class="hovered">
                        <span v-html="markQuery(item.title)"></span>
                    </a>
                </span>
                <span class="doc_fav"><a class="is_fav"></a></span>
            </li>
        </ul>
        
    <div class="table_footer">
        <ul class="pagination">
            <li @click="paginate(count,1)" class="page_first hovered" :class="{ 'disabled' : currentPage == 1}"><a ><i class="fas fa-angle-double-left"></i></a></li>
            <li @click="paginate(count,currentPage-1)" class="page_before hovered" :class="{ 'disabled' : currentPage == 1}"><a >Vorherige</a></li>                        
            <li @click="paginate(count,page)" v-for="page in max_pages" :key="page" class="page hovered" :class="{ 'active' : currentPage == page}"><a>{{page}}</a></li>
            <li @click="paginate(count,currentPage+1)" class="page_next hovered" :class="{ 'disabled' : currentPage == last_page}"><a>Nächste</a></li>
            <li class="page_last hovered" @click="paginate(count, last_page)" :class="{ 'disabled' : currentPage == last_page}"><a ><i class="fas fa-angle-double-right"></i></a></li>
        </ul>
    </div>
</div>
</template>

<script>

export default {
    props: ['itemsAll', 'page', 'tabletype'],
    data(){
        return {
            count: this.itemsAll?  this.itemsAll.length : 0,
            currentPage: this.page ? this.page : 0,
            records_per_page: 6,
            totalPages: 0,
            startPage: 0,
            endPage: 0,
            startIndex: 0,
            endIndex: 0,
            max_pages: [],
            table_items: [],
            all_items: this.itemsAll ? this.itemsAll : [],
            last_page: null,
            type: this.tabletype ? this.tabletype : '',
            filtered_items: this.all_items ? this.all_items : [],
            testClass: '',
            has_ilvy: this.hasIlvy

        } 
    },
    watch:{
        all_items(){
            this.paginate(this.count,this.currentPage)
            this.pushToTable()
        }
    },
    computed: {
        query(){
            let string = this.$store.state.input.query.length ? this.$store.state.input.query.join('|') : ''
            return new RegExp(string, 'gi')
        },
        ticket_id(){
            return this.$store.state.input.ticket_id
        },
        doc_classes(){
            return this.$store.state.options.docClasses
        },
        selected_doc_class:{
            get(){
                return this.$store.state.input.doc_class ? this.$store.state.input.doc_class : 0
            },
            set(newVal){
                this.$store.commit('input/doc_class', newVal)
            }
        },
        language(){
            return this.$store.state.input.language
        },
        product(){
            return this.$store.state.input.product.slug
        },
        
    },
    mounted(){
        if(this.type == 'ilvy'){
            this.filterFunction(this.selected_doc_class)
        }
        else{
            this.filtered_items = this.all_items
            this.paginate(this.count,this.currentPage)
            this.pushToTable()
        }
        
    },
    methods: {
        markQuery(value){            
            if (this.query){                
                value = value.replace(new RegExp(this.query, 'gi'), (match) => {                    
                    return `<mark>${match}</mark>`
                })            
            }
            return value        
        },
        linkToPublication(e, link){
            e.preventDefault()
            this.$router.push(link)
        },
        publication_link(item){
            if(this.tabletype === 'ilvy'){
                return `/publication/${this.language}/${this.product}/${item.doc_id}_${this.replaceSymbols(item.slug)}` + (this.ticket_id ? '?ticket_id=' + this.ticket_id : '')
            }
            if(this.tabletype === 'techex'){
                return `/supnews/techex/${this.product}/${item.itemId}/${this.replaceSymbols(item.title)}` + (this.ticket_id ? '?ticket_id=' + this.ticket_id: '')
            }
            if(this.tabletype === 'fritzwiki'){
                return `/supnews/fritzwiki/${this.product}/${item.itemId}/${this.replaceSymbols(item.title)}` + (this.ticket_id ? '?ticket_id=' + this.ticket_id: '')
            }
        },
        replaceSymbols(string){
            return string.replaceAll(' ', '-').replaceAll('/', '')
        },
        filterFunction(newVal){
            if(newVal == 0){
                this.filtered_items = this.all_items
                this.paginate(this.filtered_items.length,this.currentPage)
                this.pushToTable()
            }
            else{
                let filt = this.all_items.filter(item => {
                    if (newVal == item.doc_class_id){
                        return item
                    }
                })
                this.filtered_items = filt
                //this.count = this.filtered_items.length
                this.paginate(this.filtered_items.length,this.currentPage)
                this.pushToTable()
            }
            
        },
        showFilter(e){
            if(e.target.children[1]){
                 e.target.children[1].classList.remove('closed')
            }
        },
        closeFilter(){
            document.querySelector('.filter_select_open').classList.add('closed')
        },
        paginate(totalItems, currentPage = 1, pageSize = 15, maxPages = 6) {
          
            // calculate total pages
            let totalPages = Math.ceil(totalItems / pageSize);
                this.last_page = totalPages
            // ensure current page isn't out of range
            if (currentPage < 1) {
                currentPage = 1;
            } else if (currentPage > totalPages) {
                currentPage = totalPages;
            }

            let startPage, endPage;
            if (totalPages <= maxPages) {
                // total pages less than max so show all pages
                startPage = 1;
                endPage = totalPages;
            } else {
                // total pages more than max so calculate start and end pages
                let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
                let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
                if (currentPage <= maxPagesBeforeCurrentPage) {
                    // current page near the start
                    startPage = 1;
                    endPage = maxPages;
                } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
                    // current page near the end
                    startPage = totalPages - maxPages + 1;
                    endPage = totalPages;
                } else {
                    // current page somewhere in the middle
                    startPage = currentPage - maxPagesBeforeCurrentPage;
                    endPage = currentPage + maxPagesAfterCurrentPage;
                }
            }

            // calculate start and end item indexes
            let startIndex = (currentPage - 1) * pageSize;
            let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

            // create an array of pages to ng-repeat in the pager control
            let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

            // return object with all pager properties required by the view
            this.count = totalItems,
            this.currentPage = currentPage,
            this.records_per_page = pageSize,
            this.totalPages = totalPages,
            this.startPage = startPage,
            this.endPage = endPage,
            this.startIndex = startIndex,
            this.endIndex = endIndex,
            this.max_pages = pages

            let pagename = 'currentPage_'+this.type
        
            this.$store.commit(`input/${pagename}`, currentPage)
            this.pushToTable()
          
        },
        pushToTable(){
            this.table_items = []
            for(let i = this.startIndex; i <= this.endIndex; i++){
                this.table_items.push(this.filtered_items[i])
            }
            
            this.$store.commit(`items/items_${this.type}_length`, this.filtered_items.length)
        },
    }   
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hovered{
    cursor: pointer;
}
.closed{
    display: none;
}
.disabled {
    pointer-events:none; 
    opacity:0.6;        
}

.filter_select > ul{
  position:absolute;
  z-index:5;
  background-color: white;
  border: 1px solid #353c41;
  padding:5px
}
.filter_select >ul >li{
  margin: 5px 0;
}
.table_footer{
    justify-content: flex-end
}

</style>
