<template >
  <div>
    <Header endType="Frontend" />
    <div id="layoutSidenav_content">
      <main class="main_con">
        <div class="container-fluid">
          <div class="form_container main_area layout_container">
            <h1 class="settings">Einstellungen</h1>
            <p>Verwalte hier die Einstellungen Deines Benutzerkontos.</p>
            <div class="form_wrapper msm_box" style="clear: both">
              <form @submit="onSubmit" class="settings">
                <legend>Standard-Sprache</legend>
                <ul class="language_filter">
                  <li v-for="lang in optionsLanguage" :key="lang.id">
                    <input
                      v-model="language"
                      type="radio"
                      name="Sprache"
                      :id="lang.slug"
                      :value="lang.slug"
                    />
                    <label :for="lang.slug">{{ lang.name }}</label>
                  </li>
                </ul>
                <hr />
                <legend>Standard-Ansicht</legend>
                <ul class="view">
                  <li>
                    <input
                      v-model="viewType"
                      type="radio"
                      name="viewType"
                      id="viewTypeBright"
                      value="bright"
                    />
                    <label for="viewTypeBright">Hell (Standard)</label>
                  </li>
                  <li>
                    <input
                      v-model="viewType"
                      type="radio"
                      name="viewType"
                      id="viewTypeDark"
                      value="dark"
                    />
                    <label for="viewTypeDark">Dunkel</label>
                  </li>
                </ul>
                <hr />
                <legend>Meine Suchbegriffe</legend>

                <MultiSelect
                  @addTag="addTag"
                  @removeTag="removeTag"
                  :searchterms="searchterms"
                  :options="[]"
                  placeholder="Suchbegriff(e) hinzufügen"
                  tag_placeholder="Diesen Tag hinzufügen"
                />
                <input
                  v-model="onlyMyTerms"
                  type="checkbox"
                  name="terms"
                  id="terms"
                  true-value="1"
                  false-value="0"
                />
                <label for="terms"
                  >Nur diese Suchbegriffe vorschlagen</label
                >
                <hr />
                <div v-if="success" class="alert alert-success" role="alert">
                  Einstellungen wurden erfolgreich gespeichert
                </div>
                <div v-if="fail" class="alert alert-danger" role="alert">
                  Einstellungen konnten nicht gespeichert werden. Grund:
                  {{ fail_status }}
                </div>
                <div class="button_con">
                  <button
                    class="button primary_button right"
                    type="submit"
                    variant="success"
                  >
                    Speichern
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MultiSelect from "@/components/VueSelectComponent";
import Header from "@/components/Header.vue";
export default {
  components: {
    Header,
    MultiSelect,
  },
  data() {
    return {
      viewType: "",
      language: null,
      loading_con: "loading_screen_off",
      success: false,
      fail: false,
      fail_status: "",
      //onlyMyTerms: false
      terms: [],
      only_my_terms: null
    };
  },
  computed: {
    onlyMyTerms: {
      get() {
        return this.$store.state.user.userConfig.onlyMyTerms;
      },
      set(val) {
        this.only_my_terms = val
      },
    },
    searchterms: {
      get() {
        return this.$store.state.user.userConfig.searchterms;
      },
      set() {
      },
    },
    
    optionsLanguage() {
      return this.$store.state.options.languages;
    },
  },
  mounted() {
    this.only_my_terms = this.onlyMyTerms
    this.terms = [...this.searchterms]
    this.viewType = this.$store.state.user.userConfig.darkmode
      ? "dark"
      : "bright";
    this.language = this.$store.state.user.userConfig.language
      ? this.$store.state.user.userConfig.language
      : "de";
  },
  methods: {
    removeTag(tag) {
      this.terms = this.terms.filter(item => item != tag)
      this.terms.sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
      //this.$store.commit("user/deleteSearchterm", tag);
    },
    addTag(tag) {
      this.terms.push(tag)
      this.terms.sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
      //this.$store.commit("user/addSearchterm", tag);
    },
    onSubmit(e) {
      e.preventDefault();
      this.saveSearchTerms()
      this.$store
        .dispatch("user/changeUserDefaultConfig", {
          view_type: this.viewType,
          language: this.language,
          only_my_searchterms: this.only_my_terms == null ? '0' : this.only_my_terms,
        })
        .then((status) => {
          if (!status.error) {
            this.checkLastRoute();
          } else {
            this.success = false;
            this.fail = true;
            this.fail_status = status.error.status;
          }
        });
    },
    saveSearchTerms(){
      this.$store.commit('user/searchterms', this.terms)
      return this.$store.dispatch('user/updateSearchTerms',this.$store.state.user.user.id)
    },
    checkLastRoute() {
      let query = [];
      query.push({ cat: this.$store.state.input.categories.join(",") });
      query.push({ q: this.$store.state.input.query.join(" ") });
      let obj = Object.assign({}, ...query);
      let product = this.$store.state.input.product.slug;
      this.$router.push({
        name: "suchmaske",
        params: { lang: this.language, product: product },
        query: obj,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*form{
  width: 30%;
  margin: auto;
  border: 1px solid black;
  padding: 10px;
  margin: 20px auto;
  border-radius: 5px;
}
.view li {
  list-style: none;
}
.view{
  margin: 10px 0;
}*/
</style>
