export const authenticationService = {
    login,
    logout,
    checkLogin,
    checkCookie,
    getCookie,
    getHeaderData,
}

function checkLogin(){
    let url = "/api/auth/checkLogin"    
    return fetch(url)
    .then(response => {
        if(!response){ throw response}
        if (response.status == 200){
            return true
        }
        return false
    })
    .catch(error => {
        console.error(error)
    })
    
    
}
function checkCookie(value){
    
    if (getCookie(value) == 'true'){
        return true
    }
    else{
        return false
    }
    
}
/*
async function isAdmin(){
    let url = "/api/auth/checkAdmin"    
    let status = await fetch(url)
    .then(response => {
        if (response.status == 200){
            return true
        }
        return false
    })
    .catch(error => {
        console.error(error)
    })
    return status
}
*/


function login(email, password){
    let cred = Buffer.from(email + ':' + password).toString('base64')
    let url = "/api/auth/login"
    
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + cred
        }
    })
    .then(response => {
        return response;        
    })
    .catch(error => {
        console.log(error)
    })
}
function logout(){
    let url = "/api/auth/logout"    
    return fetch(url)
    .then(response => {
        if(response.status == 200){
            return true
        }
        else{
            return false
        }
    })
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

function getHeaderData(){
    let url = "/api/auth/headerData"
    return fetch(url)
    .then(response => response.json()
    .then(response => response))

}

