<template>
<!-- MUSS NOCH UMGESTELLT WERDEN-->
<div>
  <Header endType="Backend" :key="rerender"/>
  <AdminNavigation processType="Kategorien"/>
  <div class="main_area layout_container">
    <div class="backend">
        <h1>Kategorien</h1>
    </div>
    <a @click="showSyncBox = true" class="button icon important sync">Synchronisieren</a>
    <a @click="showOrderBox = true" class="button icon sort">Reihenfolge ändern</a>
    <a @click="createNew" class="button icon new">Neue Kategorie</a>
    <div id="popup_catsync" class="overlay" v-if="showSyncBox">
        <div class="popup">
            <h2>Kategorien synchronisieren</h2>
            <div class="popup_content">
                Sollen die Kategorien mit dem Ilvy!-CMS synchronisiert werden?
                <br><span class="attention">Kategorien ohne Schreibschutz werden beim Synchronisieren überschrieben!</span>
            </div>
            <div class="popup_action">
                <a @click="showSyncBox = false" class="button close">Abbrechen</a>
                <a @click="syncData" class="button important">Sychronisieren</a>
            </div>
        </div>
    </div>
    <DeleteItem :item="category_to_delete" v-if="showDeleteItem" process_type="Kategorie"/>
    
    <div id="popup_catsort" class="overlay" v-if="showOrderBox">
        <div class="popup">
            <h2>Reihenfolge ändern</h2>
            <div class="popup_content sort_list">
              <transition-group name="slide-fade">
                <div v-for="(cat,i) in items" :key="i" class="sort_item" draggable="true" @dragstart="dragStart(i, $event)" @dragover.prevent @dragend="dragEnd" @drop="dragFinish(i, $event)">{{cat.name}}</div>
              </transition-group>
            </div>
            <div class="popup_action">
                <a @click="showOrderBox = false" class="button close">Verwerfen</a>
                <a @click="handleOk" class="button icon important">Speichern</a>
            </div>
        </div>
    </div>

    <ShowProducts :products="products_to_show" v-if="showProductBox"/>
    <div class="table">
        <div class="table_header">
          <input v-model="filter" type="search" placeholder="Kategorien durchsuchen" class="cat_search">
            
          <ul class="viewfilter">
            <li id="show_all_searchterms">
              <input
                  v-model="showAllTerms"
                  type="checkbox"
                  name="terms"
                  id="terms"
                  true-value="1"
                  false-value="0"
                  @input="saveShowAllTerms"
                />
                <label for="terms"
                  >Alle Suchbegriffe anzeigen</label
                >
            </li>
            <li id="write_perm_off">
              <a @click="updateApprovement_Permission_all(false)" class="protect_all hovered">
                  <i class="fas fa-lock-open"></i>
                  Alle freigeben
              </a>
            </li>
            <li>
              <a @click="updateApprovement_Permission_all(true)" class="protect_all hovered">
                  <i class="fas fa-lock"></i>
                  Alle schreibschützen
              </a>
            </li>
          </ul>
        </div>
        <ul class="cat_table">
            <li class="table_header">
                <span class="left_align">Name</span>
                <span class="left_align">Sprachen</span>
                <span>SKB-ID</span>
                <span>TechEx-ID</span>
                <span>F!Wiki-ID</span>
                <span>Produkte</span>
                <span>Freigabe</span>
                <span>Schreibschutz</span>
            </li>
            <li v-for="cat in filtered_items" :key="cat.id">
              <span>{{cat.ilvy_name}}</span>
              <div class="languages">
                <span v-for="(client, index) in cat.client" :key="index" :class="index"></span>
              </div>
              <span class="id" :class="{ 'status_icon uncheck' : !cat.id}">{{cat.id}}</span>
              <span class="id" :class="{ 'status_icon uncheck' : !cat.techex_tag}">{{cat.techex_tag}}</span>
              <span class="id" :class="{ 'status_icon uncheck' : !cat.fritzwiki_tag}">{{cat.fritzwiki_tag}}</span>
              <a @click="setProductsToShow(cat.products)" class="status_icon search hovered"></a>
              <a @click="cat.approved == 1 ? cat.approved = 0 : cat.approved = 1; updateApprovement_Permission(cat.id, cat.approved, 'approved')" class="status_icon hovered" :class="{ 'released' : cat.approved == 1 , 'unreleased' : cat.approved == 0}"></a>
              <a @click="cat.write_perm == 1 ? cat.write_perm = 0 : cat.write_perm = 1; updateApprovement_Permission(cat.id, cat.write_perm, 'write_perm')" class="status_icon hovered" :class="{ 'secured' : cat.write_perm == 1 , 'unsecured' : cat.write_perm == 0}"></a>
              <a @click="updateProduct(cat)" class="status_icon edit hovered"></a>
              <a @click="setCategoryToDelete(cat)" class="status_icon delete hovered"></a>
            </li>                 
        </ul>                    
    </div>           
</div>
  
</div>

</template>

<script>
import Header from '@/components/Header.vue'
import ShowProducts from '@/components/ShowProducts.vue'
import DeleteItem from '@/components/DeleteItem.vue'
import AdminNavigation from '@/components/AdminNavigation.vue'

  export default {
    components: {
      Header, ShowProducts, DeleteItem, AdminNavigation
    },
    data() {
      return {
        showProductBox: false,
        filtered_items: [],
        products_to_show: [],
        category_to_delete: null,
        showDeleteItem: false,
        showSyncBox: false,
        showOrderBox: false,
        products: [],
        items: [],
        cat_order: [],
        dragging: [],
        id:'',
        filter: '',
        loading_con: 'loading_screen',
        rerender: 0,
        showAllTerms: null
        
      }
    },
    watch: {
      filter(val){
        
        let filteritems = this.items.filter(( obj ) => {
          if (obj.name.toLowerCase().includes(val.toLowerCase())){
            return obj
          }
          
        })
        if (filteritems.length){
          this.filtered_items = filteritems
        }
        if(val.length == 0){
          this.filtered_items = this.items
        }
        
        //this.paginate(this.filtered_items.length,1)
      }
    },
    computed: {
      isDragging() {
        return this.dragging > -1
      }
    },
    mounted() {      
      this.getCategories()
      this.rerender++
    },
    
    methods: {
      saveShowAllTerms(){
        let bool = this.showAllTerms == 1 ? 0 : 1
        this.$http.put('/api/categories/showAllTerms', {              
              value: bool
          })
      },
      setCategoryToDelete(cat){
        this.showDeleteItem = true
        this.category_to_delete = cat
      },
      setProductsToShow(products){
        this.showProductBox = true
        this.products_to_show = products
      },
      handleOk(ev){
        ev.preventDefault()
        
        
          let order = []
          this.items.forEach(item => {
            order.push(item.id)
          }) 
          let url = "/api/categories/updateOrder"
          this.$http.put(url, {              
              order: JSON.stringify(order)
          })
          .then(resp => {
            if(resp.status == 200){
               this.showOrderBox = false
            }
          })
          .catch(error => {
            alert('Reihenfolge konnte nicht geändert werden. Grund: ' + error)
            window.location.reload()
          })
        
        
      },
      dragStart(which, ev) {
        ev.dataTransfer.setData('Text', this.id);
        ev.dataTransfer.dropEffect = 'move'
        this.dragging = which;
      },
      
      dragEnd() {
        this.dragging = -1
      },
      dragFinish(to) {
        this.moveItem(this.dragging, to);
      },
      moveItem(from, to) {
        if (to === -1) {
          this.removeItemAt(from);
        } else {
          this.cat_order.splice(to, 0, this.cat_order.splice(from, 1)[0]);
        }
      },
      getCategories(){
      this.totalRows = this.items.length
      fetch('/api/categories/getCatProd')
        .then(resp => resp.json())
        .then(json => {
            if(json){
              json.forEach((item,index) => {
                if(item.client){
                  json[index].client = JSON.parse(item.client)
                }
                if(item.products){
                  json[index].products = item.products ?  item.products.split(',') : ''
                }
              })
              this.items = json  
              this.cat_order = json
              this.filtered_items = json
              this.loading_con = 'loading_screen_off'
            }
                    
        })

        fetch('/api/categories/showAllTerms')
        .then(resp => resp.json())
        .then(json => {
            this.showAllTerms = json.value
                    
        })
     },
     updateApprovement_Permission_all(status){
          let fetches = []
          this.loading_con = 'loading_screen'
          if(status){
            this.items.forEach(item =>{
              item.write_perm = 1
              fetches.push(this.updateApprovement_Permission(item.id, 1, 'write_perm'))
              
            })
          }
          else{
            this.items.forEach(item =>{
              item.write_perm = 0
              fetches.push(this.updateApprovement_Permission(item.id, 0, 'write_perm'))
              
            })
          }
          

          Promise.all(fetches).then(()=>{
            this.loading_con = 'loading_screen_off'
        })
        
      },
      syncData(){
        this.showSyncBox = false
        this.loading_con = 'loading_screen' 
        fetch('/ws/categories/syncData')
        .then((data) => {
          if(data.status == 200){            
            this.getCategories()
            this.loading_con = 'loading_screen_off' 
          }
        })
        .catch(error => {
          console.log(error)
        })
      },
      createNew(e){
        e.preventDefault()
        this.$router.push({ 
          name: 'kategorienansicht',
          params: {
            type: 'anlegen'
            
          }
        })
      },
      updateApprovement_Permission(id, e = null, type){
        
        let url = "/api/categories/updateApprovement/"+id                   
        this.$http.put(url, {            
            [type]: e,      
        })
        .then(response => {
            if (response.status == 200) {
              // nothing
            }
        })
        .catch(error => {
          alert("nicht erfolgreich")
          console.log(error)
          window.location.reload()
        })   
        
      },
      
      updateProduct(item){
       this.$router.push({
          name: 'kategorienansicht',
          params: {
            id: item.id,
            type: 'update',            
          },
            
        })
        
      },
      deleteItem(id){
        let url = "/api/categories/"+id
                   
        this.$http.delete(url)
        .then(response => {
            if (response.status == 200) {
                window.location.reload()
            }            
        })
        .catch(error => {
          alert("nicht erfolgreich")
          console.log(error)
          window.location.reload()
        })  
      },
      categoryFilter(){        
        let filteritems = this.filtered_items.filter(( obj ) => {
          if (obj.name.toLowerCase().includes(this.filter.toLowerCase())){
            return obj
          }
          
        })
        if (filteritems.length){
          this.filtered_items = filteritems
        }        
        this.paginate(this.filtered_items.length,1)
        
      }
    }
  }
</script>

<style  scoped >


#modal-1{
  position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 10px;
    z-index: 1000;
    width: 100%;
    height: 100vh;
    /* padding: 39%; */
    padding-left: 30%;
    padding-right: 30%;
    padding-top: 15%;
}
.hide {
  visibility: hidden;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(100px);
  opacity: 0;
}
.slide-fade-move{
  transition: all 3s
}
* {
  box-sizing: border-box;
}

.list {
  list-style-type: none;
  padding: 10px;
}

.done {
  text-decoration: line-through;
  color: #888;
}

.new-todo {
  width: 100%;
}

.trash-drop {
  border: 2px dashed #ccc !important;
  text-align: center;
  color: #e33;
}

.trash-drop:-moz-drag-over {
  border: 2px solid red;
}

.item {
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 14px 8px;
  margin-bottom: 3px;
  background-color: #fff;
  box-shadow: 1px 2px 2px #ccc;
  font-size: 22px;
}

.remove-item {
  float: right;
  color: #a45;
  opacity: 0.5;
}

.item:hover .remove-item {
  opacity: 1;
  font-size: 28px;
}
.order_con{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search_field{
  padding: 0 !important;
}
.product_item{
  display: inline;
}
.button_con{
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
}
.action_buttons {
  display: flex;
  justify-content: space-around;
}
.action_buttons >*{
  margin-right: 10px;
}
.createNew{
    margin-bottom: 20px;
}

.pagination_per_page_container{
    display: flex;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
 
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>