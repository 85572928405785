<template >
<nav>
    <ul class="backend_main_menu">
        <li class="hovered"><a @click="$router.push({name: 'produktverwaltung'})" :class="{ 'active' : type == 'Produkte'}">Produkte</a></li>
        <li class="hovered"><a @click="$router.push({name: 'kategorienverwaltung'})" :class="{ 'active' : type == 'Kategorien'}">Kategorien</a></li>
        <li class="hovered"><a @click="$router.push({name: 'sprachenverwaltung'})" :class="{ 'active' : type == 'Sprachen'}">Sprachen</a></li>
        <li class="hovered"><a @click="$router.push({name: 'loginverwaltung'})" :class="{ 'active' : type == 'Logins'}">Logins</a></li>
        <li class="hovered"><a @click="$router.push({name: 'wuiverwaltung'})" :class="{ 'active' : type == 'WuI'}">Workflow & Internes</a></li>  
    </ul>
</nav>
</template>

<script>
export default {
    props: ['processType'],
    data(){
        return {
          type: this.processType,
        } 
    }  
}

</script>
<style scoped>
.hovered{
    cursor: pointer;
}



</style>
