<template>
  <div>
    <Header endType="Backend" :key="rerender" />
    <AdminNavigation processType="Produkte" />

    <div class="main_area layout_container">
      <div class="backend">
        <h1>{{ productName }} bearbeiten</h1>
        
      </div>

      <div class="msm_box form">
        <fieldset>
          <legend>Name</legend>
          <input type="text" class="name_edit" v-model="form.name" />
        </fieldset>

        <fieldset>
          <legend>Ilvy!-Sprachen</legend>
          <ul class="checkboxes">
            <li v-for="lang in optionsLanguages" :key="lang.lang_id">
              <input
                type="checkbox"
                :id="lang.lang_id"
                v-model="form.languages"
                :value="lang.lang_id"
              />
              <label :for="lang.lang_id">{{ lang.slug.toUpperCase() }}</label>
            </li>
          </ul>
        </fieldset>
        <fieldset class="field_row">
          <div class="input_wrapper">
            <legend>SKB-Slug</legend>
            <select placeholder="Produkt wählen" class="product_select">
              <option>{{ form.ilvy }}</option>
            </select>
          </div>
          <div class="input_wrapper">
            <legend>PSI-Slug</legend>
            <select
              placeholder="Produkt wählen"
              class="product_select"
              v-model="form.psi"
            >
              <option
                v-for="product in optionsPSIProducts"
                :key="product.id"
                :value="product.slug"
              >
                {{ product.slug }}
              </option>
            </select>
          </div>
          <div class="input_wrapper">
            <legend>SupportNews-Tag</legend>
            <input
              type="text"
              class="name_edit"
              v-model="form.supportnews_tag"
            />
          </div>
        </fieldset>
        <fieldset class="field_row">
          <div>
            <legend>Freigabe</legend>
            <input type="radio" id="aa" v-model="form.approved" :value="1" />
            <label for="aa">Ja</label>
            <input type="radio" id="bb" v-model="form.approved" :value="0" />
            <label for="bb">Nein</label>
          </div>
          <div>
            <legend>Schreibschutz</legend>
            <input type="radio" id="cc" v-model="form.write_perm" :value="1" />
            <label for="cc">Ja</label>
            <input type="radio" id="dd" v-model="form.write_perm" :value="0" />
            <label for="dd">Nein</label>
          </div>
        </fieldset>
        <div class="form_action">
          <a @click="cancel" class="button close">Abbrechen</a>
          <a @click="onSubmit" class="button important">Speichern</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import AdminNavigation from "@/components/AdminNavigation.vue";
export default {
  components: {
    Header,
    AdminNavigation,
  },
  data() {
    return {
      show: false,
      id: this.$route.params.id,
      form: {
        ilvy: "",
        name: "",
        psi: "",
        product_group: "",
        supportnews_tag: "",
        approved: null, // this.approved == "true") ? 1 : 0
        write_perm: null,
        languages: [],
      },
      allIlvyProducts: [],
      allPSIProducts: [],
      optionsIlvyProducts: [],
      optionsPSIProducts: [],
      optionsLanguages: [],
      rerender: 0,
    };
  },
  computed:{
    productName(){
      return this.form.name;
    }
  },
  beforeCreate() {
    fetch("/api/languages/getAll/")
      .then((resp) => resp.json())
      .then((json) => {
        if (json) {
          this.optionsLanguages = json;
        } else {
          this.optionsLanguages = [];
        }
      });
    fetch("/api/msm/getSingle/" + this.$route.params.id)
      .then((resp) => resp.json())
      .then((json) => {
        if (json[0].psi_slug) {
          this.form.psi = json[0].psi_slug;
        }
        if (json[0].ilvy_slug) {
          this.form.ilvy = json[0].ilvy_slug;
        }
        if (json[0].name) {
          this.form.name = json[0].name;
        }

        this.form.approved = json[0].approved;

        this.form.write_perm = json[0].write_perm;

        if (json[0].supportnews_tag) {
          this.form.supportnews_tag = json[0].supportnews_tag
            ? json[0].supportnews_tag
            : "";
        }
        if (json[0].product_group) {
          this.form.product_group = json[0].product_group
            ? json[0].product_group
            : "";
        }
      });
    fetch("/api/msm/getLanguages/" + this.$route.params.id)
      .then((resp) => resp.json())
      .then((json) => {
        json.forEach((item) => {
          if (item.language_id) {
            this.form.languages.push(item.language_id);
          }
        });
      });
    fetch("/api/msm/getAllIlvy")
      .then((resp) => resp.json())
      .then((json) => {
        let items = [];
        if (json.data.products) {
          Object.values(json.data.products).forEach((product) => {
            Object.values(product).forEach((item) => {
              items.push(item);
            });
          });
        }

        items.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)); // sort by name
        this.optionsPSIProducts = items;
      });
    this.rerender++;
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let prod_url = "/api/msm/updateProduct";
      this.$http
        .put(prod_url, {
          id: this.id,
          name: this.form.name,
          psi_slug: this.form.psi,
          supportnews_tag: this.form.supportnews_tag
            ? this.form.supportnews_tag
            : null,
          approved: this.form.approved,
          write_perm: this.form.write_perm,
          product_group: this.form.product_group,
        })
        .then((response) => {
          if (response.status == 200) {
            this.$router.push({ name: "produktverwaltung" });
          }
        })
        .catch((error) => {
          console.log(error);
        });

      let lang_url = "/api/languages/updateLangProd/" + this.id;
      this.$http
        .put(lang_url, {
          languages: this.form.languages,
        })
        .then((response) => {
          if (response.status == 200) {
            this.$router.push({ name: "produktverwaltung" });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancel() {
      this.$router.push({ name: "produktverwaltung" });
    },
    handleChangeIlvyProduct(e, options) {
      for (var prop in options) {
        let obj = options[prop].options;
        for (const key in obj) {
          if (obj[key].slug == e) {
            this.form.product_group = obj[key].productGroup;
            break;
          }
        }
      }
    },
    handleChangePSIProduct(e, options) {
      for (var prop in options) {
        let obj = options[prop].options;
        for (const key in obj) {
          if (obj[key].slug == e) {
            this.form.product_group = obj[key].productGroup;
            break;
          }
        }
      }
    },
  },
};
</script>
<style  scoped>
.input_wrapper {
  width: 30%;
}
</style>