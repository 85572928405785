<template >
<div class="overlay">
    <div class="popup">
        <h2>{{type}} löschen</h2>
        <div class="popup_content">
            Soll das {{type}} <strong>{{item_name}}</strong> wirklich gelöscht werden?
        </div>
        <div class="popup_action">
            <a @click="closePopup" class="button close">Abbrechen</a>
            <a @click="deleteItem(item_id)" class="button icon important">Löschen</a>
        </div>
    </div>
</div>    
</template>

<script>

export default {
    props: ['item', 'process_type'],
    data(){
        return {
          item_name: this.item.name,
          item_id: this.item.id,
          type: this.process_type
        } 
    },
    computed:{
      
    },
    watch:{
      
    },
    mounted(){
      
    },
    methods: {
      deleteItem(id){
          this.$parent.deleteItem(id)
      },
      closePopup(){
          this.$parent.showDeleteItem = false
      }
    }   
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.closed{
  display: none;
}
</style>
