import { render, staticRenderFns } from "./Sprachenverwaltung.vue?vue&type=template&id=6e8b6be7&scoped=true&"
import script from "./Sprachenverwaltung.vue?vue&type=script&lang=js&"
export * from "./Sprachenverwaltung.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e8b6be7",
  null
  
)

export default component.exports