module.exports = {
    elasticSearch: (url, data) => {
        const controller = new AbortController();
        const signal = controller.signal;
        setTimeout(() => {
            controller.abort();
        }, 5000);
    
        fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
            signal: signal,
        });
    }
}



