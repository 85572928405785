<template >
  <div class="container-fluid">
    <nav>        
      <ul class="product_main_menu">
        <li v-for="productGroup in optionsProducts" :key="productGroup.label"> 
          
            <!--ACTIVE FEHLT NOCH-->
          
          <a class="product_select hovered" :class="{ 'active' : productGroup.label == product_group}" @click="product_select" >{{productGroup.label}}
            <i class="fas fa-caret-down hovered"></i> <!-- CHange to mouseover-->
          </a>
          <div   class="product_select_open closed">
            <ul >
                  <li  style="display:block" v-for="prod in productGroup.options" :key="prod.id">
                      <a @click="chooseProduct" class="product_select_single hovered" :class="{ 'active' : prod.ilvy_slug === product}" :data-product_slug="prod.ilvy_slug" :data-product_id="prod.id">{{prod.name}}</a>
                  </li>
                  
              </ul>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
//vuex
//OPTIONSPRODUCTS NOCH
export default {
    props: ['pub'],
    data(){
        return {

        } 
    },
    computed:{
      product(){
        return this.$store.state.input.product.slug
      },
      product_id(){
        return this.$store.state.input.product.id
      },
      product_group(){
        return this.$store.state.input.product.product_group
      },
      optionsProducts(){
        return this.pub === 'ilvy' ? 
        this.$store.state.publication_ilvy.options.products : (
          this.pub === 'sn' ? 
          this.$store.state.publication_sn.options.products : 
          this.$store.state.options.products
        )
      }
    },
    watch:{
      
    },
    mounted(){

    },
    methods: {
      product_close(){
        
        let all = document.querySelectorAll('.product_select_open')
        all.forEach(item => {          
            item.classList.add('closed');          
        })
      },
      product_select(e){

        if(!e.target.nextSibling.classList.contains('closed')) {
          this.product_close()
        }
        else if (e.target.nextSibling){
          e.target.nextSibling.classList.remove('closed')
          this.closeHandler(e.target.nextSibling)
        }

        
        
      },
      closeHandler(currentElement, close = false){
        if (close){
          currentElement.className = "";
        }
        else{
          let all = document.querySelectorAll('.product_select_open')
          all.forEach(item => {
            if (currentElement != item){
              item.classList.add('closed');
            }
          })
        }        
      },
      chooseProduct(a){
        this.$store.dispatch('input/product', {product: a.target.dataset.product_slug, pub: this.pub})

        this.$router.push({ params: { ...this.$route.params, product: a.target.dataset.product_slug }, query: {...this.$route.query}}).catch(err => {console.error(err)})
        this.closeHandler(a.target.parentElement.parentElement, false)
        
        
      },
    }   
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hovered{
  cursor: pointer;
}

.closed{
  display: none
}

</style>
