<template >
  <CoolLightBox :items="items" :index="index" @close="index = null" effect="swipe" :enableScrollLock="true"
    :slideshow="false" thumbsPosition="bottom">
  </CoolLightBox>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      items: [],
      index: null,
      linkList: [],
    };
  },
  computed: {},
  watch: {
    linkList() {
      for (var i = 0; i < this.linkList.length; i++) {
        this.linkList[i].onclick = this.linkAction;
      }
    },
  },
  methods: {
    linkAction(e) {
      let index = e.target["data-index"];
      this.index = index ?? null;
    },
    getSiblings(element) {
      let siblings = [];
      let sibling = element?.parentNode?.firstChild;
      while (sibling) {
        if (sibling?.nodeType === 1 && sibling !== element) {
          siblings.push(sibling);
        }
        sibling = sibling?.nextSibling;
      }
      return siblings;
    },
    getImageData(image, index) {
      let img = image.querySelector("img");
      img["data-index"] = index;
      let figcaption = this.getSiblings(image).filter(item => item.tagName === "FIGCAPTION")[0];
      this.linkList.push(img);
      let title = figcaption ? figcaption.innerText : '';
      let src = img.src;
      let description = img.alt;
      return {
        src,
        description,
        title,
      };

    },
    parseMedia() {
      this.items = [];
      let images = document.querySelectorAll(".lightbox");
      Array.from(images).forEach((image, index) => {
        let item = this.getImageData(image, index);
        this.items.push(item);
      });
    },
  },
  updated() { },
  mounted() { },
};
</script>
<style scoped></style>
