import Vue from 'vue'
import Axios from 'axios'
import App from './App.vue'
import IlvyCSS from './assets/css/ilvy.css' // NUR FÜR PUBLIKATIONEN
import DefaultCSS from './assets/css/default.css'
import DocsCSS from './assets/css/docs.css'
import FontAwesomeCSS from './assets/css/fontawesome.css'
import FontsCSS from './assets/css/fonts.css'
import PsiCSS from './assets/css/psi.css'
import VariablesCSS from './assets/css/variables.css'
import AddCSS from './assets/css/add.css'
import VueRouter from 'vue-router';

import VueMultiSelectCSS from "vue-multiselect/dist/vue-multiselect.min.css"


import CKEditor from '@ckeditor/ckeditor5-vue2';

import VueSanitize from "vue-sanitize";
Vue.use(VueSanitize);

//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
Vue.use(CoolLightBox)


import VueShowdown from 'vue-showdown'

// the second parameter of Vue.use() is optional
Vue.use(VueShowdown, {
    // set default flavor of showdown
    flavor: 'github',
    // set default options of showdown (will override the flavor options)
    options: {
        emoji: false,
    },
})


import vSelect from "vue-select";

Vue.component("v-select", vSelect);
import 'vue-select/dist/vue-select.css';

export const bus = new Vue()


Vue.use(CKEditor)
Vue.use(AddCSS)
Vue.use(DefaultCSS)
Vue.use(VariablesCSS)
Vue.use(FontsCSS)
Vue.use(FontAwesomeCSS)
Vue.use(DocsCSS)


Vue.use(IlvyCSS)
Vue.use(PsiCSS)
Vue.use(VueMultiSelectCSS)


// ignore NavigationDuplicated Error

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(() => { })
};

const replacePush = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
    return replacePush.call(this, location).catch(() => { })
};

Vue.use(VueRouter);





import Produktverwaltung from './pages/Produktverwaltung.vue';
import Suchmaske from './pages/Suchmaske.vue';
import Publikationsansicht from './pages/Publikationsansicht.vue';
//import NotFound from './pages/404.vue';

import Produktansicht from './pages/Produktansicht.vue';

import Sprachenverwaltung from './pages/Sprachenverwaltung.vue';
import Sprachenansicht from './pages/Sprachenansicht.vue';

import Kategorienverwaltung from './pages/Kategorienverwaltung.vue';
import Kategorienansicht from './pages/Kategorienansicht.vue';
import Supnews_Template from './pages/Supnews_Template.vue';

import Doc_Klassenansicht from './pages/Doc_Klassenansicht.vue';

import Zendesk_success from './pages/Zendesk_success.vue';

import Login from './pages/Login.vue';

import Loginverwaltung from './pages/Loginverwaltung.vue';

import UserConfig from './pages/UserConfig.vue';

import Wuiverwaltung from './pages/WuiVerwaltung.vue';
import Wuiansicht from './pages/Wuiansicht.vue';


import store from './store/store'

Vue.prototype.$http = Axios;

Vue.config.productionTip = false

const routes = [
    {
        name: 'user_config',
        path: '/user/options/config',
        component: UserConfig,

        meta: {
            requiresAuth: true
        }


    },
    {
        name: 'zendesk_success',
        path: '/zendesk/handover/success',
        component: Zendesk_success,

        meta: {
            requiresAuth: true
        }


    },
    {
        name: 'supnews_template',
        path: '/supnews/:type/:product/:itemID/:title',
        component: Supnews_Template,
        meta: {
            requiresAuth: true,
            title: true
        },
        beforeEnter: (to, from, next) => {
            let prefix = to.params.type === 'techex' ? 'TechEx:' : (to.params.type === 'fritzwiki' ? 'FRITZ!Wiki:' : 'Workflow & Internes') 
            store.dispatch('publication_sn/getContent', {
                type: to.params.type,
                product: to.params.product,
                id: to.params.itemID
            })
                .then(() => {
                    to.meta.title = store.state.input.ticket_id ? `#${store.state.input.ticket_id} - ${prefix} ${store.state.publication_sn.content.title}` : `${prefix} ${store.state.publication_sn.content.title}`
                    next()
                })
        }


    },
    {
        name: 'doc_klassenansicht',
        path: '/doc_class/:type/:id?',
        component: Doc_Klassenansicht,

        meta: {
            requiresAuth: true,
            onlyAdmin: true
        }

    },
    {
        name: 'kategorienansicht',
        path: '/kategorien/:type/:id?',
        component: Kategorienansicht,

        meta: {
            requiresAuth: true,
            onlyAdmin: true
        },

        beforeEnter: (to, from, next) => {
            if (to.params.type == 'update') {
                let id = to.params.id
                store.dispatch('admin/category/getSearchTerms', id)
                    .then(() => {
                        next()
                    })
            }
            else {
                store.commit('admin/category/resetTerms')
                next()
            }

        }
    },
    {
        name: 'wuiansicht',
        path: '/wuikategorien/:type/:id?',
        component: Wuiansicht,

        meta: {
            requiresAuth: true,
            onlyAdmin: true
        },

        beforeEnter: (to, from, next) => {
            if (to.params.type == 'update') {
                let id = to.params.id
                store.dispatch('admin/workflowinternes/setSingle', id)
                    .then(() => {
                        next()
                    })
            }
            else {
                store.dispatch('admin/workflowinternes/resetSingle')
                next()
            }

        }
    },
    {
        name: 'kategorienverwaltung',
        path: '/admin/options/kategorien',
        component: Kategorienverwaltung,

        meta: {
            requiresAuth: true,
            onlyAdmin: true
        },


    },
    {
        name: 'sprachenansicht',
        path: '/sprachen/ansicht/:type/:id?',
        component: Sprachenansicht,

        meta: {
            requiresAuth: true,
            onlyAdmin: true
        },
        beforeEnter: (to, from, next) => {
            let id = to.params.id
            store.dispatch('admin/language/getLanguageData', id)
                .then((slug) => {
                    store.dispatch('admin/language/getSearchTerms', slug).then(() => {
                        next()
                    })

                })
        }


    },
    {
        name: 'sprachenverwaltung',
        path: '/admin/options/sprachen',
        component: Sprachenverwaltung,

        meta: {
            requiresAuth: true,
            onlyAdmin: true
        }


    },
    {
        name: 'wuiverwaltung',
        path: '/admin/options/workflowinternes',
        component: Wuiverwaltung,

        meta: {
            requiresAuth: true,
            onlyAdmin: true
        },
        beforeEnter: (to, from, next) => {
            store.dispatch('admin/workflowinternes/getCategories')
                .then(() => {
                    next()
                })
        }

    },
    {
        name: 'produktansicht',
        path: '/produktansicht/update/:id',
        component: Produktansicht,

        meta: {
            requiresAuth: true,
            onlyAdmin: true
        }


    },
    {
        name: 'suchmaske',
        path: '/:lang?/:product?', // hier nur root --> /
        component: Suchmaske,


        meta: {
            requiresAuth: true,
            title: true

        },
        beforeEnter: (to, from, next) => {
            to.meta.title = store.state.input.ticket_id ? `#${store.state.input.ticket_id} - ${store.state.input.product.name}` : `ohne ZD# - ${store.state.input.product.name}`
            next()
        }


    },
    { path: '/', redirect: { name: 'suchmaske' } },

    {
        name: 'produktverwaltung',
        path: '/admin/options/produkte',
        component: Produktverwaltung,

        meta: {
            requiresAuth: true,
            onlyAdmin: true
        }


    },
    {
        name: 'publikationsansicht', // in keyboradselect wird der name bezogen !!!
        path: '/publication/:lang/:product/:id',
        component: Publikationsansicht,

        meta: {
            requiresAuth: true,
            title: true
        },
        beforeEnter: (to, from, next) => {
            store.dispatch('publication_ilvy/getContent', {
                lang: to.params.lang,
                product: to.params.product,
                id: to.params.id
            })

                .then(() => {
                    to.meta.title = store.state.input.ticket_id ? `#${store.state.input.ticket_id} - ${store.state.publication_ilvy.content.doc_id}: ${store.state.publication_ilvy.content.title}` : `${store.state.publication_ilvy.content.doc_id}: ${store.state.publication_ilvy.content.title}`
                    next()
                })
        },
        
    },
    {
        name: 'loginverwaltung',
        path: '/admin/options/loginverwaltung',
        component: Loginverwaltung,

        meta: {
            requiresAuth: true,
            onlyAdmin: true
        }



    },
    /*
        {
            name: '404',
            path: '/msm/notfound/404',
            component: NotFound
    
        },
    */
    {
        name: 'login',
        path: '/login',
        component: Login,
    },


];

const router = new VueRouter({ mode: 'history',scrollBehavior: function(to) {
    if (to.hash) {
        return {
            selector: to.hash,
            behavior: 'smooth'
        }
    }
}, routes: routes });

import { authenticationService } from '@/_services/user';

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        if (!store.state.user.loggedIn) {
            authenticationService.checkLogin().then(status => {
                checkForQueryData(to)
                Promise.all([
                    checkForOptionsDocClasses(),
                    checkForOptionsLanguages(),

                ])
                    .then(() => {
                        if (!status) {
                            let redirect = to.path == '/login' ? to.query.redirect : (to.query.redirect ? to.query.redirect : to.fullPath)
                            let product = to.path == '/login' ? to.query.product : to.params.product
                            let language = to.path == '/login' ? to.query.language : to.params.language
                            next({
                                name: 'login',
                                query: {
                                    redirect: redirect,
                                    product: product,
                                    language: language
                                },
                            })
                        }
                        else {
                            store.commit('user/login')
                            store.dispatch('user/getUserConfig').then(() => {
                                Promise.all([
                                    checkForLanguage(to),
                                    checkForProduct(to)
                                ]).then(() => {
                                    //store.dispatch('options/getAllSearchTerms')
                                    checkForSearchTerms()
                                    //store.dispatch('items/getWuIItems')
                                    checkForWUI()
                                    next()
                                    
                                })


                            })
                        }
                    })

            })


        }
        else {
            
            checkForQueryData(to)
            Promise.all([
                checkForLanguage(to),
                checkForProduct(to),
                checkForOptionsDocClasses(),
                checkForOptionsLanguages(),
                checkForWUI()
            ]).then(() => {
                next()
            })
        }


    }
    else if (to.meta.onlyAdmin && !authenticationService.checkCookie('isAdmin')) {
        next({
            path: '/'
        })
    }
    else {
        next()
    }

})

router.afterEach((to) => {
    if (to.meta.title) {
        Vue.nextTick(() => {
            document.title = to.meta.title
        });
    }

});


function checkForQueryData(to) {
    if (!to.query.redirect) {
        if (to.query.ticket_id && !store.state.input.ticket_id) {
            store.commit('input/ticket_id', to.query.ticket_id)
        }
        if (to.query.channel && !store.state.input.channel) {
            store.commit('input/channel', to.query.channel)
        }
        if (to.query.q && !store.state.input.query.length) {
            let query = to.query.q?.length ? to.query.q.split(' ') : []
            store.commit('input/query',query )
        }
        if (to.query.doc_class && !store.state.input.doc_class) {
            store.commit('input/doc_class', to.query.doc_class)
        }
        if (to.query.categories && !store.state.input.categories) {
            store.commit('input/categories', to.query.categories.split(',').map(Number))
        }
    }

}

function checkForProduct(to) {
    if (to.params.product && store.state.input.product.slug?.toLowerCase() != to.params.product?.toLowerCase()) {
        return Promise.all([
            store.dispatch('input/initDefaultProduct'),
            store.dispatch('input/product', { product: to.params.product, pub: null })
        ])
    }
    else if (!store.state.input.product.slug) {
        return store.dispatch('input/initDefaultProduct')
            .then(default_product => {
                return store.dispatch('input/product', { product: default_product, pub: null })
            })
    }
    else {
        return Promise.resolve()
    }
}

function checkForLanguage(to) {
    if (to.params.lang && !store.state.input.language) {
        return store.dispatch('input/language', to.params.lang)
    }
    else if (!store.state.input.language) {
        let lang = store.state.user.userConfig.language ? store.state.user.userConfig.language : store.state.input.default_language
        return store.dispatch('input/language', lang)
    }
    else {
        return Promise.resolve()
    }
}

function checkForOptionsLanguages() {
    if (!store.state.options.languages.length) {
        return store.dispatch('options/languages')
    }
    else {
        return Promise.resolve()
    }
}

function checkForOptionsDocClasses() {
    if (!store.state.options.docClasses.length) {
        return store.dispatch('options/docClasses')
    }
    else {
        return Promise.resolve()
    }
}

function checkForWUI(){
    if(!store.state.items.items_wui.length){
        return store.dispatch('items/getWuIItems')
    }
    else{
        return Promise.resolve()
    }
}

function checkForSearchTerms(){
    if(!store.state.options.searchterms.length){
        return store.dispatch('options/getAllSearchTerms')
    }
    else{
        return Promise.resolve()
    }
}


new Vue(Vue.util.extend({ router, store }, App)).$mount('#app');
export default router
