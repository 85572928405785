import axios from 'axios';
const language = {
    namespaced: true,
    state: () => ({
        name: '',
        slug: '',
        url: '',
        searchterms: []
    }),
    mutations: {
        name(state, name){
            state.name = name
        },
        slug(state, slug){
            state.slug = slug
        },
        url(state, url){
            state.url = url
        },
        searchterms(state, searchterms){
            state.searchterms = searchterms
        },
        addSearchterm(state, searchterm){
            state.searchterms.push(searchterm)
        },
        deleteSearchterm(state, searchterm){
            state.searchterms = state.searchterms.filter(item => item !== searchterm)
        }
    },
    actions: {
        getSearchTerms(context, langSlug){
            
            return axios.get(`/api/language_searchterms/get/${langSlug}`).then(data => {
                return context.commit('searchterms', data.data.terms.sort() )
            })
        },
        updateSearchTerms({state}, langSlug){ // vorerst bis store komplett implementiert ist
            const data = {
                terms: state.searchterms
            }
            return axios.post(`/api/language_searchterms/create/${langSlug}`, data).then(response => {
                return response
            })
        },
        getLanguageData(context, id){
            return axios.get(`/api/languages/getSingle/${id}`).then(resp => {
                console.log(resp.data[0])
                let data = resp.data[0]
                context.commit('name', data.name)
                context.commit('slug', data.slug)
                context.commit('url', data.url)
                return data.slug
            })
        }
    }
}

export default language