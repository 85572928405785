<template>
  <div>
    <div>
      <div class="form_container">
        <div class="comment_form_wrapper">
          <div class="msm_box" v-if="success">
            {{ type_string }} erfolgreich eingetragen<br /><br />
            <a v-if="success" :href="topic_url" target="_blank"
              >Zum {{ type_string }}</a
            >
          </div>

          <div class="msm_box" v-if="fail">
            {{ type_string }} konnte nicht eingetragen werden. Grund:
            {{ fail_status }}
          </div>
          <div class="msm_box" v-if="noContent">
            Kommentarfeld darf nicht leer sein!
          </div>
          <form @submit="onSubmit">
            
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="content"
            ></ckeditor>
            <div class="comment_options">
              <div class="follow_option">
                <input type="checkbox" id="checkbox" v-model="follow" />
                <label for="checkbox" class="light_label"
                  >Über Antworten benachrichtigen (Inhalt folgen)</label
                >
              </div>
              <button type="submit" class="submit_button button icon topic">
                Thema/Kommentar erstellen
              </button>
            </div>
            <!--TKO End-->
          </form>
        </div>
      </div>
      
    </div>
    <div v-if="loading" class="loading_wrapper">
      <LoadingAnimation />
    </div>
  </div>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import UploadAdapter from "../_helpers/uploadAdapter";
import LoadingAnimation from "./LoadingAnimation.vue";

export default {
  components: {
    ckeditor: CKEditor.component,
    LoadingAnimation,
  },
  props: {
    type: {
      type: String,
    },
    ilvy_dokId: {
      type: Number,
      default: 0,
    },
    ilvy_dokTitle: {
      type: String,
    },
    publication_slug: {
      type: String,
    },
    supnews_dokId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      form_type: this.type,
      content: "",
      success: false,
      fail: false,
      fail_status: "",
      type_string: this.type == "ilvy" ? "Topic" : "Kommentar",
      follow: true,
      noContent: false,
      topic_url: "",
      editor: ClassicEditor,
      editorConfig: {
        placeholder: "Thema/Kommentar in den SupportNews erstellen",
        toolbar: [
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "|",
          "imageUpload",
          "|",
          "undo",
          "redo",
        ],

        extraPlugins: [function(editor) {editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {return new UploadAdapter(loader)}}],
        
      },
    };
  },
  watch: {
    content(val) {
      console.log('content',val);
    },
  },
  computed: {
    ticket_link() {
      return this.$store.state.input.ticket_id ? `${process.env.VUE_APP_ZENDESK_URL}/agent/tickets/${this.$store.state.input.ticket_id}` : null;
    },
  },
  methods: {    
    onEditorReady(editor) {
      console.log('editor',editor);
      //this.editor = editor;

      // Benutzerdefinierte Datenkonvertierung
      editor.conversion.for('dataDowncast').add(dispatcher => {
        dispatcher.on('insert:image', (evt, data, conversionApi) => {
          const imgElement = conversionApi.writer.createElement('img', {
            src: data.item.getAttribute('src'),
            alt: data.item.getAttribute('alt'),
          });
          conversionApi.writer.insert(imgElement, data.range);
        });
      });
    },
    onSubmit(e) {
      e.preventDefault();
      switch (this.form_type) {
        case "fritzwiki":
        case "techex":
        case "workflowundinternes":
          if (this.content.length) {
            this.noContent = false;
            this.supNewsComment(this.follow);
          } else {
            this.noContent = true;
          }
          break;
          

        case "ilvy":
          if (this.content.length) {
            this.noContent = false;
            this.postIlvyTopic(this.follow);
          } else {
            this.noContent = true;
          }
          break;
          
      }
    },
    postIlvyTopic(follow) {
      const controller = new AbortController();
      const signal = controller.signal;
      setTimeout(() => {
        controller.abort();
      }, 8000);
      this.loading = true
      let url = "/api/forum/postIlvy";
      let content = this.content; //.replace(/</g, "").replace(/>/g, "");
      let current_url = window.location.href
        .replace(/</g, "")
        .replace(/>/g, "")
        .split("?")[0];
      let zendesk_ticket_link = this.ticket_link
        ? "<a href=" +
          this.ticket_link +
          ' rel="external nofollow noopener" target="_blank" style="background: url("//www.google.com/s2/favicons?domain="' +
          this.ticket_link +
          '") left center no-repeat; padding-left: 20px;">Zendesk-Ticket aufrufen</a>'
        : '';
      let data = {
        type: this.form_type,
        title: "#" + this.ilvy_dokId + " - " + this.ilvy_dokTitle,
        content: content,
        link:
          "<a href=" +
          current_url +
          ' rel="external nofollow noopener" target="_blank" style="background: url("//www.google.com/s2/favicons?domain="' +
          current_url +
          '") left center no-repeat; padding-left: 20px;">Publikation aufrufen</a>',
        follow: follow,
        ticket_link: zendesk_ticket_link,
      };
      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Content-Length": data.length,
        },
        body: JSON.stringify(data),
        signal: signal,
      };
      fetch(url, options)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.topic_url = data.url;

          this.success = true;
          this.fail = false;
          this.content = "";
          this.loading = false
        })
        .catch((error) => {
          this.fail = true;
          this.success = false;
          this.fail_status = error;
          this.loading = false
        });
    },
    supNewsComment(follow) {
      const controller = new AbortController();
      const signal = controller.signal;
      setTimeout(() => {
        controller.abort();
      }, 5000);
      this.loading = true
      let url = "/api/forum/postSupNews";
      let content = this.content; //.replace(/</g, "").replace(/>/g, "")
      let zendesk_ticket_link = this.ticket_link
        ? "<a href=" +
          this.ticket_link +
          ' rel="external nofollow noopener" target="_blank" style="background: url("//www.google.com/s2/favicons?domain="' +
          this.ticket_link +
          '") left center no-repeat; padding-left: 20px;">Zendesk-Ticket aufrufen</a>'
        : '';
      let data = {
        id: this.supnews_dokId,
        content: content,
        type: this.form_type,
        follow: follow,
        ticket_link: zendesk_ticket_link
      };
      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Content-Length": data.length,
        },
        body: JSON.stringify(data),
        signal: signal,
      };
      fetch(url, options)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.topic_url = data.url;

          this.success = true;
          this.fail = false;
          this.content = "";
          this.loading = false
        })
        .catch((error) => {
          console.error(error);
          this.fail = true;
          this.success = false;
          this.fail_status = error;
          this.loading = false
        });
    },
  },
  mounted() {

  },
};
</script>
<style  scoped>
form * {
  margin: 5px 0;
}
.comment_textarea {
  font: 400 15px Roboto, "Segoe UI", Helvetica, Arial, sans-serif;
  width: 96.5%;
}



</style>