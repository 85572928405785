<template>
  <div>
    <div class="product">
      <h1 @click="openProductSelect">
        <span class="product_switch">{{ product_name }}</span>
      </h1>
      <ul class="tags">
        <a v-if="update_posting" :href="update_posting" target="_blank">
          <li class="tag version" :class="[product_group_slug]">
            {{ product_version }}
          </li>
        </a>
        <a v-else-if="product_version">
          <li class="tag version" :class="[product_group_slug]">
            {{ product_version }}
          </li>
        </a>
        <a v-if="ios_version" :href="ios_update_posting" target="_blank">
          <li class="tag ios" :class="[product_group_slug]">
            <i class="fab fa-apple"></i>&nbsp;&nbsp;{{ ios_version }}
          </li>
        </a>
        <a v-if="labor_link" :href="labor_link" target="_blank">
          <li v-if="is_labor" class="tag lab" :class="[product_group_slug]">
            Labor
          </li>
        </a>
        <a v-if="eos">
          <li
            v-if="eos"
            class="tag eos important"
            :class="[product_group_slug]"
          >
            EOS
          </li>
        </a>
        <a v-else-if="eom_link" :href="eom_link" target="_blank">
          <li
            v-if="eom"
            class="tag eom important"
            :class="[product_group_slug]"
          >
            EOM
          </li>
        </a>
        <a v-else>
          <li
            v-if="eom"
            class="tag eom important"
            :class="[product_group_slug]"
          >
            EOM
          </li>
        </a>
      </ul>
    </div>
    <a
      v-if="gui_link"
      :href="gui_link"
      target="_blank"
      class="button light icon GUI"
      >Benutzeroberfläche</a
    >
    <KeyboardProductSelect
      @closeProductSelect="closeProductSelect"
      :openByClick="openProductSelectByClick"
      :key="renderProductSelect"
    />
  </div>
</template>

<script>
import KeyboardProductSelect from "@/components/KeyboardProductSelect";
export default {
  data() {
    return {
      openProductSelectByClick: false,
      renderProductSelect: 0,
    };
  },
  components: {
    KeyboardProductSelect,
  },
  computed: {
    product_name: {
      get() {
        return this.$store.state.input.product.name;
      },
      set() {},
    },
    product_group_slug() {
      return this.$store.state.items.psi_data.productGroupSlug;
    },
    product_version() {
      return this.$store.getters["items/product_version"];
    },
    is_labor() {
      return this.$store.getters["items/is_labor"];
    },
    gui_link() {
      return this.$store.getters["items/gui_link"];
    },
    labor_link() {
      return this.$store.getters["items/labor_link"];
    },
    update_posting() {
      return this.$store.getters["items/update_posting"];
    },
    ios_version() {
      return this.$store.getters["items/ios_version"];
    },
    ios_update_posting() {
      return this.$store.getters["items/ios_update_posting"];
    },
    eom() {
      return this.$store.getters["items/eom"];
    },
    eos() {
      return this.$store.getters["items/eos"];
    },
    eom_link() {
      return this.$store.getters["items/eom_link"];
    },
  },
  mounted() {},
  methods: {
    closeProductSelect() {
      this.openProductSelectByClick = false;
    },
    openProductSelect() {
      this.openProductSelectByClick = true;
      this.renderProductSelect++;
    },
    /*
    openProductSelect() {
      this.$emit("openProductSelect");
    },
    */
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
