<template>
  <div>
    <Header endType="Frontend" />
    <ProductNavigation pub="sn" />
    <div class="main_area layout_container">
      <ProductInfo />

      <div class="msm_box doc_meta">
        <div>
          <a
            v-if="hasZenticket && rmaReason"
            @click="rmaTreffer()"
            class="button important icon tb_hit"
            >RMA-Treffer</a
          >
          <a @click="keinTreffer()" class="button icon no_hit">Zurück</a>
          <a :href="url" target="_blank" class="button icon external"
            >SupportNews-Variante</a
          >
        </div>
        <span v-if="$route.params.type == 'techex'" class="doc_number"
          >TechEx-Dok#{{ itemID }}</span
        >
        <span v-else-if="$route.params.type == 'fritzwiki'" class="doc_number">FRITZ!Wiki#{{ itemID }}</span>
        <span v-else class="doc_number">W&I#{{ itemID }}</span>
        <hr />
        <span class="creation_date">Aktualisiert am {{ date }}</span>
      </div>
      <div :id="docTypeID" class="msm_box">
        <h1>{{ title }}</h1>
        <div class="description" v-html="content"></div>
      </div>
      <div class="msm_box doc_comment">
        <CommentTopicForm :type="type" :supnews_dokId="itemID" />
      </div>
    </div>
    <Lightbox />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import CommentTopicForm from "@/components/Comment_Topic_Form.vue";
import ProductNavigation from "@/components/ProductNavigation.vue";
import ProductInfo from "@/components/ProductInfo.vue";
import Mark from "mark.js/src/lib/mark.js";
import Lightbox from "@/components/Lightbox.vue";

export default {
  components: {
    Header,
    CommentTopicForm,
    ProductNavigation,
    ProductInfo,
    Lightbox
  },
  data() {
    return {
      linkList: [],
      originDB: "TECHEX",
    };
  },
  computed: {
    product() {
      return this.$store.state.input.product.slug;
    },
    type() {
      return this.$route.params.type;
    },
    docTypeID() {
      return this.type + "_doc";
    },
    contentFields() {
      return {
        techex: "field_107",
        fritzwiki: "field_178",
        workflowundinternes: "field_101",
      };
    },
    fieldselector() {
      return this.contentFields[this.type];
    },
    hasZenticket() {
      return this.$store.state.input.ticket_id;
    },
    query() {
      let string = this.$store.state.input.query.length
        ? this.$store.state.input.query.join("|")
        : "";
      return new RegExp(string, "gi");
    },
    url() {
      return this.$store.state.publication_sn.content.url;
    },
    content() {
      let htmlString =
        this.$store.state.publication_sn.content.fields[this.fieldselector];
        
      let doc = new DOMParser().parseFromString(htmlString, "text/html");
      if (this.type == "fritzwiki" || this.type == "workflowundinternes") {
        this.parseVideoLinks(doc.body);
      }

      return doc.body.innerHTML;
    },
    date() {
      return this.$store.getters["publication_sn/getDate"];
    },
    author() {
      return this.$store.state.publication_sn.content.author.name;
    },
    title() {
      return this.$store.state.publication_sn.content.title;
    },
    itemID() {
      return this.$store.state.publication_sn.content.id;
    },
    rmaReason() {
      return this.$store.state.publication_sn.content?.fields?.field_298;
    },
    ilvy_treffer() {
      return this.$store.state.zendesk.treffer;
    },
    rma_treffer: {
      get() {
        return this.$store.state.zendesk.rma_treffer;
      },
      set(newVal) {
        this.$store.commit("zendesk/rma_treffer", newVal);
      },
    },
    externalSlug() {
      return this.$store.state.input.product.slug;
    },
  },
  watch: {
    ilvy_treffer(newVal) {
      this.$store.commit("zendesk/treffer", newVal);
    },
    content() {
      setTimeout(() => {
        var context = document.querySelector(".msm_box");
        var instance = new Mark(context[0]);
        instance.markRegExp(this.query);
      }, 100);
    },
  },
  created() {},
  mounted() {
    window.scrollTo(0, 0);
    if (this.type == "fritzwiki" || this.type == "workflowundinternes") {
      let string = `#${this.type}_doc`
      this.parseWUILinks(string)
      this.parseFritzWikiLinks(string);
      this.markQuery();
      this.bindLinks();
    }
    
  },
  updated() {
    if (this.type == "fritzwiki" || this.type == "workflowundinternes") {
      let string = `#${this.type}_doc`
      
        this.parseFritzWikiLinks(string);
        this.parseWUILinks(string)
        this.bindLinks();
      
      
    }
    
    
  },
  beforeMount() {},
  beforeRouteUpdate(to, from, next) {
    let prefix = to.params.type === "techex" ? "TechEx:" : "FRITZ!Wiki:";
    this.$store
      .dispatch("publication_sn/getContent", {
        type: to.params.type,
        product: to.params.product,
        id: to.params.itemID,
      })
      .then(() => {
        to.meta.title = this.$store.state.input.ticket_id
          ? `#${this.$store.state.input.ticket_id} - ${prefix} ${this.$store.state.publication_sn.content.title}`
          : `${prefix} ${this.$store.state.publication_sn.content.title}`;
        next();
      });
  },
  methods: {
    parseVideoLinks(doc) {
      let iframes = doc.getElementsByTagName("iframe")
      Array.from(iframes).forEach((item) => {
        if (item.dataset.embedSrc) {
          item.src = item.dataset.embedSrc;
        }
      });
    },
    parseFritzWikiLinks(string) {
      let allLinks = document
        .querySelector(string)
        .getElementsByTagName("a");
      Array.from(allLinks).forEach((link) => {
        if (link.href.includes("/fritzwiki/")) {
          let urlObject = new URL(link);
          let path = urlObject.pathname.split("/");
          let lastPart = path[path.length - 2].split("-");
          let id = lastPart[lastPart.length - 1].replace("r", "");
          let slug = path[path.length - 2].replace("-r" + id, "");
          let hash = urlObject.hash;
          link.href = `/supnews/fritzwiki/${this.product}/${id}/${slug}${hash}`;
        }
      });
    },
    parseWUILinks(string) {
      let allLinks = document
        .querySelector(string)
        .getElementsByTagName("a");
      Array.from(allLinks).forEach((link) => {
        if (link.href.includes("/workflowinternes/")) {
          let urlObject = new URL(link);
          let path = urlObject.pathname.split("/");
          let lastPart = path[path.length - 2].split("-");
          let id = lastPart[lastPart.length - 1].replace("r", "");
          let slug = path[path.length - 2].replace("-r" + id, "");
          let hash = urlObject.hash;
          link.href = `/supnews/workflowundinternes/${this.product}/${id}/${slug}${hash}`;
        }
      });
    },
    closeProductSelect() {
      this.openProductSelectByClick = false;
    },
    openProductSelect() {
      this.openProductSelectByClick = true;
      this.renderProductSelect++;
    },
    rmaTreffer() {
      this.rma_treffer = {
        type: this.type,
        doc_type: this.type,
        doc_id: this.itemID,
        title: this.title,
        product: this.externalSlug,
        rma: true,
        rma_reason: this.rmaReason,
        originDB: this.originDB,
      };

      this.checkLastRoute();
    },
    markQuery() {
      let selector = `#${this.type}_doc`;
      let context = document.querySelector(selector);
      let instance = new Mark(context);
      instance.markRegExp(this.query);
    },
    keinTreffer() {
      this.checkLastRoute();
    },
    checkLastRoute() {
      let query = [];
      query.push({ cat: this.$store.state.input.categories.join(",") });
      query.push({ q: this.$store.state.input.query.join(" ") });
      let obj = Object.assign({}, ...query);
      let product = this.$store.state.input.product.slug;
      let lang = this.$store.state.input.language;
      this.$router.push({
        name: "suchmaske",
        params: { lang: lang, product: product },
        query: obj,
      });
    },
    bindLinks() {
      let selector = `${this.type}_doc`;
      let msm_box = document.getElementById(selector);
      this.linkList = msm_box.getElementsByTagName("a");
      for (var i = 0; i < this.linkList.length; i++) {
        this.linkList[i].onclick = this.linkAction;
      }
    },
    linkAction(event) {
      event.preventDefault();
      let target = event.target.href ? event.target : event.target.parentNode;
      if (target.href.includes(window.location.host)) {
        let urlObject = new URL(target.href);
        let link = urlObject.hash.length ? urlObject.pathname + urlObject.hash : urlObject.pathname;
        this.$router.push(link);
      } 
      else if(target.classList.contains("ipsAttachLink_image")){
        window.open(target.href);
      }
      else {
        window.open(target.href, '_blank');
      }
      
    },
  },
};
</script>


<style scoped>
.msm_box {
  position: relative;
}
</style>


