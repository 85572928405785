import Vue from 'vue'
import Vuex from 'vuex'
import user_store from './modules/user_store'
import input_store from './modules/input_store'
import options_store from './modules/options_store'
import zendesk_store from './modules/zendesk_store'
import items_store from './modules/items_store'
import publication_store_ilvy from './modules/publication_store_ilvy'
import publication_store_sn from './modules/publication_store_sn'

import admin_language from './modules/admin/language'
import admin_category from './modules/admin/category'
import admin_product from './modules/admin/product'
import admin_workflowinternes from './modules/admin/workflowinternes'

import VuexPersistence from 'vuex-persist'


const vuexLocal = new VuexPersistence({
  reducer: (state) => (
    {
      user: state.user,
      input: state.input,
      admin: state.admin,
      options: state.options,
      publication_ilvy: state.publication_ilvy,
      publication_sn: state.publication_sn,
      zendesk: state.zendesk,
      items: state.items
    }
  ),
  storage: window.sessionStorage
})



Vue.use(Vuex); 

const store = new Vuex.Store({
  modules: {
    user: user_store,
    input: input_store,
    options: options_store,
    zendesk: zendesk_store,
    items: items_store,
    admin: {
      namespaced: true,
      modules: {
        category: admin_category,
        product: admin_product,
        language: admin_language,
        workflowinternes: admin_workflowinternes
      }
    },
    publication_ilvy: publication_store_ilvy,
    publication_sn: publication_store_sn
  },
  plugins: [vuexLocal.plugin],
});

export default store