<template >
    <div id="popup_prod_select" class="overlay" v-if="showModal" @click="closePopUp">
        <div class="popup">
            <h3>{{message}}</h3>
          <a
            v-if="buttons"
            @click="cancelButton()"
            class="button important ok_button"
            >{{buttonText}}</a
          >
          
        </div>
    </div>   
</template>

<script>

export default {
    props: ['message', 'showModal', 'buttons', 'buttonText', 'onlyCloseButton'],
    data(){
        return {
            
        } 
    },
    computed:{

    },
    watch: {

    },
    methods: {
        closePopUp(){
            if(!this.onlyCloseButton){
                this.$emit('closeModal')
            }          
        },
        confirmButton(){
            this.$emit('confirm')
        },
        cancelButton(){
            this.$emit('cancel')
        }
    },
    created(){

    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3{
    text-align: center;
}
.ok_button{
    width: 100%;
    box-sizing: border-box;
}
</style>
