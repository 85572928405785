<template>
<div class="loading-spinner_wrapper">
  <svg class="loading-spinner">
    <circle
      :cx="circlePositions[index] && circlePositions[index].x"
      :cy="circlePositions[index] && circlePositions[index].y"
      :r="item.radius"
      :fill="item.color"
      v-for="(item, index) in circles"
      :key="index"/>
  </svg>
</div>
  
</template>

<script>
const CENTER_X = 50;
const CENTER_Y = 50;
const RADIUS = 20;

function positionOnCircle(radius, angle, center) {
  return {
    x: center.x + (radius * Math.cos(toRadians(angle))),
    y: center.y + (radius * Math.sin(toRadians(angle)))
  }
}

function toRadians(angle) {
  return angle * Math.PI / 180;
}

function calculatePositions(component) {
  let angleIncrement = 360 / component.circles.length;
  let positions = {};
  component.circles.forEach((circle, index) => {
    positions[index] = positionOnCircle(
      RADIUS,
      angleIncrement * index,
      {x: CENTER_X, y: CENTER_Y}
    )
  })
  return positions;
}

export default {
  data() {
    return {
      circles: [],
      counter: 0,
      interval: null
    }
  },
  computed: {
    darkmode(){
      return this.$store.state.user.userConfig.darkmode 
    },
    circlePositions: calculatePositions,
  },
  mounted() {
    this.circles = [
        {color: !this.darkmode ? '#D97A89' : '#B1DBF4', radius: 0},
        {color: !this.darkmode ? '#D97A89' : '#B1DBF4', radius: 0},
        {color: !this.darkmode ? '#D9576C' : '#98D2F4', radius: 0},
        {color: !this.darkmode ? '#D9576C' : '#98D2F4', radius: 0},
        {color: !this.darkmode ? '#B3243B' : '#74BEEA', radius: 0},
        {color: !this.darkmode ? '#B3243B' : '#74BEEA', radius: 0},
        {color: !this.darkmode ? '#863643' : '#6D97B0', radius: 0},
        {color: !this.darkmode ? '#740C1C' : '#266E98', radius: 0},
        {color: !this.darkmode ? '#740C1C' : '#266E98', radius: 0},
    ]
    this.animate()
  },

  destroyed() {
    clearInterval(this.interval);
  },
  methods:{
    animate(){
      this.interval = setInterval(() => {
      this.counter++;
      this.circles = this.circles.map((item, index) => ({
        ...item,
        radius: (this.counter + index) % 8
      }));
    }, 70);
    }
  }
}
</script>

<style scoped>
.loading-spinner {
  width: 100px;
  height: 100px;
}

.loading-spinner_wrapper{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  background-color: var(--body-background);
  color: var(--content-text);
  z-index: 999;
  transition: all .3s linear 0s;
}

</style>
