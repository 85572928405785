const product = {
    namespaced: true,
    state: () => ({

    }),
    mutations: {

    },
    actions: {
        
    }
}

export default product