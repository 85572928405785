<template >
  <div>
    <Header endType="Frontend" />
    <div class="container-fluid">
      <ProductNavigation />
      <div class="main_area layout_container">
        <ProductInfo />
        <ZendeskBox @startLoading="startLoading" @stopLoading="stopLoading" />
        <div class="tabs">
          <ul class="tablist">
            <li>
              <a
                @click="changeInfoTab('support')"
                class="hovered"
                :class="{ active_tab: info_tab == 'support' }"
                >Support-Dokumente</a
              >
            </li>
            <li>
              <a
                @click="changeInfoTab('psi')"
                class="hovered"
                :class="{ active_tab: info_tab == 'psi' }"
                >Produktinformationen (PSI)</a
              >
            </li>
            <li v-if="ticket_id && product_has_warranty">
              <a
                @click="changeInfoTab('rma')"
                class="hovered"
                :class="{ active_tab: info_tab == 'rma' }"
                >RMA</a
              >
            </li>
            <li>
              <a
                @click="changeInfoTab('wui')"
                class="hovered"
                :class="{ active_tab: info_tab == 'wui' }"
                >Workflow & Internes</a
              >
            </li>
          </ul>
        </div>
        <div v-if="info_tab == 'support'" class="msm_box">
          <div class="filter">
            <div class="text_filter">
              
              <MultiSelect placeholder="Suchbegriff(e)" :searchterms="query" :options="optionsTerms" group_values="values" group_label="group" @valueChanged="valueChanged" :is_homepage="true" />
              <LanguageNavigation />
            </div>
            <CategoryNavigation />
          </div>
          <div class="tabs small">
            <ul class="tablist">
              <li>
                <a
                  @click="changeDocTab('ilvy')"
                  class="hovered"
                  :class="{ active_tab: doc_tab == 'ilvy' }"
                  >Wissensdatenbank
                  <span
                    class="doc_counter"
                    :class="{ hitless: ilvy_count == 0 }"
                    >{{ ilvy_count }}</span
                  ></a
                >
              </li>
              <li>
                <a
                  @click="changeDocTab('techex')"
                  class="hovered"
                  :class="{ active_tab: doc_tab == 'techex' }"
                  >TechEx-Doks
                  <span
                    class="doc_counter"
                    :class="{ hitless: techex_count == 0 }"
                    >{{ techex_count }}</span
                  ></a
                >
              </li>
              <li>
                <a
                  @click="doc_tab = 'fritzwiki'"
                  class="hovered"
                  :class="{ active_tab: doc_tab == 'fritzwiki' }"
                  >FRITZ!Wiki
                  <span 
                    class="doc_counter"
                    :class="{ hitless: fritzwiki_count == 0 }"
                     >{{ fritzwiki_count }}</span></a
                >
              </li>
            </ul>
          </div>
          <div class="msm_box">
            <Table
              :key="rerender"
              v-if="doc_tab === 'ilvy'"
              :itemsAll="items_ilvy"
              :page="currentPage_ilvy"
              tabletype="ilvy"
            />
            <Table
              :key="rerender"
              v-if="doc_tab === 'techex'"
              :itemsAll="items_techex"
              :page="currentPage_techex"
              tabletype="techex"
            />
            <Table
              :key="rerender"
              v-if="doc_tab === 'fritzwiki'"
              :itemsAll="items_fritzwiki"
              :page="currentPage_fritzwiki"
              tabletype="fritzwiki"
            />
          </div>
        </div>

        <div v-if="info_tab == 'psi'" class="msm_box">
          <PSIInformation :psiData="psi_data" :key="rerender" />
        </div>

        <div v-if="info_tab == 'rma'" class="msm_box">
          <RMATab @showModal="showModal = true" @startLoading="startLoading" @stopLoading="stopLoading" />
        </div>

        <div v-if="info_tab == 'wui'" class="msm_box">
          <WorkflowInternes />
        </div>
      </div>
      <MessageModal
        :message="rma_message"
        :showModal="showModal"
        @closeModal="showModal = false"
        buttonText="OK"
      />
      <MessageModal
        :message="no_product_message"
        :showModal="showModalIfNoProduct"
        @closeModal="showModalIfNoProduct = false"
        buttonText="Tab schließen"
        :onlyCloseButton="true"
        :buttons="true"
        @cancel="closeTab"
      />
      <LoadingAnimation :key="rerender" v-if="loading" />
     
    </div>
  </div>
</template>

<script>
import LoadingAnimation from "@/components/LoadingAnimation.vue";
import Header from "@/components/Header.vue";
import Table from "@/components/Table.vue";
import ProductInfo from "@/components/ProductInfo.vue";
import ProductNavigation from "@/components/ProductNavigation.vue";
import PSIInformation from "@/components/PSI_Information.vue";
import LanguageNavigation from "@/components/LanguageNavigation.vue";
import CategoryNavigation from "@/components/CategoryNavigation.vue";
import RMATab from "@/components/RMATab.vue";
import MessageModal from "@/components/MessageModal.vue";
import ZendeskBox from "@/components/ZendeskBox.vue";
import MultiSelect from "@/components/VueSelectComponent";
import WorkflowInternes from "@/components/Workflow_Internes.vue";


export default {
  components: {
    Header,
    Table,
    ProductInfo,
    ProductNavigation,
    PSIInformation,
    LanguageNavigation,
    CategoryNavigation,
    RMATab,
    MessageModal,
    ZendeskBox,
    LoadingAnimation,
    MultiSelect,
    WorkflowInternes
  },
  data() {
    return {
      rerender: 0,
      rma_message: "Bevor Du RMA auslösen kannst, musst Du die Kundendaten im Zendesk-Ticket aufnehmen!",
      showModal: false,
      loading: false,
      searchTerms: [],
      no_product_message: "Das Produkt ist EOS. Du musst das EOS-Makro verwenden.",
      showModalIfNoProduct: false,

    };
  },
  computed: {
    optionsTerms(){
      return this.$store.getters['options/getAllSearchterms']
    },
    requester() {
      return this.$store.state.zendesk.requester;
    },
    items_ilvy() {
      return this.$store.state.items.items_ilvy;
    },
    ilvy_count() {
      return this.$store.state.items.items_ilvy_length ?? 0;
    },
    items_techex() {
      return this.$store.state.items.items_techex;
    },
    techex_count() {
      return this.$store.state.items.items_techex_length ?? 0;
    },
    items_fritzwiki() {
      return this.$store.state.items.items_fritzwiki;
    },
    fritzwiki_count() {
      return this.$store.state.items.items_fritzwiki_length ?? 0;
    },
    psi_data() {
      return this.$store.state.items.psi_data;
    },
    product_has_warranty() {
      return (
        this.$store.state.items.psi_data?.data?.fields["garantiezeit"]?.data !==
        "keine"
      );
    },
    doc_class() {
      return this.$store.state.input.doc_class;
    },
    doc_tab: {
      get() {
        return this.$store.state.input.doc_tab;
      },
      set(newVal) {
        this.$store.commit("input/doc_tab", newVal);
      },
    },
    info_tab: {
      get() {
        return this.$store.state.input.info_tab;
      },
      set(newVal) {
        this.$store.commit("input/info_tab", newVal);
      },
    },
    currentPage_ilvy() {
      return this.$store.state.input.currentPage_ilvy;
    },
    currentPage_techex() {
      return this.$store.state.input.currentPage_techex;
    },
    currentPage_fritzwiki() {
      return this.$store.state.input.currentPage_fritzwiki;
    },
    zendesk_channel() {
      return this.$store.state.input.channel;
    },
    language() {
      return this.$store.state.input.language;
    },
    product: {
      get() {
        return this.$store.state.input.product.slug;
      },
      set(newVal) {
        this.$router
          .push({
            params: { ...this.$route.params, product: newVal },
            query: { ...this.$route.query },
          })
          .catch((err) => {
            console.error(err);
          });
      },
    },
    ticket_id() {
      return this.$store.state.input.ticket_id;
    },
    query: {
      get() {
        return this.$store.state.input.query.length ? this.$store.state.input.query : [];
      },
      set() { },
    },
  },
  watch: {
    product_has_warranty(newVal) {
      if (!newVal && this.info_tab == "rma") {
        this.changeInfoTab("support");
      }
    },
    items_ilvy() {
      this.rerender++;
    },
    items_techex() {
      this.rerender++;
    },
    items_fritzwiki() {
      this.rerender++;
    },
    doc_tab() {
      this.rerender++;
    },
    info_tab() {
      this.rerender++;
    },
    psi_data() {
      this.rerender++;
    },
    doc_class() {
      this.rerender++;
    },
  },
  created() {
    
  },
  beforeMount() {
    
    if (!this.$route.params.lang) {
      this.$router
        .push({
          params: { ...this.$route.params, lang: this.language },
          query: { ...this.$route.query },
        })
        .then(() => {
          if (!this.$route.params.product) {
            this.$router
              .push({
                params: { ...this.$route.params, product: this.product },
                query: { ...this.$route.query },
              })
              .catch((err) => {
                console.error(err);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  },
  updated() {
    this.setTitle();
  },
  mounted() {
    this.$store.dispatch('options/getAllSearchTerms')
    if (!this.info_tab) {
      this.info_tab = "support";
    }

    if (!this.doc_tab) {
      this.doc_tab = "ilvy";
    }
    this.showModalIfNoProduct = this.product ? false : true
  },
  methods: {
    closeTab(){
      window.close()
    },
    valueChanged(terms){
      const newVal = terms.join(' ')
      if (newVal.length == 0) {
        let query = Object.assign({}, this.$route.query);
        delete query.q;
        this.$router.replace({ query });
        this.$store.dispatch("input/query", []);
      } else {
        this.$store.dispatch("input/query", terms);
        this.$router
          .push({ query: { ...this.$route.query, q: newVal } })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    startLoading() {
      this.loading = true;
      this.rerender++;
    },
    stopLoading() {
      this.loading = false;
      this.rerender++;
    },
    setTitle() {
      document.title = this.$store.state.input.ticket_id
        ? `#${this.$store.state.input.ticket_id} - ${this.$store.state.input.product.name}`
        : `ohne ZD# - ${this.$store.state.input.product.name}`;
    },
    changeInfoTab(newVal) {
      this.$store.commit("input/info_tab", newVal);     
    },
    changeDocTab(newVal) {
      this.$store.commit("input/doc_tab", newVal);
    },
    resetQuery() {
      let query = Object.assign({}, this.$route.query);
      delete query.cat;
      delete query.q;
      this.$router.replace({ query });
      this.$store.dispatch("input/reset");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
nav li {
  display: inline-flex;
  padding-right: 8px;
}
.hovered {
  cursor: pointer;
}

.product_select_single:hover {
  color: var(--accent-color);
}
.product_select > ul {
  position: absolute;
  display: none;
  z-index: 5;
  background-color: white;
  border: 1px solid #353c41;
}
.product_select_open {
  display: block !important;
}

.popup > div > ul {
  background-color: var(--body-background);
  color: var(--content-text);
}

.loading-spinner_wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--body-background);
  color: var(--content-text);
  z-index: 999;
  transition: all 0.3s linear 0s;
}
</style>