<template>
    <div>
        <h2 class="backend">OAuth 2.0 Zugangsdaten</h2>
        <button @click="openChangeAuthDataModal()" class="button right">Zugangsdaten ändern</button>
        <div class="table">
          <ul class="oauth_table">
            <li class="table_header">
              <span class="left_align">Name</span>
              <span class="left_align">Wert</span>
            </li>
            <li>
              <span>Token Name:</span>
              <span>{{oAuth.tokenName}}</span>
            </li>
            <li>
              <span>Token URL:</span>
              <span>{{oAuth.tokenUrl}}</span>
            </li>
            <li>
              <span>Client ID:</span>
              <span>{{oAuth.clientId}}</span>
            </li>
            <li>
              <span>Client Secret:</span>
              <span>{{oAuth.clientSecret}}</span>
            </li>
            <li>
              <span>Scope:</span>
              <span>{{oAuth.scope}}</span>
            </li>
          </ul>
        </div>
        
        <div id="popup_catsort" class="overlay" v-if="changeAuthDataModal">
            <div class="popup">
                <h2>Zugangsdaten ändern</h2>
                <div class="popup_form">
                  <label for="token_name">Token-Name</label>
                  <input name="token_name" v-model="oAuth.tokenName">
                  <br>
                  <label for="acces_token_url">Access Token URL</label>
                  <input name="acces_token_url" v-model="oAuth.tokenUrl">
                  <br>
                  <label for="client_id">Client ID</label>
                  <input name="client_id" v-model="oAuth.clientId">
                  <br>
                  <label for="client_secret">Client Secret</label>
                  <input name="client_secret" v-model="oAuth.clientSecret">
                  <br>
                  <label for="scope">Scope</label>
                  <input name="scope" v-model="oAuth.scope">

                </div>
                <div class="popup_action">
                    <a @click="closeChangeAuthDataModal()" class="button close">Schließen</a>
                    <a @click="changeOAuthData()" class="button icon important">Speichern</a>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
  export default {
    data() {
      
      return {
        changeAuthDataModal: false,
        form: {
          token_name: '',
          access_token_url: '',
          client_id: '',
          client_secret: '',
          scope: ''
        },
        oAuth: {
          tokenName: '',
          tokenUrl: '',
          clientId: '',
          clientSecret: '',
          scope: ''
        },
        
      }
    },
    computed: {

    },
    mounted() {
      this.getOAuthData()
    },
    
    methods: {
      getOAuthData(){
        const controller = new AbortController()
        const signal = controller.signal
        setTimeout(() => { 
          controller.abort()
        }, 5000)
        
        fetch('/api/auth/data', {signal:signal})
        .then(resp => resp.json())
        .then(json => {
            if(json.token_name){
              this.oAuth.tokenName = json.token_name
            }
            if(json.access_token_url){
              this.oAuth.tokenUrl = json.access_token_url
            }
            if(json.client_id){
              this.oAuth.clientId = json.client_id
            }
            if(json.client_secret){
              this.oAuth.clientSecret = json.client_secret
            }
            if(json.scope){
              this.oAuth.scope = json.scope 
            }                
        })
        .catch(error => {
            console.error(error)
        })
      },
      changeOAuthData(){
        const controller = new AbortController()
        const signal = controller.signal
        setTimeout(() => { 
          controller.abort()
        }, 5000)
        let url = "/api/auth/data/1"
        let data = {
          token_name: this.oAuth.tokenName,
          access_token_url: this.oAuth.tokenUrl,
          client_id: this.oAuth.clientId,
          client_secret: this.oAuth.clientSecret,
          scope: this.oAuth.scope
        }
        let options = {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
          signal:signal
        };
        fetch(url, options)
        .then(resp => {
            if(resp.status == 200){
             this.closeChangeAuthDataModal()
            }
          })
          .catch(error => {
            alert('Zugangsdaten konnten nicht geändert werden. Grund: ' + error)
            window.location.reload()
          })
      },
      closeChangeAuthDataModal(){
        this.changeAuthDataModal = false
      },
      openChangeAuthDataModal(){
        this.changeAuthDataModal = true
      }
    }
  }
</script>
<style  scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>