<template>
  <div>

    <!--
        Hier gehts weiter bro
    -->


    <Header endType="Backend" :key="rerender" />
    <AdminNavigation processType="WuI" />
    <div class="main_area layout_container">
      <div class="backend">
        <h1 v-if="type == 'anlegen'">W&I Kategorie erstellen</h1>
        <h1 v-else>{{ name }} bearbeiten</h1>
      </div>

      <div class="msm_box form">
        <fieldset>
          <legend>Name</legend>
          <input type="text" class="name_edit" v-model="name" />
        </fieldset>

        <fieldset class="field_row">
          <div>
            <legend>W&I-ID</legend>
            <input type="text" class="name_edit" v-model="node" />
          </div>
          <div>
            <legend>SupportNews-Name</legend>
            <input type="text" class="name_edit" v-model="category_name" />
          </div>
        </fieldset>
        <fieldset class="field_row">
          <div id="base_url">
            <legend>Link</legend>
            <input type="text" class="name_edit" v-model="link" />
          </div>
        </fieldset>
        <div class="form_action">
          <a @click="cancel" class="button close">Abbrechen</a>
          <a @click="onSubmit" class="button important">Speichern</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import AdminNavigation from "@/components/AdminNavigation.vue";
export default {
  components: {
    Header,
    AdminNavigation
  },
  data() {
    return {
      id: null,
      rerender: 0,
      terms: [],
      name: "",
      node: "",
      link: "",
      category_name: "",
    };
  },
  computed: {
    type(){
      return this.$route.params.type;
    },
    single(){
      return this.$store.state.admin.workflowinternes.single
    }
  },
  beforeMount() {
    this.name = this.single.name ?? "";
    this.node = this.single.node ?? "";
    this.link = this.single.link ?? "";
    this.id = this.$route.params.id ?? null
    this.category_name = this.single.category_name ?? "";
  },
  methods: {
    
    cancel() {
      this.$router.push({ name: "wuiverwaltung" });
    },
    onSubmit(evt) {
      evt.preventDefault();

      this.saveData()
        .then((response) => {
          if (response) {
            this.$router.push({
              name: "wuiverwaltung",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveData() {
      let data = {
        name: this.name,
        node: this.node,
        link: this.link,
        category_name: this.category_name,
      };
      if (this.type == "anlegen") {
        return this.$store.dispatch("admin/workflowinternes/createCategory", data);
      } else {
        data.id = this.id;
        return this.$store.dispatch("admin/workflowinternes/updateCategory", data);
      }
    
    },
  },
  watch: {},
};
</script>
<style  scoped>
#base_url {
  width: 50%;
}
#base_url > input {
  width: 100%;
}
</style>