<template>
  <div id="main">
    <p id="message" class="success_protocol">An Zendesk übergeben:</p>

    <ul class="protocol_items">
      <li v-for="(item, index) in zendesk_response" :key="index">
        <p v-if="Object.keys(item)[0] == 'ilvy'">
          Dok/MailTB <span>{{ Object.values(item)[0] }}</span>
        </p>
        <p v-if="Object.keys(item)[0] == 'rma'">
          RMA-Ticket <span>#{{ Object.values(item)[0] }}</span>
        </p>
        <div v-if="hasError">
          <p>RMA-Ticket konnte nicht erstellt werden</p>
          <button @click="backToIlvy()">Zurück zu Ilvy</button>
        </div>
      </li>
    </ul>
    <a v-if="!hasError" @click="closeTab()" class="button important close_button"
      >Tab schließen</a
    >
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    zendesk_response() {
      return this.$store.state.zendesk.response_data.success;
    },
    hasError() {
      return this.zendesk_response.some((item) =>
        Object.keys(item).includes("rma_error")
      );
    },
  },
  methods: {
    backToIlvy() {
      this.$router.push({ name: "suchmaske" });
    },
    closeTab() {
      window.close();
    },
  },
};
</script>

<style  scoped>
#main {
  height: 93vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  max-width: 90%;
}
#status {
  font-weight: bold;
  font-size: 6em;
}
.close_button {
  margin: 20px auto;
}
</style>