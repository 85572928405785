<template>
<div>
  <Header endType="Backend" :key="rerender"/>
  <AdminNavigation processType="Produkte"/>
  <div class="main_area layout_container">
    <div class="backend">
        <h1>Produkte</h1>
    </div>
    <a @click="showSyncBox = true" class="button icon important sync">Synchronisieren</a>
    <fieldset class="">
        <div class="default_product">
            <legend>Standard-Produkt</legend>
            <select placeholder="Produkt wählen" class="product_select" v-model="default_product" >
              <option v-for="product in items" :key="product.id" :value="product.ilvy_slug">{{product.ilvy_slug}}</option>
            </select>
        </div>
    </fieldset>
    <div id="popup_productsync" class="overlay" v-if="showSyncBox">
        <div class="popup">
            <h2>Produkte synchronisieren</h2>
            <div class="popup_content">
                Sollen die Produkte mit dem Ilvy!-CMS synchronisiert werden?
                <br><span class="attention">Produkte ohne Schreibschutz werden beim Synchronisieren überschrieben!</span>
            </div>
            <div class="popup_action">
                <a @click="showSyncBox = false" class="button close">Abbrechen</a>
                <a @click="syncData" class="button important">Sychronisieren</a>
            </div>
        </div>
    </div>
    <DeleteItem :item="product_to_delete" v-if="showDeleteItem" process_type="Produkt"/>
    
    <div class="table">
        <div class="table_header">
            <input v-model="filter" type="search" placeholder="Produkte durchsuchen" class="product_search">
            <ul class="viewfilter">
                <li>
                    <a @click="updateApprovement_Permission_all(false)" class="protect_all hovered">
                        <i class="fas fa-lock-open"></i>
                        Alle freigeben
                    </a>
                </li>
                <li>
                    <a @click="updateApprovement_Permission_all(true)" class="protect_all hovered">
                        <i class="fas fa-lock"></i>
                        Alle schreibschützen
                    </a>
                </li>
                <li>
                    <a @mouseover="showMenu" class="filter_select hovered">Filtern nach 
                        <i class="fa fa-caret-down"></i>
                        <ul class="filter_select_open closed" @mouseleave="closeMenu">
                          <li v-for="(pg, index) in product_groups" :key="index">
                            <input type="checkbox" :id="index" :value="pg" :checked="checkedPGs.includes(pg)" v-model="checkedPGs">
                            <label :for="index">{{pg}}</label>
                          </li>
                        </ul>
                    </a>                                        
                </li>
            </ul>
        </div>
        <ul v-if="filtered_items.length" class="product_table">
            <li class="table_header">
                <span class="left_align">Name</span>
                <span class="left_align">Sprachen</span>
                <span>SKB</span>
                <span>PSI</span>
                <span>SNs</span>
                <span>Freigabe</span>
                <span>Schreibschutz</span>
            </li>
            <li v-for="product in table_items" :key="product.ilvy_slug">
              <span>{{product.name}}</span>
              <div class="languages">
                <span v-for="lang in product.languages" :key="lang" :class="lang"></span>
              </div>
              <span class="status_icon" :class="{ 'check' : product.ilvy_slug , 'uncheck' : !product.ilvy_slug}"></span>
              <span class="status_icon" :class="{ 'check' : product.psi_slug , 'uncheck' : !product.psi_slug}"></span>
              <span class="status_icon" :class="{ 'check' : product.supportnews_tag , 'uncheck' : !product.supportnews_tag}"></span>
              <a @click="product.approved == 1 ? product.approved = 0 : product.approved = 1; updateApprovement_Permission(product.id, product.approved, 'approved')" class="status_icon hovered" :class="{ 'released' : product.approved == 1 , 'unreleased' : product.approved == 0}"></a>
              <a @click="product.write_perm == 1 ? product.write_perm = 0 : product.write_perm = 1; updateApprovement_Permission(product.id, product.write_perm, 'write_perm')" class="status_icon hovered" :class="{ 'secured' : product.write_perm == 1 , 'unsecured' : product.write_perm == 0}"></a>
              <a @click="updateProduct(product)" class="status_icon edit hovered"></a>
              <a @click="setProductToDelete(product)" class="status_icon delete hovered"></a>
            </li>
        </ul>
        <div class="table_footer">
          <ul class="pagination">
            <li @click="paginate(count,1)" class="page_first hovered" :class="{ 'disabled' : currentPage == 1}"><a ><i class="fas fa-angle-double-left"></i></a></li>
            <li @click="paginate(count,currentPage-1)" class="page_before hovered" :class="{ 'disabled' : currentPage == 1}"><a >Vorherige</a></li>
            
            <li @click="paginate(count,page)" v-for="page in max_pages" :key="page" class="page hovered" :class="{ 'active' : currentPage == page}"><a>{{page}}</a></li>
            
            <li @click="paginate(count,currentPage+1)" class="page_next hovered" :class="{ 'disabled' : currentPage == last_page}"><a>Nächste</a></li>
            <li class="page_last hovered" @click="paginate(count, last_page)" :class="{ 'disabled' : currentPage == last_page}"><a ><i class="fas fa-angle-double-right"></i></a></li>
          </ul>
            
        </div>
    </div>

</div>

</div>

</template>

<script>
import Header from '@/components/Header.vue'
import DeleteItem from '@/components/DeleteItem.vue'
import AdminNavigation from '@/components/AdminNavigation.vue'
  export default {
    components: {
      Header, DeleteItem, AdminNavigation
    },
    data() {
      return {
        filter: '',
        count: null,
        currentPage: 1,
        records_per_page: 40,
        totalPages: 0,
        startPage: 0,
        endPage: 0,
        startIndex: 0,
        endIndex: 0,
        max_pages: [],
        table_items: [],
        filtered_items: [],
        filteredByCheckBox: [],
        last_page: null,
        showSyncBox: false,
        showDeleteItem:false,
        product_to_delete: null,
        items: [],
        loading_con: 'loading_screen',
        product_groups: [],
        checkedPGs: [],
        rerender: 0
      }
    },
    watch: {
      checkedPGs(val){
        sessionStorage.setItem('checkedPGs', JSON.stringify(val));
        let filteritems = this.items.filter(( obj ) => {
          if (val.includes(obj.product_group)){
            return obj
          }
          
        
        })
        
        this.filtered_items = filteritems
        if(val.length == 0){
          this.filtered_items = this.items
        }
        this.filteredByCheckBox = this.filtered_items
        this.paginate(this.filtered_items.length,1)
      },
      filter(val){
        
        let filteritems = this.filteredByCheckBox.filter(( obj ) => {
          if (obj.name.toLowerCase().includes(val.toLowerCase())){
            return obj
          }
          
          
        })
        if (filteritems.length){
          this.filtered_items = filteritems
        }
        else{
          this.filtered_items = []
        }
        
        if(val.length == 0){
          this.filtered_items = this.filteredByCheckBox
        }
        
        
        
        this.paginate(this.filtered_items.length,1)
      }
    },
    computed: {
      default_product:{
        get(){
          if (this.items.filter(item => item.default_product === 1).length){
            return this.items.filter(item => item.default_product === 1)[0].ilvy_slug
          }
          else{
            return ''
          }
          
        },
        set(newVal){
          this.changeDefaultProduct(newVal)
        }
      }
    },
    mounted() {
      this.getProducts()
      this.rerender++
    },
    updated(){
      
    },
    methods: {
      changeDefaultProduct(product){
        this.$store.dispatch('input/changeDefaultProduct', product)
      },
      getProducts(){
        fetch('/api/msm/getLangProd')
        .then(resp => resp.json())
        .then(json => {
          if(json){
            let product_groups = []
            json.forEach((item, index) =>{
              if(item.languages){
                json[index].languages = item.languages.split(',')
                json[index].languages.sort()
                if(item.product_group){
                  if (!product_groups.includes(item.product_group)){
                  product_groups.push(item.product_group)
                } 
                }
                
              }
                             
            })
            this.product_groups = product_groups
            json.sort((a,b) => (a.name > b.name ) ? 1 : ((b.name  > a.name ) ? -1 : 0)) // sort by name              
            this.items = json
            this.table_items = json
            this.filtered_items = json
            this.filteredByCheckBox = json
            this.count = json.length
            this.loading_con = 'loading_screen_off'
            if(sessionStorage.checkedPGs != null){
              this.checkedPGs = JSON.parse(sessionStorage.getItem('checkedPGs'));
              
            }
            this.paginate(this.count,this.currentPage)
          }
          
            
        })
        .catch(error => {
          console.log(error)
          this.loading_con = 'loading_screen_off'
        })
      },
      showMenu(e){
        if(e.target.children[1]){
          e.target.children[1].classList.remove('closed')
        }
        
      },
      closeMenu(){
        document.querySelector('.filter_select_open').classList.add('closed')
      },
      setProductToDelete(product){
        this.showDeleteItem = true
        this.product_to_delete = product
      },
      syncData(){
        this.loading_con = 'loading_screen'
        this.showSyncBox = false
        fetch('/ws/products_synchro/syncData')
        .then(response => {
            if (response.status == 200) {
              this.loading_con = 'loading_screen_off'
              
              this.getProducts()
            }            
        })
        .catch(error => {
          console.error(error)
        })
      },
      updateApprovement_Permission_all(status){
          let fetches = []
          this.loading_con = 'loading_screen'
          if(status){
            this.items.forEach(item =>{
              item.write_perm = 1
              fetches.push(this.updateApprovement_Permission(item.id, 1, 'write_perm'))
              
            })
          }
          else{
            this.items.forEach(item =>{
              item.write_perm = 0
              fetches.push(this.updateApprovement_Permission(item.id, 0, 'write_perm'))
              
            })
          }
          

          Promise.all(fetches).then(()=>{
            this.loading_con = 'loading_screen_off'
        })
        
      },
      updateApprovement_Permission(id, e = null, type){
        
        return this.updateRequest(id, e, type)
        .then(response => {
            if (response.status == 200) {
              this.$store.dispatch('options/products', this.$store.state.input.language)
            }
        })
        .catch(error => {
          alert("nicht erfolgreich")
          console.log(error)
          window.location.reload()
        })   
        
      },
      updateRequest(id, e = null, type){
        let url = "/api/msm/updateApprovement/"+id                 
        return this.$http.put(url, {            
            [type]: e ,      
        })
      },
      updateProduct(item){
        this.$router.push({
        name: 'produktansicht',
        params: {
            id: item.id            
        },
            
        })
        
      },
      deleteItem(id){
        this.loading_con = 'loading_screen'
        this.showDeleteItem = false
        let prod_url = "/api/msm/"+id                    
        this.$http.delete(prod_url)
        .then(response => {
            if (response.status == 200) {
              
              this.loading_con = 'loading_screen_off'
              this.getProducts()
            }            
        })
        .catch(error => {
          alert("nicht erfolgreich")
          console.log(error)
          window.location.reload()
        })
      },
      paginate(totalItems, currentPage = 1, pageSize = this.records_per_page, maxPages = 6){
          
          let totalPages = Math.ceil(totalItems / pageSize);
            this.last_page = totalPages
          if (currentPage < 1) {
              currentPage = 1;
          } else if (currentPage > totalPages) {
              currentPage = totalPages;
          }

          let startPage, endPage;
          if (totalPages <= maxPages) {
              startPage = 1;
              endPage = totalPages;
          } else {
              let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
              let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
              if (currentPage <= maxPagesBeforeCurrentPage) {
                  startPage = 1;
                  endPage = maxPages;
              } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
                  startPage = totalPages - maxPages + 1;
                  endPage = totalPages;
              } else {
                  startPage = currentPage - maxPagesBeforeCurrentPage;
                  endPage = currentPage + maxPagesAfterCurrentPage;
              }
          }
          let startIndex = (currentPage - 1) * pageSize;
          let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
          let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

          this.count = totalItems
          this.currentPage = currentPage
          this.records_per_page = pageSize
          this.totalPages = totalPages
          this.startPage = startPage
          this.endPage = endPage
          this.startIndex = startIndex
          this.endIndex = endIndex
          this.max_pages = pages
          this.pushToTable()
          
      },
      pushToTable(){
        this.table_items = []
        for(let i = this.startIndex; i <= this.endIndex; i++){
          this.table_items.push(this.filtered_items[i])
        }
      },
      productFilter(){        
        let filteritems = this.filtered_items.filter(( obj ) => {
          if (obj.name.toLowerCase().includes(this.filter.toLowerCase())){
            return obj
          }
          
        })
        if (filteritems.length){
          this.filtered_items = filteritems
        }        
        this.paginate(this.filtered_items.length,1)
        
      }
    }
  }
</script>
<style  scoped>
.closed{
  display: none;
}
.loading_screen_off{
  display: none;
  transition: all .3s linear 0s;
}
.loading_screen{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,0.98);
  overflow: hidden;
  z-index: 999;
  transition: all .3s linear 0s;
}
.write_perm_head{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.button_con{
  display: flex;
  align-items: center;
  justify-content: start;
}
.action_buttons {
  display: flex;
  justify-content: space-around;
}
.action_buttons >*{
  margin-right: 10px;
}
.createNew{
    margin-bottom: 20px;
}
.main_con{
  width: 100%;
  padding: 5%;
}
.pagination_per_page_container{
    display: flex;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
 
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

fieldset{
  border: none;
  padding: 0;
  margin-bottom: 10px;
}
</style>