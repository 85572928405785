import axios from 'axios';
const options_store = {
    namespaced: true,
    state: () => ({
        languages: [],
        products: [],
        unparsedProducts: [],
        categories: [],
        docClasses: [],
        searchterms: {},
        userTerms: [],
        languageTerms: [],
        categoryTerms: []
    }),
    mutations: {
        languages(state, language) {
            state.languages = language
        },
        products(state, product) {
            state.products = product
        },
        unparsedProducts(state, unparsedProducts) {
            state.unparsedProducts = unparsedProducts
        },
        categories(state, categories) {
            state.categories = categories
        },
        docClasses(state, docClasses) {
            state.docClasses = docClasses
        },
        userTerms(state, terms){
            state.userTerms = terms
        },
        languageTerms(state, terms){
            state.languageTerms = terms
        },
        categoryTerms(state, terms){
            state.categoryTerms = terms
        }
    },
    actions: {
        async getUserTerms({rootState, commit}){
            const user_id = rootState.user.user.id
            const userTerms = await getUserTerms(user_id);
            commit('userTerms', userTerms)
        },
        async getLanguageTerms({rootState, commit}){
            const onlyUserTerms = rootState.user.userConfig.onlyMyTerms == 1 ? true : false
            if(onlyUserTerms){
                commit('languageTerms', [])
            }
            else{
                const lang_slug = rootState.input.language
                const langTerms = await getLanguageTerms(lang_slug);
                commit('languageTerms', langTerms)
            }
            
        },
        async getCategoryTerms({rootState, commit, getters}){
            const onlyUserTerms = rootState.user.userConfig.onlyMyTerms == 1 ? true : false
            if(onlyUserTerms){
                commit('categoryTerms', [])
            }
            else{
                const lang_slug = rootState.input.language
                const showAllCategoryTerms = await showAllCategoryTermsConfig()
                const categories = rootState.input.categories.length ? rootState.input.categories : (showAllCategoryTerms ? getters['getAllMatchingCategories'] : [])
               
                const catTerms = categories.length ? await getCategoryTermsFromDB(lang_slug, categories) : [];
                
                commit('categoryTerms', catTerms)
            }
            
        },
        async getAllSearchTerms({ dispatch}) {
            return Promise.all([
                dispatch('getUserTerms'),
                dispatch('getLanguageTerms'),
                dispatch('getCategoryTerms')
            ])
        },
        products({ dispatch, commit }, language) {
            const controller = new AbortController()
            const signal = controller.signal
            setTimeout(() => {
                controller.abort()
            }, 8000)
            fetch('/api/msm/getProductsByLanguage/' + language, { signal: signal })
                .then(resp => {
                    if (resp.status == 403) {
                        dispatch('user/logout', '', { root: true }).then(() => {

                        })
                        throw new Error('User logged out')
                    }

                    return resp.json()
                })
                .then(json => {
                    if (json) {
                        commit('products', parseProduct(json))
                        commit('unparsedProducts', json)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        categories(context, data) {
            if (data.product && data.language) {
                const controller = new AbortController()
                const signal = controller.signal
                setTimeout(() => {
                    controller.abort()
                }, 8000)
                fetch('/api/categories/getCatByProd/' + data.product + '/' + data.language, { signal: signal })
                    .then(resp => resp.json())
                    .then(json => {
                        if (json) {
                            context.commit('categories', json)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }

        },
        languages(context) {
            const controller = new AbortController()
            const signal = controller.signal
            setTimeout(() => {
                controller.abort()
            }, 8000)
            fetch('/api/languages/getAll', { signal: signal })
                .then(resp => resp.json())
                .then(json => {
                    if (json) {
                        context.commit('languages', json)
                    }

                })
                .catch(err => {
                    console.log(err)
                })
        },
        docClasses(context) {
            const controller = new AbortController()
            const signal = controller.signal
            setTimeout(() => {
                controller.abort()
            }, 8000)
            fetch('/api/doc_class/getAll/', { signal: signal })
                .then(resp => resp.json())
                .then(json => {
                    if (json) {
                        context.commit('docClasses', json)
                    }
                })
                .catch(err => {
                    console.log(err)
                    context.commit('docClasses', [])
                })
        }
    },
    getters: {
        getCategoryTag: (state) => (type, categories) => {
            if (state.categories) {
                return state.categories.filter(cat => categories.includes(cat.cat_id)).map(item => item[type]).join(',')
            }
            else {
                return ''
            }
        },
        getBaseURL: (state, getters, rootState) => {
            if (state.languages) {
                return state.languages.filter(lang => lang?.slug === rootState.input?.language).map(item => item?.url).join()
            }
            else {
                return ''
            }
        },
        getAllMatchingCategories: (state) => {
            return state.categories.map(item => item.cat_id)
        },
        getAllSearchterms: (state, getters, rootState) => {
            const onlyUserTerms = rootState.user.userConfig.onlyMyTerms == 1 ? true : false
            if(onlyUserTerms){
                return [
                    {
                        group: 'meine Suchbegriffe',
                        values: state.userTerms
                    }
                ]
            }
            else{
                const userTerms = state.userTerms
                const langTerms = state.languageTerms
                const categoryTerms = state.categoryTerms
                const allOtherTerms = langTerms.concat(categoryTerms)

                return [
                    {
                        group: 'meine Suchbegriffe',
                        values: userTerms.sort(function (a, b) {
                            return a.toLowerCase().localeCompare(b.toLowerCase());
                        })
                    },
                    {
                        group: 'Vorschläge',
                        values: allOtherTerms.sort(function (a, b) {
                            return a.toLowerCase().localeCompare(b.toLowerCase());
                        })
                    }
                ]
            }
        }
    }
}
function parseProduct(json) {
    json.sort((a, b) => (a.product_order < b.product_order) ? 1 : ((b.product_order < a.product_order) ? -1 : 0))

    let product_groups = []
    json.forEach(item => {
        product_groups.push({ label: item.product_group, options: [] })
        product_groups[product_groups.length - 1].options.push(item)
    })
    const combined = {};
    product_groups.forEach(o => {
        const id = o["label"];
        const obj = o["options"][0];
        if (!combined[id]) { combined[id] = [obj]; }
        else { combined[id].push(obj); }
    });
    const result = [];
    Object.keys(combined).forEach(key => {
        result.push({ "label": key, "options": combined[key] });
    });
    result.forEach(product_group => {
        product_group.options.sort((a, b) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0))
    })
    return result.reverse()
}

async function getUserTerms(user_id) {
    const url = `/api/user_searchterms/get/${user_id}`
    return await axios.get(url).then(data => {
        return data.data.terms
    })
}

async function getLanguageTerms(lang_slug) {
    const url = `/api/language_searchterms/get/${lang_slug}`
    return await axios.get(url).then(data => {
        return data.data.terms
    })
}

async function getCategoryTermsFromDB(lang_slug, categories) {
    const categorystring = categories.join(',')
    const url = `/api/category_searchterms/get/${lang_slug}?categories=${categorystring}`
    return await axios.get(url).then(data => {
        return data.data.terms
    })
}

async function showAllCategoryTermsConfig() {
    const url = "/api/categories/showAllTerms"
    return await axios.get(url).then(data => {
        return data.data.value == 1 ? true : false
    })
}

export default options_store