<template >
<div>
  <Header endType="Backend" :key="rerender"/>
  <AdminNavigation processType="WuI"/>
  <div class="main_area layout_container">
    <div class="backend">
        <h1>Workflow & Internes</h1>
    </div>
    <a @click="createNew" class="button icon new">Neue Kategorie</a>
    <a @click="showOrderBox = true" class="button icon sort">Reihenfolge ändern</a>
    <DeleteItem :item="item_to_delete" v-if="showDeleteItem" process_type="Kategorie"/>
    <div id="popup_catsort" class="overlay" v-if="showOrderBox">
        <div class="popup">
            <h2>Reihenfolge ändern</h2>
            <div class="popup_content sort_list">
              <transition-group name="slide-fade">
                <div v-for="(cat,i) in cat_order" :key="i" class="sort_item" draggable="true" @dragstart="dragStart(i, $event)" @dragover.prevent @dragend="dragEnd" @drop="dragFinish(i, $event)">{{cat.name}}</div>
              </transition-group>
            </div>
            <div class="popup_action">
                <a @click="handleCancel" class="button close">Verwerfen</a>
                <a @click="handleOk" class="button icon important">Speichern</a>
            </div>
        </div>
    </div>
    <div class="table">
        <ul class="wui_table">
            <li class="table_header">
                <span class="left_align">Kategorie</span>
                <span class="left_align">W&I-ID</span>
                <span class="left_align">Link</span>
            </li>
            <li v-for="item in items" :key="item.id">
              <span>{{item.name}}</span>
              <span>{{item.node}}</span>
              <span>{{item.link}}</span>
              <a @click="updateItem(item)" class="status_icon edit hovered"></a>
              <a @click="setItemToDelete(item)" class="status_icon delete hovered"></a>
            </li>
        </ul>
    </div>
</div>
  
  
</div>


</template>

<script>
import Header from '@/components/Header.vue'
import DeleteItem from '@/components/DeleteItem.vue'
import AdminNavigation from '@/components/AdminNavigation.vue'
export default {
    components: {
      Header, DeleteItem, AdminNavigation
    },
    data(){
        return {
          showSyncBox: false,
          showDeleteItem: false,
          item_to_delete: null,
          rerender: 0,
          showOrderBox: false,
          cat_order: [], 
          items: [],
        } 
    },
    computed: {
      /*
        items(){
            return this.$store.state.admin.workflowinternes.categories
        }
        */
    },
    mounted() {
      this.items = this.$store.state.admin.workflowinternes.categories
        this.cat_order = [...this.items]
    },
    methods: {
        createNew(e){
            e.preventDefault()
        this.$router.push({ 
          name: 'wuiansicht',
          params: {
            type: 'anlegen'
            
          }
        })
        },
      setItemToDelete(item){
        this.showDeleteItem = true
        this.item_to_delete = item
      },
      updateItem(item){
        this.$router.push({
          name: 'wuiansicht',
          params: {
            id: item.id,
            type: 'update',
            
          },
            
        })
        
      },
      deleteItem(id){
        this.$store.dispatch('admin/workflowinternes/deleteCategory', id).then(() => {
          this.cat_order = this.cat_order.filter(item => item.id !== id)
        })
        this.showDeleteItem = false

      },
      dragStart(which, ev) {
        //ev.preventDefault()
        ev.dataTransfer.setData('Text', this.id);
        ev.dataTransfer.dropEffect = 'move'
        this.dragging = which;
        
      },
      
      dragEnd() {
        this.dragging = -1
      },
      dragFinish(to, ev) {
        ev.preventDefault()
        this.moveItem(this.dragging, to);
      },
      moveItem(from, to) {
        if (to === -1) {
          this.removeItemAt(from);
        } else {
          this.cat_order.splice(to, 0, this.cat_order.splice(from, 1)[0]);
        }
      },
      handleOk(){
        this.setPostion()
      },
      handleCancel(){
        this.showOrderBox = false
        this.cat_order = [...this.items]
      },
      setPostion(){
        let url = "/api/wui/setposition/"                
        this.$http.put(url, this.cat_order)
        .then(response => {
            if(response.status === 200){
              this.showOrderBox = false
              this.items = [...this.cat_order]
            }
        })
        .catch(error => {
          alert("nicht erfolgreich")
          console.log(error)
          
        })   
      }
    }
        
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
