<template >
    <ul class="language_filter">
        <li v-for="lang in optionsLanguages" :key="lang.id">
            <input v-model="language" type="radio" name="Sprache" :id="lang.slug" :value="lang.slug">
            <label :for="lang.slug">{{lang.slug.toUpperCase()}}</label>
        </li>
    </ul>    
</template>

<script>

export default {
    props: ['pub'],
    data(){
        return {

        } 
    },
    computed:{
      language:{
          get(){
            return this.$store.state.input.language
          },
          set(newVal){
            this.$router.push({ params: { ...this.$route.params, lang: newVal }, query: {...this.$route.query}}).catch(err => {console.error(err)})
            this.$store.dispatch('input/language', newVal)
            if(this.pub){
              let data = {
                lang: newVal,
                product: this.$store.state.input.product.slug,
                id: this.$store.state.publication_ilvy.content.doc_id
              }
              this.$store.dispatch('publication_ilvy/getContent', data)
            }
          }
        
      },
      optionsLanguages(){
        return this.pub ? this.$store.state.publication_ilvy.options.languages : this.$store.state.options.languages
      }
    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
