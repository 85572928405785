<template>
  <div class="progress-wrap" @click="backToTop">
    <svg
      class="progress-circle svg-content"
      width="100%"
      height="100%"
      viewBox="-1 -1 102 102"
    >
      <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scroll: null,
      progressPath: null,
      pathLength: null,
      height: null,
      progress: 0,
      offset: 50,
      duration: 550,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.progressPath = document.querySelector(".progress-wrap path");
    this.pathLength = this.progressPath.getTotalLength();
    this.progressPath.style.transition =
      this.progressPath.style.WebkitTransition = "none";
    this.progressPath.style.strokeDasharray =
      this.pathLength + " " + this.pathLength;
    this.progressPath.style.strokeDashoffset = this.pathLength;
    this.progressPath.getBoundingClientRect();
    this.progressPath.style.transition =
      this.progressPath.style.WebkitTransition =
        "stroke-dashoffset 10ms linear";

    this.updateProgress();
  },
  methods: {
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      this.updateProgress();
      if (scrollTop > this.offset) {
        document
          .querySelector(".progress-wrap")
          .classList.add("active-progress");
      } else {
        document
          .querySelector(".progress-wrap")
          .classList.remove("active-progress");
      }
    },
    backToTop() {
      
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      
    },
    updateProgress() {
      this.scroll =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      let body = document.body,
        html = document.documentElement;

      let docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      this.height = docHeight - window.innerHeight;
      this.progress =
        this.pathLength - (this.scroll * this.pathLength) / this.height;
      document.querySelector(".progress-wrap path").style.strokeDashoffset =
        this.progress;
    },
  },
};
</script>

<style scoped>
.progress-wrap {
  position: fixed;
  right: 2%;
  bottom: 13%;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px transparent;
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.progress-wrap::after {
  position: absolute;
  font-family: FontAwesome;
  content: "\f102";
  text-align: center;
  line-height: 46px;
  font-size: 32px;
  color: var(--accent-color);
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap:hover::after {
  opacity: 0;
}
.progress-wrap::before {
  position: absolute;
  font-family: FontAwesome;
  content: "\f102";
  text-align: center;
  line-height: 44px;
  font-size: 38px;
  opacity: 0;
  background: var(--accent-color);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 2;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap:hover::before {
  opacity: 1;
}
.progress-wrap svg path {
  fill: none;
}
.progress-wrap svg.progress-circle path {
  stroke: var(--accent-color);
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
</style>