<template >
  <div class="login">
    <header>
      <div class="layout_container">
        <span class="logo"></span>
      </div>
    </header>
    <nav></nav>
    <div class="layout_container">
      <div id="login_wrapper">
        <form @submit="onSubmit" autocomplete="on">
          <h1>Anmelden</h1>
          <p>Melde Dich mit Deinen SupportNews-Zugangsdaten an.</p>
          <hr>
          <label>E-Mail-Adresse</label>
          <input type="email" placeholder="E-Mail Adresse" v-model="form.email" required>
          <label>Passwort</label>
          <input type="password" placeholder="Passwort" v-model="form.password" required>
          <button type="submit" class="button primary_button">Anmelden</button>
          <div class="msm_box" v-if="unauthorized">Falsche Zugangsdaten. Bitte nochmal versuchen</div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import { authenticationService } from '@/_services/user';
export default {
    components: {
      
    },
    props:{
      from:{
        type: String
      }
    },
    data(){
        return {
          form: {
              email: '',
              password: ''
          },
          auth: false,
          thisFrom: this.from,
        } 
    },
    computed: {
      unauthorized(){
        return this.auth ? true : false
      }
    },
    mounted() {
      
    },
    methods: {
      onSubmit(e){
        e.preventDefault()
        authenticationService.login(this.form.email, this.form.password)        
        .then(data => {          
          if (data.status == 200){
            this.$store.commit('user/login')
            this.$store.dispatch('user/getUserConfig')
            this.$store.dispatch('input/product', this.$route.query.product ? this.$route.query.product : this.$store.state.input.default_product)
            this.auth = false            
            this.$router.push(this.$route.query.redirect || '/')
          }
          else if(data.status == 401){
            this.auth = true
          }
        })
        .catch(error => {
          console.log(error)
        })
      },
    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

form{
  display: flex;
  flex-direction: column;
}
form > *{
  margin: 5px 0;
  height: 40px;
}
</style>
