<template>
    <div>
        <h2 class="backend">Supportnews User (Cache Management)</h2>
        <button @click="openChangeSNUserModal()" class="button right">Zugangsdaten ändern</button>
        <div class="table">
          <ul class="oauth_table">
            <li class="table_header">
              <span class="left_align">Name</span>
              <span class="left_align">Wert</span>
            </li>
            <li>
              <span>Email:</span>
              <span>{{snUser.email}}</span>
            </li>
            <li>
              <span>Passwort:</span>
              <span>{{snUser.password}}</span>
            </li>
          </ul>
        </div>
        
        <div id="popup_catsort" class="overlay" v-if="changeModal">
            <div class="popup">
                <h2>Zugangsdaten ändern</h2>
                <div class="popup_form">
                  <label for="email">Email</label>
                  <input name="email" v-model="snUser.email">
                  <br>
                  <label for="password">Passwort</label>
                  <input name="password" v-model="snUser.password">
                </div>
                <div class="popup_action">
                    <a @click="closeChangeSNUserModal()" class="button close">Schließen</a>
                    <a @click="changeData()" class="button icon important">Speichern</a>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
  export default {
    data() {
      
      return {
        changeModal: false,
        snUser: {
            email: '',
            password: ''
        },
        
      }
    },
    computed: {

    },
    mounted() {
      this.getData()
    },
    
    methods: {
      getData(){
        const controller = new AbortController()
        const signal = controller.signal
        setTimeout(() => { 
          controller.abort()
        }, 5000)
        
        fetch('/api/auth/snuser', {signal:signal})
        .then(resp => resp.json())
        .then(data => {
            if(data.email){
              this.snUser.email = data.email
            }
            if(data.password){
              this.snUser.password = data.password
            }               
        })
        .catch(error => {
            console.error(error)
        })
      },
      changeData(){
        const controller = new AbortController()
        const signal = controller.signal
        setTimeout(() => { 
          controller.abort()
        }, 5000)
        let url = "/api/auth/snuser"
        let data = {
            email: this.snUser.email,
            password: this.snUser.password,
        }
        let options = {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
          signal:signal
        };
        fetch(url, options)
        .then(resp => {
            if(resp.status == 200){
             this.closeChangeSNUserModal()
            }
          })
          .catch(error => {
            alert('Zugangsdaten konnten nicht geändert werden. Grund: ' + error)
            window.location.reload()
          })
      },
      closeChangeSNUserModal(){
        this.changeModal = false
      },
      openChangeSNUserModal(){
        this.changeModal = true
      }
    }
  }
</script>
<style  scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>