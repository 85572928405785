const items_store = {
    namespaced: true,
    state: () => ({
        items_ilvy: [],
        items_ilvy_length: null,
        items_fritzwiki: [],
        items_fritzwiki_length: null,
        items_techex: [],
        items_techex_length: null,
        items_wui: [],
        items_wui_length: null,
        psi_data: []
    }),
    mutations: {
        items_ilvy(state, items) {
            state.items_ilvy = items
        },
        items_ilvy_length(state, length) {
            state.items_ilvy_length = length
        },
        items_fritzwiki(state, items) {
            state.items_fritzwiki = []
            state.items_fritzwiki = items
        },
        items_fritzwiki_length(state, length) {
            state.items_fritzwiki_length = length
        },
        items_techex(state, items) {
            state.items_techex = items
        },
        items_techex_length(state, length) {
            state.items_techex_length = length
        },
        items_wui(state, items) {
            state.items_wui = items
        },
        items_wui_length(state, length) {
            state.items_wui_length = length
        },
        psi_data(state, data) {
            state.psi_data = data
        }
    },
    actions: {
        getIlvyItems({dispatch, commit}, data) {
            if (data.product && data.language) {
                const product = data.product
                const query = data.query
                const language = data.language
                const category = data.categories
                let cat
                if (category) {
                    if (category.length > 1) {
                        cat = '&cat[]=' + category.join('&cat[]=') + '&op=and'//+this.cat_radio
                    }
                    else if (category.length == 1) {
                        cat = '&cat[]=' + category.join()
                    }
                }
                let body_data = {
                    channel: 'SBK',
                    language: language,
                    slug: product,
                    query: query,
                    category: cat
                }
                const controller = new AbortController()
                const signal = controller.signal
                setTimeout(() => {
                    controller.abort()
                }, 8000)
                fetch('/ws/products/getAll/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body_data),
                    signal: signal
                })
                    .then(response => {
                        if(response.status == 403){
                            dispatch('user/logout', '', {root: true}).then(() => {
                                
                            })
                            throw new Error('User logged out')
                        }
                        return response.json()
                    })
                    .then(data => {
                        if (data.error) { throw data }
                        commit('items_ilvy', data)
                        commit('items_ilvy_length', data.length)
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        commit('items_ilvy', [])
                        commit('items_ilvy_length', 0)
                    })
            }


        },
        getTechexItems({ commit }, data) {
            const product = data.supportnews_tag
            const query = data.query
            const body_data = {
                tag: product,
                cat: data.categories,
                query: query,
                type: 'techex'
            }
            const controller = new AbortController()
            const signal = controller.signal
            setTimeout(() => {
                controller.abort()
            }, 8000)
            fetch('/ws/supportnews/getAllTechex/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body_data),
                signal: signal
            })
                .then(response => response.json())
                .then(result => {
                    commit('items_techex', result)
                    commit('items_techex_length', result.length)
                })
                .catch((error) => {
                    console.error('Error:', error)
                    commit('items_techex', [])
                    commit('items_techex_length', 0)
                })
        },
        getFritzWikiItems({ commit }, data) {
            const product = data.supportnews_tag
            const query = data.query
            const body_data = {
                tag: product,
                cat: data.categories,
                query: query,
                type: 'fritzwiki'
            }
            
            const controller = new AbortController()
            const signal = controller.signal
            setTimeout(() => {
                controller.abort()
            }, 10000)
            
            fetch('/ws/supportnews/getAllFritzWiki/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body_data),
                signal: signal
            })
                .then(response => response.json())
                .then(result => {
                    commit('items_fritzwiki', result)
                    commit('items_fritzwiki_length', result.length)
                })
                .catch((error) => {
                    console.error('Error:', error);
                    commit('items_fritzwiki', [])
                    commit('items_fritzwiki_length', 0)
                })
        },
        getWuIItems({ commit }) {
            const controller = new AbortController()
            const signal = controller.signal
            setTimeout(() => {
                controller.abort()
            }, 10000)
            
            fetch('/ws/supportnews/getAllWuI/', {
                method: 'GET',
                signal: signal
            })
                .then(response => response.json())
                .then(result => {
                    commit('items_wui', result)
                    let length = 0
                    result.forEach(cat => {
                        length += cat.doks.length
                    })
                    commit('items_wui_length', length)
                })
                .catch((error) => {
                    console.error('Error:', error);
                    commit('items_wui', [])
                    commit('items_wui_length', 0)
                })
        },
        getPSIData({ commit }, data) {
            let product = data.psi_slug
            const controller = new AbortController()
            const signal = controller.signal
            setTimeout(() => {
                controller.abort()
            }, 8000)
            fetch('/ws/supportnews/getPSI/' + product, { signal: signal })
                .then(response => response.json())
                .then(json => {
                    commit('psi_data', json)
                })
                .catch(error => {
                    commit('psi_data', [])
                    console.error(error)
                })
        },
    },
    getters: {
        ilvy_count: state => {
            return state.items_ilvy ? state.items_ilvy.length : 0
        },
        techex_count: state => {
            return state.items_techex ? state.items_techex.length : 0
        },
        fritzwiki_count: state => {
            return state.items_fritzwiki ? state.items_fritzwiki.length : 0
        },
        wui_count: state => {
            return state.items_wui ? state.items_wui.length : 0
        },
        gui_link: state => {
            if (state.psi_data) {
                return state.psi_data.data?.fields["gui"] ? state.psi_data.data.fields["gui"].data : ''
            }
            else {
                return ''
            }
        },
        is_labor: state => {
            if (state.psi_data) {
                return state.psi_data.data?.fields["labor"] ? (state.psi_data.data.fields["labor"]?.data == "ja" ? true : false) : ''
            }
            else {
                return ''
            }
        },
        labor_link: state => {
            if (state.psi_data) {
                return state.psi_data.data?.fields["labor-link"] ? state.psi_data.data.fields["labor-link"]?.data : ''
            }
            else {
                return ''
            }
        },
        product_version: state => {
            if (state.psi_data) {
                return state.psi_data.data?.fields["f-os-version"] ? state.psi_data.data.fields["f-os-version"]?.data : ''
            }
            else {
                return ''
            }
        },
        update_posting: state => {
            if (state.psi_data) {
                return state.psi_data.data?.fields["update-posting"] ? state.psi_data.data.fields["update-posting"]?.data : ''
            }
            else {
                return ''
            }
        },
        ios_version: state => {
            if (state.psi_data) {
                return state.psi_data.data?.fields["ios-version"] ? state.psi_data.data.fields["ios-version"]?.data : ''
            }
            else {
                return ''
            }
        },
        ios_update_posting: state => {
            if (state.psi_data) {
                return state.psi_data.data?.fields["ios-update-posting"] ? state.psi_data.data.fields["ios-update-posting"]?.data : ''
            }
            else {
                return ''
            }
        },
        eom: state => {
            if (state.psi_data) {
                return state.psi_data.data?.fields["eom"] ? (state.psi_data.data.fields["eom"]?.data == "ja" ? 'EOM' : '')  : ''
            }
            else {
                return ''
            }
        },
        eos: state => {
            if (state.psi_data) {
                return state.psi_data.data?.fields["eos"] ? (state.psi_data.data.fields["eos"]?.data == "ja" ? 'EOS' : '')  : ''
            }
            else {
                return ''
            }
        },
        eom_link: state => {
            if (state.psi_data) {
                return state.psi_data.data?.fields["eom-text"] ? state.psi_data.data.fields["eom-text"]?.data : ''
            }
            else {
                return ''
            }
        },
        getSingleWuIItem: state => {
            return (id) => {
                return state.items_wui.find(item => {
                    return item.doks.find(dok => dok.itemId == id)
                })
            }
        }
        
    }
}


export default items_store