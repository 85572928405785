const publication_store_sn = {
    namespaced: true,
    state: () => ({
        options: {
            products: [],
            unparsedProducts: []
        },
        content: []
    }),
    mutations: {
        products(state, products){
            state.options.products = products
        },
        unparsedProducts(state, products){
            state.options.unparsedProducts = products
        },
        content(state, content){
            state.content = content
        }
    },
    actions: {
        getContent({commit, dispatch}, data){
            const controller = new AbortController()
            const signal = controller.signal
            setTimeout(() => { 
                controller.abort()
            }, 10000)
            return fetch('/ws/supportnews/getSingle/' + data.type + '/' + data.id, {signal:signal})
            .then(resp => resp.json())
            .then(json => {
                commit('content', json)
                dispatch('setProducts', json.tags)
            })
        },
        setProducts({commit, rootState}, tags){
            commit('products', parseProduct(filterProducts(rootState.options.unparsedProducts, tags)))
            commit('unparsedProducts', filterProducts(rootState.options.unparsedProducts, tags))
        },
    },
    getters:{
        getDate: (state) => {
            if(state.content){
                let date = new Date(state.content?.date)
                return checkIfDigit(date?.getDate()) + '.' + checkIfDigit(date?.getMonth() + 1) + '.' + date?.getFullYear()
            }
            else{
                return ''
            }
            
        }
    }
}
function parseProduct(json){
    json.sort((a,b) => (a.name < b.name ) ? 1 : ((b.name  < a.name ) ? -1 : 0)) // sort by name
    let product_groups = []          
    json.forEach( item => {                
        product_groups.push({label:item.product_group, options: []})
        product_groups[product_groups.length-1].options.push(item)                       
    })
    const combined = {};
    product_groups.forEach(o => {
        const id = o["label"];
        const obj = o["options"][0];        
        if(!combined[id]) { combined[id] = [obj]; }
        else { combined[id].push(obj); }        
    });
    const result = [];
    Object.keys(combined).forEach(key => {
        result.push({"label" : key, "options" : combined[key]});
    });
    return result.reverse()
}
function filterProducts(allProducts, tags){
    return allProducts.filter(product => tags.includes(product.supportnews_tag))
}
function checkIfDigit(num){
    return num < 10 ? '0' + num : num
}
export default publication_store_sn