<template>
  <div>
    <div v-for="item in items" :key="item.id">
      <div v-if="item.doks.length" class="cat">
        <h2>
          <a :href="item.link" target="_blank">{{ item.name }}</a>
        </h2>
        <div class="docs_grid">
          <div v-for="dok in item.doks" :key="dok.itemId">
            <a
              @click="linkToSingle($event, publication_link(dok))"
              :href="publication_link(dok)"
              >{{ dok.title }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},
  data() {
    return {};
  },
  computed: {
    items() {
      return this.$store.state.items.items_wui;
    },
    product() {
      return this.$store.state.input.product.slug;
    },
    ticket_id() {
      return this.$store.state.input.ticket_id;
    },
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  updated() {},
  methods: {
    linkToSingle(e, link) {
      e.preventDefault();
      this.$router.push(link);
    },
    publication_link(item) {
      return (
        `/supnews/workflowundinternes/${this.product}/${
          item.itemId
        }/${this.replaceSymbols(item.title)}` +
        (this.ticket_id ? "?ticket_id=" + this.ticket_id : "")
      );
    },
    replaceSymbols(string) {
      return string.replaceAll(" ", "-").replaceAll("/", "");
    },
  },
};
</script>

<style scoped>
</style>

