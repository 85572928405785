<template>
  <div>
    <Header endType="Frontend" />
    <ProductNavigation pub="ilvy" />
    <div class="main_area layout_container">
      <ProductInfo />
      <div class="msm_box doc_meta">
        <div>
          <a
            v-if="hasRMAMailTB && ticket_id && rmaReason"
            @click="mailTBTreffer()"
            class="button important icon tb_hit"
            >RMA-MailTB-Treffer</a
          >
          <a
            v-else-if="hasMailTB && ticket_id"
            @click="mailTBTreffer()"
            class="button important icon tb_hit"
            >MailTB-Treffer</a
          >

          <a
            v-if="ticket_id && hasExternIlvy"
            @click="treffer()"
            class="button important icon hit"
            >Treffer</a
          >
          <span>
            <transition name="fade">
              <span id="copied" v-if="show">Externer Link kopiert</span>
            </transition>
          </span>
          <a
            v-if="hasExternIlvy"
            @click="smTreffer()"
            class="button important icon copy"
            >SM-Treffer</a
          >
          <a @click="keinTreffer()" class="button icon no_hit">Kein Treffer</a>
          <a
            v-if="hasExternIlvy"
            @click="linkToExternIlvy()"
            class="button icon external"
            >Externe Variante</a
          >
        </div>
        <span class="doc_number">SKB-Dok#{{ doc_id }}</span>
        <hr />
        <span class="creation_date">{{ modified }}</span>
        <LanguageNavigation pub="ilvy" />
      </div>
      <div id="skb_doc" class="msm_box">
        <div ref="div" id="skb" v-html="content"></div>
      </div>
      <div class="msm_box doc_comment">
        <CommentTopicForm
          type="ilvy"
          :ilvy_dokId="doc_id"
          :ilvy_dokTitle="title"
        />
      </div>
      <Lightbox ref="lightbox" />
    </div>
  </div>
</template>
<script>
import TurndownService from "turndown";
import Header from "@/components/Header.vue";
import CommentTopicForm from "@/components/Comment_Topic_Form.vue";
import ProductInfo from "@/components/ProductInfo.vue";
import ProductNavigation from "@/components/ProductNavigation.vue";
import LanguageNavigation from "@/components/LanguageNavigation.vue";
import Mark from "mark.js/src/lib/mark.js";
import statistics from "@/_helpers/statistics.js";
import Lightbox from "@/components/Lightbox.vue";
export default {
  components: {
    Header,
    CommentTopicForm,
    ProductInfo,
    ProductNavigation,
    LanguageNavigation,
    Lightbox,
  },
  data() {
    return {
      hasMailTB: false,
      hasRMAMailTB: false,
      show: false,
      openProductSelectByClick: false,
      renderProductSelect: 0,
      linkList: [],
      originDB: "SKB",
    };
  },
  computed: {
    query() {
      let string = this.$store.state.input.query.length
        ? this.$store.state.input.query.join("|")
        : "";
      return new RegExp(string, "gi");
    },
    language() {
      return this.$store.state.input.language;
    },
    ilvy_treffer() {
      return this.$store.state.zendesk.treffer;
    },
    rma_treffer: {
      get() {
        return this.$store.state.zendesk.rma_treffer;
      },
      set(newVal) {
        this.$store.commit("zendesk/rma_treffer", newVal);
      },
    },
    pms_category() {
      return this.$store.getters["publication_ilvy/getPMSCategory"];
    },
    externalSlug() {
      return this.$store.state.input.product.slug;
    },
    hasExternIlvy() {
      return this.$store.state.publication_ilvy.hasExternIlvy;
    },
    modified() {
      return this.$store.getters["publication_ilvy/modificationDate"];
    },
    title() {
      return this.$store.state.publication_ilvy.content?.title;
    },
    publication_slug() {
      return this.$store.state.publication_ilvy.content?.slug;
    },
    doc_id() {
      return this.$store.state.publication_ilvy.content?.doc_id;
    },
    doc_class_id() {
      return this.$store.state.publication_ilvy.content?.doc_class_id;
    },
    content() {
      return this.$store.getters["publication_ilvy/getContent"];
    },
    baseURL() {
      return this.$store.getters["options/getBaseURL"];
    },
    ticket_id() {
      return this.$store.state.input.ticket_id;
    },
    externalLink() {
      return (
        this.baseURL +
        this.externalSlug +
        "/" +
        this.doc_id +
        "_" +
        this.publication_slug
      );
    },
    rmaReason() {
      return this.$store.state.publication_ilvy.content?.rma_reason;
    },
  },
  watch: {
    linkList() {
      for (var i = 0; i < this.linkList.length; i++) {
        this.linkList[i].onclick = this.linkAction;
      }
    },
    content() {
      setTimeout(() => {
        var context = document.querySelector("#skb");
        var instance = new Mark(context);
        instance.markRegExp(this.query);
      }, 100);
    },
    ilvy_treffer(newVal) {
      this.$store.commit("zendesk/treffer", newVal);
    },
  },
  methods: {
    linkAction: function (event) {
      event.preventDefault();
      let target =
        event.target.href || (event.composedPath && event.composedPath())
          .filter((item) => {
            if (item != window && item != document) {
              if (!item.classList.contains("lightbox") && item.tagName == "A") {
                return item;
              }
            }
          })
          .map((item) => item.href)[0];
      if (target) {
        if (target.includes(window.location.host)) {
          let urlObject = new URL(target);
          this.$router.push(urlObject.pathname);
        } else {
          window.open(target, "_blank");
        }
      }
    },
    closeProductSelect() {
      this.openProductSelectByClick = false;
    },
    openProductSelect() {
      this.openProductSelectByClick = true;
      this.renderProductSelect++;
    },
    showCopied() {
      this.show = true;
      setTimeout(() => {
        this.show = false;
        this.checkLastRoute();
      }, 1000);
    },
    copyToClipBoard() {
      navigator.clipboard.writeText(this.externalLink);
    },
    smTreffer() {
      this.copyToClipBoard();
      this.showCopied();
      this.sendToES();
    },
    mailTBTreffer() {
      let ticket_id = this.ticket_id;
      if (!this.ilvy_treffer.some((item) => item.doc_id == this.doc_id)) {
        this.sendToES();
        let baseURL = this.baseURL;
        let externalSlug = this.externalSlug;
        var turndownService = new TurndownService({ bulletListMarker: "-" });
        turndownService.addRule("links_formatieren", {
          filter: "a",
          replacement: function (content, html) {
            if (!html.href.includes(window.location.host)) {
              return "[" + content + "](" + html.href + ")";
            } else {
              return (
                "[" +
                content +
                "](" +
                baseURL +
                externalSlug +
                "/" +
                html.href.substring(html.href.lastIndexOf("/") + 1) +
                "?t_id=" +
                ticket_id +
                ")"
              );
            }
          },
        });
        let mailTBContent = document.getElementsByClassName("mailTB")[0];
        var markdown = turndownService.turndown(mailTBContent);

        markdown = markdown.replace(
          new RegExp("{{(.*?)}}", "g"),
          function (string) {
            return string.replaceAll("\\", "");
          }
        );
        this.ilvy_treffer.push({
          type: "ilvy",
          doc_type: "ilvy",
          doc_id: this.doc_id,
          title: this.title,
          slug: markdown,
          doc_slug: this.publication_slug,
          language: this.language,
          product: this.externalSlug,
          mailTB: true,
          pms_category: this.pms_category,
          baseURL: baseURL,
          ticket_id: ticket_id,
        });
      }
      if (this.rmaReason && this.hasRMAMailTB) {
        this.rmaTreffer();
      }

      this.checkLastRoute();
    },
    rmaTreffer() {
      this.rma_treffer = {
        type: "ilvy",
        doc_slug: this.publication_slug,
        doc_id: this.doc_id,
        doc_class_id: this.doc_class_id,
        title: this.title,
        product: this.externalSlug,
        rma: true,
        rma_reason: this.rmaReason,
        originDB: this.originDB,
      };
    },
    keinTreffer() {
      this.checkLastRoute();
    },
    treffer() {
      if (!this.ilvy_treffer.some((item) => item.doc_id == this.doc_id)) {
        this.sendToES();
        this.ilvy_treffer.push({
          type: "ilvy",
          doc_id: this.doc_id,
          doc_class_id: this.doc_class_id,
          title: this.title,
          slug: this.publication_slug,
          doc_slug: this.publication_slug,
          language: this.language,
          product: this.externalSlug,
          mailTB: false,
          pms_category: this.pms_category,
          baseURL: this.baseURL,
          ticket_id: this.ticket_id
        });
      }
      this.checkLastRoute();
    },
    elasticSearch: statistics.elasticSearch,
    sendToES() {
      const url = "/api/stats/doc";
      const data = {
        category: this.$store.state.input.categories
          ? this.$store.state.input.categories.join(",")
          : 0,
        doc_class: this.$store.state.input.doc_class
          ? this.$store.state.input.doc_class
          : 0,
        doc_id: this.$store.state.publication_ilvy.content?.doc_id,
        doc_class_id: this.$store.state.publication_ilvy.content?.doc_class_id,
        language: this.$store.state.input.language,
        pms_category: this.pms_category,
        product: this.$store.state.input.product.name,
        product_group: this.$store.state.input.product.product_group,
        searchquery: this.$store.state.input.query.join(" "),
        ticket_id: this.$store.state.input.ticket_id
          ? this.$store.state.input.ticket_id
          : 0,
        topic: {
          name: this.title,
          url_extern: this.externalLink,
          url_intern: window.location.href,
        },
        topic_container_name: this.$store.state.publication_ilvy.content?.topic,
        support_type: this.$store.state.input.channel
          ? this.$store.state.input.channel
          : 0,
      };
      this.elasticSearch(url, data);
    },
    checkLastRoute() {
      let query = [];
      query.push({ cat: this.$store.state.input.categories.join(",") });
      query.push({ q: this.$store.state.input.query });
      let obj = Object.assign({}, ...query);
      let product = this.$store.state.input.product.slug;
      this.$router.push({
        name: "suchmaske",
        params: { lang: this.language, product: product },
        query: obj,
      });
    },
    markQuery() {
      var context = document.querySelector("#skb");
      var instance = new Mark(context);
      instance.markRegExp(this.query);
    },
    linkToExternIlvy() {
      this.copyToClipBoard(this.externalLink);
      window.open(this.externalLink, "_blank");
    },
    checkForMailTBandRMA() {
      window.scrollTo(0, 0);
      this.hasMailTB = document.getElementsByClassName("mailTB")[0]
        ? true
        : false;
      this.hasRMAMailTB = document.getElementsByClassName("RMA")[0]
        ? true
        : false;
      this.markQuery();
    },
    bindLinks() {
      let msm_box = document.getElementById("skb_doc");
      this.linkList = msm_box.getElementsByTagName("a");
      for (var i = 0; i < this.linkList.length; i++) {
        this.linkList[i].onclick = this.linkAction;
      }
    },
  },
  created() {},
  updated() {
    this.checkForMailTBandRMA();
    this.bindLinks();
    this.$refs.lightbox.parseMedia();
  },
  mounted() {
    this.checkForMailTBandRMA();
    this.bindLinks();
    this.$refs.lightbox.parseMedia();
  },
  beforeRouteUpdate(to, from, next) {
    this.$store
      .dispatch("publication_ilvy/getContent", {
        lang: to.params.lang,
        product: to.params.product,
        id: to.params.id,
      })

      .then(() => {
        to.meta.title = this.$store.state.input.ticket_id
          ? `#${this.$store.state.input.ticket_id} - ${this.$store.state.publication_ilvy.content.doc_id}: ${this.$store.state.publication_ilvy.content.title}`
          : `${this.$store.state.publication_ilvy.content.doc_id}: ${this.$store.state.publication_ilvy.content.title}`;
        next();
      });
  },
};
</script>
<style scoped>
#prod_select {
  margin: 0;
}
.content {
  padding: 5%;
}
.prod_con {
  display: flex;
  align-items: center;
  justify-content: start;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
#copied {
  display: inline;
}

.popup > div > ul {
  background-color: var(--body-background);
  color: var(--content-text);
}
.msm_box {
  position: relative;
}
</style>