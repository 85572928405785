<template>
<div>
  <Header />
  <div id="layoutSidenav_content">
<div fluid>
    <div class="form_container">
        <div class="form_wrapper">
    <form @submit="onSubmit"> 
      <fieldset>
        <legend>Name</legend>
        <input type="text" v-model="form.name">
      </fieldset>

      <fieldset v-if="type != 'update'">
        <legend>Quelle</legend>
        <input type="radio" v-model="form.quelle" name="some-radios" value="ilvy" id="ilvy">
        <label for="ilvy">Ilvy</label>
        <input type="radio" v-if="!techexExists" v-model="form.quelle" name="some-radios" value="techex" id="techex">
        <label for="techex">TechEx</label>
        <input type="radio" v-if="!wikiExists" v-model="form.quelle" name="some-radios" value="wiki" id="wiki">
        <label for="wiki">FritzWiki</label>
      </fieldset>
      
      <fieldset>
        <legend>ID</legend>
        <input type="number" ref="id" v-model="form.id" @blur="validate(form.id)">
        <div class="validation_error" v-if="validation_error">ID bereits vergeben</div>
      </fieldset>
      
      <fieldset>
        <legend>CSS-Klasse</legend>
        <input type="text" v-model="form.css_class">
      </fieldset>

      
        
        
      <div class="button_con">
        <button type="submit" >Speichern</button>
        <button @click="$router.push({ 
          name: 'produktverwaltung'
          
        })" variant="danger">Abbrechen</button>
        
      </div>
      
      
    </form>
    </div>
    </div>
  </div>
  
</div>
</div>


</template>

<script>
import Header from '@/components/Header.vue'
export default {
components: {
  Header
},
data() {
    return {
        type: this.$route.params.type,
        techexExists: false,
        wikiExists: false,
        current_id: null,
        id_dont_exists: true,
        validation_error: false,
        ids: [],
        form: {            
            name: '',
            id: this.$route.params.id ? this.$route.params.id : '',
            css_class: '',
            quelle: null    
        },
        
    }
},
created() {
  this.fetchData()
},
methods: {
  fetchData(){
    const controller = new AbortController()
        const signal = controller.signal
        setTimeout(() => { 
          controller.abort()
        }, 5000)
    fetch('/api/doc_class/getIds', {signal:signal})
        .then(resp => resp.json())
        .then(json => {

            this.ids = json           
        })
        .catch(error => {
          console.error(error)
        })
        fetch('/api/doc_class/getAllSources', {signal:signal})
        .then(resp => resp.json())
        .then(json => {
            Object.values(json).forEach(item => {
              if(item.quelle){
                if (item.quelle == "techex"){
                  this.techexExists = true
                }
                if (item.quelle == "wiki"){
                  this.wikiExists = true
                }
              }
              
            })
        })
        .catch(error => {
          console.error(error)
        })
        if (this.$route.params.type == 'update'){
        fetch('/api/doc_class/getSingle/'+this.$route.params.id, {signal:signal})    
            .then(resp => resp.json())
            .then(json => {
              if(json[0].name){
                this.form.name = json[0].name
              }
              else{
                this.form.name = ''
              }
              if(json[0].css_class){
                this.form.css_class = json[0].css_class 
              }
              else{
                this.form.css_class = ''
              }
              if(json[0].id){
                this.current_id = json[0].id 
              }
              else{
                this.current_id = ''
              }
              if(json[0].quelle){
                this.form.quelle = json[0].quelle  
              }
              else{
                this.form.quelle = '' 
              }
            
        })
        .catch(error => {
          console.error(error)
        })    
    }
    
    
 
    
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
    onSubmit(e){
        e.preventDefault()
        this.request()
        .then(resp=>{
          if(resp){
            this.$router.push({ 
            name: 'produktverwaltung'
            
            }) 
          }
        })
    },
    request(){
      if (this.type == 'anlegen'){
            let url = "/api/doc_class/create"
            return this.$http.post(url, {              
                id: this.form.id,
                name: this.form.name,
                css_class: this.form.css_class,
                quelle: this.form.quelle
            })
            
        }
        else if (this.type == 'update'){   
            let url = "/api/doc_class/update/"+this.form.id
            return this.$http.put(url, {
                name: this.form.name,
                css_class: this.form.css_class,
                quelle: this.form.quelle
            })
        }
    },
    validate(item){
      if (this.ids.includes(parseInt(item)) && item != this.current_id){
        this.id_dont_exists = false
        this.validation_error = true
        this.$refs.id.focus();
      }
      else{
        this.id_dont_exists = true
        this.validation_error = false
      }
    }
}

}
</script>
<style  scoped>
.validation_error{
  padding: 10px;
  background-color: firebrick;
  color: white;
  border-radius: 5px;
  margin-top: 5px;
}
form{
  width: 30%;
  margin: auto;
}
</style>