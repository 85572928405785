const publication_store_ilvy = {
    namespaced: true,
    state: () => ({
        options: {
            products: [],
            unparsedProducts: [],
            languages: []
        },
        content: [],
        hasExternIlvy: null
    }),
    mutations: {
        products(state, products) {
            state.options.products = products
        },
        unparsedProducts(state, products) {
            state.options.unparsedProducts = products
        },
        languages(state, languages) {
            state.options.languages = languages
        },
        content(state, content) {
            state.content = content
        },
        hasExternIlvy(state, status) {
            state.hasExternIlvy = status
        }
    },
    actions: {
        getContent({ commit, dispatch }, data) {
            if (data.id) {
                const controller = new AbortController()
                const signal = controller.signal
                setTimeout(() => {
                    controller.abort()
                }, 10000)
                return fetch('/ws/products/getSingleIntern/' + data.lang + '/' + data.product + '/' + data.id, { signal: signal })
                    .then(resp => resp.json())
                    .then(json => {
                        if(json.code != 500){
                            return Promise.all([
                                commit('content', json.data.publication),
                                commit('hasExternIlvy', Object.entries(json.data.altAttr.channels).length > 0),
                                dispatch('setProducts', json.data.altAttr.products),
                                dispatch('setLanguages', json.data.altAttr.languages)
                            ])
                        }
                        else{
                            return Promise.resolve()
                        }
                        
                    })
            }

        },
        setProducts({ commit, rootState }, data) {
            let unparsed = rootState.options.unparsedProducts
            let currentProduct = rootState.input.product.name
            commit('products', parseProduct(filterProducts(data, unparsed, currentProduct)))
            commit('unparsedProducts', filterProducts(data, unparsed, currentProduct))

        },
        setLanguages({ commit, rootState }, data) {
            let languages = Object.keys(data)
            let currentLang = rootState.input.language
            let allLangages = rootState.options.languages
            let filteredLang = filterlanguages(allLangages, languages, currentLang)
            commit('languages', filteredLang)
        }
    },
    getters: {
        getContent: (state) => {
            if (state.content) {
                return state.content.content?.replace(new RegExp('§§/', 'g'), '')
            }
            else {
                return ''
            }

        },
        modificationDate: (state) => {
            if (state.content) {
                let dateStamp = new Date(state.content.modified)
                return "Aktualisiert am: " + checkIfDigit(dateStamp.getDate()) + '.' + checkIfDigit((dateStamp.getMonth() + 1)) + '.' + dateStamp.getFullYear()
            }
            else {
                return ''
            }

        },
        getPMSCategory: (state) => {
            if (state.content) {
                return state.content.pms_category?.toLowerCase().replaceAll('/', '-').replaceAll('!', ' ').replaceAll(' ', '')
            }
            else {
                return ''
            }

        },
    }
}
function filterlanguages(allLangages, languages, currentLang) {
    return allLangages.filter(item => languages.includes(item.slug) || item.slug === currentLang)
}
function filterProducts(data, unparsed, currentProduct) {
    let end = []
    Object.values(data).forEach(element => {
        Object.values(element).forEach(item => {
            let mid = unparsed.filter(unp => {
                return unp.name === item.product_name
            })
            if (mid.length) {
                end.push(mid[0])
            }
        })
    })
    const curr = unparsed.filter(item => item.name === currentProduct)
    end.push(curr[0])
    return end
}
function parseProduct(json) {
    json.sort((a, b) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0)) // sort by name
    let product_groups = []
    json.forEach(item => {
        product_groups.push({ label: item.product_group, options: [] })
        product_groups[product_groups.length - 1].options.push(item)
    })
    const combined = {};
    product_groups.forEach(o => {
        const id = o["label"];
        const obj = o["options"][0];
        if (!combined[id]) { combined[id] = [obj]; }
        else { combined[id].push(obj); }
    });
    const result = [];
    Object.keys(combined).forEach(key => {
        result.push({ "label": key, "options": combined[key] });
    });
    return result.reverse()
}
function checkIfDigit(num) {
    return num < 10 ? '0' + num : num
}
export default publication_store_ilvy