<template >
  <div
    id="popup_prod_select"
    class="overlay"
    v-if="showProductSelectBox"
    @click="closePopUp"
  >
    <div class="popup">
      <v-select
        class="productKeyboardSelect"
        :options="optionsProducts"
        label="name"
        :reduce="(name) => name.ilvy_slug"
        v-model="selected"
      >
        <template #no-options>
          Kein passendes Produkt zu deiner Suche gefunden
        </template>
        <template #option="{ name }">
          <div class="productKeyboardSelect productItem">{{ name }}</div>
        </template>
        
      </v-select>
    </div>
  </div>
</template>

<script>
export default {
  props: ["openByClick"],
  data() {
    return {
      showProductSelectBox: false,
      selected: "",
    };
  },
  computed: {
    
    publication_type(){
      return this.$route.name == 'supnews_template' ? 'sn' : (this.$route.name == 'publikationsansicht' ? 'ilvy' : null)
    },
    
    optionsProducts() {
      return this.publication_type === "ilvy"
        ? this.$store.state.publication_ilvy.options.unparsedProducts
        : this.publication_type === "sn"
        ? this.$store.state.publication_sn.options.unparsedProducts
        : this.$store.state.options.unparsedProducts;
    },
  },
  watch: {
    selected(newVal) {
      if (newVal.length) {
        this.$store.dispatch("input/product", {
          product: newVal,
          pub: this.publication_type,
        });
        this.$router
          .push({
            params: { ...this.$route.params, product: newVal },
            query: { ...this.$route.query },
          })
          .catch((err) => {
            console.error(err);
          });
        this.selected = "";
        this.showProductSelectBox = false;
      }
    },
    showProductSelectBox(newVal) {
      if (newVal) {
        setTimeout(() => {
          if (
            document.querySelector(
              "#popup_prod_select > div > div > div > div > input"
            )
          ) {
            document
              .querySelector(
                "#popup_prod_select > div > div > div > div > input"
              )
              .focus();
          }
        }, 100);
      }
    },
  },
  methods: {
    closePopUp(e) {
      let path = e.path || (e.composedPath && e.composedPath());
      if (path[0].id == "popup_prod_select") {
        this.showProductSelectBox = false;
        this.$emit("closeProductSelect");
      }
    },
    detectShortKey(e) {
      if (e.keyCode == 89 && e.getModifierState("Control")) {
        this.showProductSelectBox = true;
      }
    },
  },
  created() {
    parent.addEventListener("keydown", this.detectShortKey);
    let popUp = document.getElementById("popup_catsort");
    if (popUp) {
      popUp.addEventListener("click", this.closePopUp);
    }
  },
  mounted() {
    this.showProductSelectBox = this.openByClick;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
