<template >
    <ul class="category_filter">
        <li v-for="category in optionsCategories" :key="category.cat_id">
        <input :id="category.client" type="checkbox" v-model="categories" :value="category.cat_id">
        <label :for="category.client">
            <div v-html="category.icon"></div>
            {{category.client}}
        </label>
        </li>
    </ul>   
</template>

<script>

export default {
    data(){
        return {

        } 
    },
    computed:{
      categories:{
        get(){
          return this.$store.state.input.categories
        },
        set(newVal){
          if(newVal){
            let string = newVal.join(',')
            this.$router.push({ query: { ...this.$route.query, cat: string } }).catch(err => {console.error(err)})
            this.$store.dispatch('input/categories', newVal)
          }
          else{
            let query = Object.assign({}, this.$route.query);
            delete query.cat;
            this.$router.replace({ query });
            this.$store.dispatch('input/categories', [])
          }
          
        }
      },
      optionsCategories(){
        return this.$store.state.options.categories
      },
    }
}

</script>

<style scoped>

</style>
