<template >
<div>
  <Header endType="Backend" :key="rerender"/>
  <AdminNavigation processType="Sprachen"/>
  <div class="main_area layout_container">
    <div class="backend">
        <h1>Sprachen</h1>
    </div>
    <a @click="showSyncBox = true" class="button icon important sync">Synchronisieren</a>
    <div id="popup_langsync" class="overlay" v-if="showSyncBox">
        <div class="popup">
            <h2>Sprachen synchronisieren</h2>
            <div class="popup_content">
                Sollen alle Sprachen mit dem Ilvy!-CMS synchronisiert werden?
                <br><span class="attention">Bestehende Daten können beim Synchronisieren überschrieben werden!</span>
            </div>
            <div class="popup_action">
                <a @click="showSyncBox = false" class="button close">Abbrechen</a>
                <a @click="syncData" class="button important">Sychronisieren</a>
            </div>
        </div>
    </div>
    <DeleteItem :item="language_to_delete" v-if="showDeleteItem" process_type="Sprache"/>
    <div class="table">
        <ul class="lang_table">
            <li class="table_header">
                <span class="left_align">Name</span>
                <span class="left_align">Slug</span>
                <span class="left_align">Basis-URL auf avm.de</span>
            </li>
            <li v-for="lang in items" :key="lang.id">
              <span>{{lang.slug.toUpperCase()}}</span>
              <span>{{lang.slug}}</span>
              <span>{{lang.url}}</span>
              <a @click="updateLang(lang)" class="status_icon edit hovered"></a>
              <a @click="setLanguageToDelete(lang)" class="status_icon delete hovered"></a>
            </li>
        </ul>
    </div>
</div>
  
  
</div>


</template>

<script>
import Header from '@/components/Header.vue'
import DeleteItem from '@/components/DeleteItem.vue'
import AdminNavigation from '@/components/AdminNavigation.vue'
export default {
    components: {
      Header, DeleteItem, AdminNavigation
    },
    data(){
        return {
          showSyncBox: false,
          showDeleteItem: false,
          language_to_delete: null,
          items: [],
          rerender: 0
        } 
    },
    mounted() {
      fetch('/api/languages/getAllConfig')
        .then(resp => resp.json())
        .then(json => {
          if(json){
            this.items = json
            this.rerender++
          }
          
        })
        .catch(error => {
          console.error(error.status)
        })
        
    },
    methods: {
      setLanguageToDelete(lang){
        this.showDeleteItem = true
        this.language_to_delete = lang
      },
      syncData(){
        fetch('/ws/languages/syncData')
        .then((res) => {
          if (res.status == 200){
            window.location.reload()
          }
          
        })
        .catch(error => {
          console.log(error)
        })
      },
      updateLang(item){
        this.$router.push({
          name: 'sprachenansicht',
          params: {
            id: item.lang_id,
            type: 'update',
            
          },
            
        })
        
      },
      deleteItem(id){
        let url = "/api/languages/"+id
                   
        this.$http.delete(url)
        .then(response => {
            if (response.status == 200) {
                window.location.reload()
            }            
        })
        .catch(error => {
          alert("nicht erfolgreich")
          console.log(error)
          window.location.reload()
        })  
      },
    }
        
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
